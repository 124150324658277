import React, { useState, useEffect } from "react";
import {
  Box,
  TextField,
  Typography,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Divider,
  Paper,
  Grid,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  IconButton,
  Tooltip,
} from "@mui/material";
import { ExpandMore, Delete, Add, Help } from "@mui/icons-material";
import { ChromePicker } from "react-color";

const DemoCreatorForm = ({ onUpdateConfig, initialConfig }) => {
  const defaultConfig = {
    // General settings
    publicationName: "Publication Name",
    highlightColor: "#000000",
    headerBarColor: "#000000",
    articleName: "Your Article Title Goes Here",
    articleLink: "https://example.com/article",
    headerImageLink: "",
    headerSvg: "",

    // Wordy settings
    wordyTitle: "Publication Wordy",
    wordyWord: "CLERK",
    wordyHint: "Enter a hint for your word here",

    // Keyword settings
    keywordWordList: ["PIECES", "DONATE", "LEGOS", "FOSTER", "SORT"],
    keywordAnswer: "COLOR",
  };

  const [formState, setFormState] = useState(initialConfig || defaultConfig);

  // Update form state when initialConfig changes (for URL sharing)
  useEffect(() => {
    if (initialConfig) {
      setFormState(initialConfig);
    }
  }, [initialConfig]);

  const [colorPickerOpen, setColorPickerOpen] = useState({
    highlight: false,
    header: false,
  });

  // Handle color picker changes
  const handleColorChange = (color, field) => {
    setFormState((prev) => ({
      ...prev,
      [field]: color.hex,
    }));
  };

  // Handle text field changes
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormState((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  // Handle keyword word list changes
  const handleWordListChange = (index, value) => {
    const newWordList = [...formState.keywordWordList];
    newWordList[index] = value.toUpperCase();
    setFormState((prev) => ({
      ...prev,
      keywordWordList: newWordList,
    }));
  };

  // Add a new word to the word list
  const addWordToList = () => {
    setFormState((prev) => ({
      ...prev,
      keywordWordList: [...prev.keywordWordList, "WORD"],
    }));
  };

  // Remove a word from the word list
  const removeWordFromList = (index) => {
    const newWordList = [...formState.keywordWordList];
    newWordList.splice(index, 1);
    setFormState((prev) => ({
      ...prev,
      keywordWordList: newWordList,
    }));
  };

  // Update the parent component with the latest configuration
  const handleSubmit = (e) => {
    e.preventDefault();
    onUpdateConfig(formState);
  };

  return (
    <Box component="form" onSubmit={handleSubmit} sx={{ mb: 4 }}>
      <Paper elevation={2} sx={{ p: 3, mb: 3 }}>
        <Typography variant="h5" component="h2" gutterBottom>
          General Settings
        </Typography>
        <Divider sx={{ mb: 3 }} />

        <Grid container spacing={3}>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Publication Name"
              name="publicationName"
              value={formState.publicationName}
              onChange={handleInputChange}
              required
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <Box sx={{ mb: 2 }}>
              <Typography variant="subtitle1" gutterBottom>
                Highlight Color
              </Typography>
              <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
                <Box
                  sx={{
                    width: 36,
                    height: 36,
                    bgcolor: formState.highlightColor,
                    border: "1px solid #ccc",
                    borderRadius: 1,
                    cursor: "pointer",
                  }}
                  onClick={() =>
                    setColorPickerOpen((prev) => ({
                      ...prev,
                      highlight: !prev.highlight,
                    }))
                  }
                />
                <TextField
                  size="small"
                  value={formState.highlightColor}
                  name="highlightColor"
                  onChange={handleInputChange}
                  sx={{ width: "120px" }}
                />
              </Box>
              {colorPickerOpen.highlight && (
                <Box sx={{ position: "relative", zIndex: 10, mt: 1 }}>
                  <Paper elevation={3} sx={{ p: 1, width: "fit-content" }}>
                    <ChromePicker
                      color={formState.highlightColor}
                      onChange={(color) =>
                        handleColorChange(color, "highlightColor")
                      }
                    />
                    <Button
                      size="small"
                      variant="contained"
                      onClick={() =>
                        setColorPickerOpen((prev) => ({
                          ...prev,
                          highlight: false,
                        }))
                      }
                      sx={{ mt: 1, width: "100%" }}
                    >
                      Done
                    </Button>
                  </Paper>
                </Box>
              )}
            </Box>
          </Grid>

          <Grid item xs={12} md={6}>
            <Box sx={{ mb: 2 }}>
              <Typography variant="subtitle1" gutterBottom>
                Header Bar Color
              </Typography>
              <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
                <Box
                  sx={{
                    width: 36,
                    height: 36,
                    bgcolor: formState.headerBarColor,
                    border: "1px solid #ccc",
                    borderRadius: 1,
                    cursor: "pointer",
                  }}
                  onClick={() =>
                    setColorPickerOpen((prev) => ({
                      ...prev,
                      header: !prev.header,
                    }))
                  }
                />
                <TextField
                  size="small"
                  value={formState.headerBarColor}
                  name="headerBarColor"
                  onChange={handleInputChange}
                  sx={{ width: "120px" }}
                />
              </Box>
              {colorPickerOpen.header && (
                <Box sx={{ position: "relative", zIndex: 10, mt: 1 }}>
                  <Paper elevation={3} sx={{ p: 1, width: "fit-content" }}>
                    <ChromePicker
                      color={formState.headerBarColor}
                      onChange={(color) =>
                        handleColorChange(color, "headerBarColor")
                      }
                    />
                    <Button
                      size="small"
                      variant="contained"
                      onClick={() =>
                        setColorPickerOpen((prev) => ({
                          ...prev,
                          header: false,
                        }))
                      }
                      sx={{ mt: 1, width: "100%" }}
                    >
                      Done
                    </Button>
                  </Paper>
                </Box>
              )}
            </Box>
          </Grid>

          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Article Title"
              name="articleName"
              value={formState.articleName}
              onChange={handleInputChange}
              required
              multiline
              rows={2}
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Article Link"
              name="articleLink"
              value={formState.articleLink}
              onChange={handleInputChange}
              required
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Header Image Link (URL)"
              name="headerImageLink"
              value={formState.headerImageLink}
              onChange={handleInputChange}
              helperText="URL to an image to be used as the header logo"
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Header SVG (Raw SVG tag)"
              name="headerSvg"
              value={formState.headerSvg}
              onChange={handleInputChange}
              multiline
              rows={3}
              helperText="Alternative to image link: Paste raw SVG code for the header logo"
            />
          </Grid>
        </Grid>
      </Paper>

      <Paper elevation={2} sx={{ p: 3, mb: 3 }}>
        <Typography variant="h5" component="h2" gutterBottom>
          Wordy Game Settings
        </Typography>
        <Divider sx={{ mb: 3 }} />

        <Grid container spacing={3}>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Wordy Title"
              name="wordyTitle"
              value={formState.wordyTitle}
              onChange={handleInputChange}
              required
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              label="Wordy Word"
              name="wordyWord"
              value={formState.wordyWord}
              onChange={(e) =>
                handleInputChange({
                  target: {
                    name: "wordyWord",
                    value: e.target.value.toUpperCase(),
                  },
                })
              }
              inputProps={{ maxLength: 5 }}
              required
              helperText="Must be a 5-letter word"
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Wordy Hint"
              name="wordyHint"
              value={formState.wordyHint}
              onChange={handleInputChange}
              multiline
              rows={2}
              required
            />
          </Grid>
        </Grid>
      </Paper>

      <Paper elevation={2} sx={{ p: 3, mb: 3 }}>
        <Typography variant="h5" component="h2" gutterBottom>
          Keyword Game Settings
        </Typography>
        <Divider sx={{ mb: 3 }} />

        <Grid container spacing={3}>
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              label="Keyword Answer"
              name="keywordAnswer"
              value={formState.keywordAnswer}
              onChange={(e) =>
                handleInputChange({
                  target: {
                    name: "keywordAnswer",
                    value: e.target.value.toUpperCase(),
                  },
                })
              }
              required
              inputProps={{ maxLength: 5 }}
              helperText="Must be a 5-letter word"
            />
          </Grid>

          <Grid item xs={12}>
            <Typography variant="subtitle1" gutterBottom>
              Keyword Word List
              <Tooltip title="These are the words that appear in the sliding puzzle. Each word must contain a letter of the answer word in the right position.">
                <IconButton size="small" sx={{ ml: 1 }}>
                  <Help fontSize="small" />
                </IconButton>
              </Tooltip>
            </Typography>

            {formState.keywordWordList.map((word, index) => (
              <Box
                key={index}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: 1,
                  mb: 2,
                }}
              >
                <TextField
                  fullWidth
                  label={`Word ${index + 1}`}
                  value={word}
                  onChange={(e) => handleWordListChange(index, e.target.value)}
                  required
                />
                <IconButton
                  color="error"
                  onClick={() => removeWordFromList(index)}
                  disabled={formState.keywordWordList.length <= 3}
                >
                  <Delete />
                </IconButton>
              </Box>
            ))}

            <Button
              startIcon={<Add />}
              variant="outlined"
              onClick={addWordToList}
              disabled={formState.keywordWordList.length >= 7}
              sx={{ mt: 1 }}
            >
              Add Word
            </Button>
          </Grid>
        </Grid>
      </Paper>

      <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
        <Button
          type="submit"
          variant="contained"
          color="primary"
          size="large"
          sx={{ px: 4 }}
        >
          Update Preview
        </Button>
      </Box>
    </Box>
  );
};

export default DemoCreatorForm;
