/**
 * Dictionary validation utilities
 */

// API for word validation (free dictionary API)
const DICTIONARY_API_BASE_URL =
  "https://api.dictionaryapi.dev/api/v2/entries/en/";

/**
 * Check if a word is valid in the dictionary
 * @param {string} word - The word to check
 * @returns {Promise<boolean>} - True if the word is valid, false otherwise
 */
export const checkWordValidity = async (word) => {
  try {
    // First check against a common word list if available
    // For now, we'll just use the API
    const response = await fetch(`${DICTIONARY_API_BASE_URL}${word}`);
    return response.ok; // If response is OK, the word exists
  } catch (error) {
    console.error("Error checking word validity:", error);
    return true; // If there's an API error, accept the word to avoid disrupting the game
  }
};

/**
 * A fallback method that could be used if the API is not available
 * This would require storing a word list in the app
 */
export const checkWordValidityOffline = (word, wordList) => {
  // Check if the word is in the provided word list
  return wordList.includes(word.toLowerCase());
};
