import React, { useState, useEffect } from "react";
import BaseKeyword from "../../games/Keyword/BaseKeyword";

const DemoKeywordPreview = ({ config }) => {
  const [wordData, setWordData] = useState(null);

  useEffect(() => {
    // Static data for demo, updated from config
    setWordData({
      wordList: config.keywordWordList,
      answer: config.keywordAnswer,
    });
  }, [config.keywordWordList, config.keywordAnswer]);

  if (!wordData) return null;

  // Derive header image or SVG
  const headerImage = config.headerImageLink || config.headerSvg;

  return (
    <BaseKeyword
      title={config.publicationName}
      subtitle="Slide each word horizontally until the center letters line up to reveal the keyword."
      wordList={wordData.wordList}
      isEmbedded={true}
      buttonColor={config.highlightColor}
      modalBackgroundColor={config.highlightColor}
      answer={wordData.answer}
      shareText={`I played ${config.publicationName} Keyword`}
      shareUrl={window.location.href}
      publicationName={`${config.publicationName} article`}
      articleTitle={config.articleName}
      articleUrl={config.articleLink}
      iconPath={headerImage}
    />
  );
};

export default DemoKeywordPreview;
