export const ALAMEDA_WORDS = [
  {
    date: "2025-01-22",
    hint: "A crucial presence on the island from 1940 to 1997, it once launched entire fleets",
    word: "NAVAL",
    characterCount: 5,
  },
  {
    date: "2025-01-23",
    hint: "The USS Hornet is famous for unexplained footsteps and sightings from beyond",
    word: "HAUNT",
    characterCount: 5,
  },
  {
    date: "2025-01-24",
    hint: "Look to the wetlands for this slender, white wader silently stalking fish",
    word: "EGRET",
    characterCount: 5,
  },
  {
    date: "2025-01-25",
    hint: "A narrow house built to annoy a neighbor remains a local architectural oddity",
    word: "SPITE",
    characterCount: 5,
  },
  {
    date: "2025-01-26",
    hint: "This WWII Admiral’s name graces the busy interstate running through Alameda County",
    word: "NIMITZ",
    characterCount: 5,
  },
  {
    date: "2025-01-27",
    hint: "At the former air station, a tall control structure once guided takeoffs and landings",
    word: "TOWER",
    characterCount: 5,
  },
  {
    date: "2025-01-28",
    hint: "Alameda’s craft beverage scene also includes this apple-based drink at local taprooms",
    word: "CIDER",
    characterCount: 5,
  },
  {
    date: "2025-01-29",
    hint: "Close to the water, this spot once hosted ferries and cargo, bridging the island to the bay",
    word: "WHARF",
    characterCount: 5,
  },
  {
    date: "2025-01-30",
    hint: "A row of distilleries and tasting rooms near the lagoon proudly carries this name",
    word: "ALLEY",
    characterCount: 5,
  },
  {
    date: "2025-01-31",
    hint: "An arts center on Paru Street commemorates a local painter’s philanthropic legacy",
    word: "BETTE",
    characterCount: 5,
  },
  {
    date: "2025-02-01",
    hint: "The creator of 'Little Nemo' revolutionized comics while residing on this island",
    word: "MCCAY",
    characterCount: 5,
  },
  {
    date: "2025-02-02",
    hint: "Several local clubs on the shoreline revolve around this type of sailing vessel",
    word: "YACHT",
    characterCount: 5,
  },
  {
    date: "2025-02-03",
    hint: "Before becoming South Shore Center, the mall once had this spelling in its name",
    word: "TOWNE",
    characterCount: 5,
  },
  {
    date: "2025-02-04",
    hint: "Before cars dominated, electric lines used this mode to connect Alameda to Oakland",
    word: "TRAIN",
    characterCount: 5,
  },
  {
    date: "2025-02-05",
    hint: "A major avenue’s Spanish name means “good,” though it can be quite busy at rush hour",
    word: "BUENA",
    characterCount: 5,
  },
  {
    date: "2025-02-06",
    hint: "The island is shaped by these currents that ebb and flow through the estuary",
    word: "TIDAL",
    characterCount: 5,
  },
  {
    date: "2025-02-07",
    hint: "A prominent early resident, with a street named for him, helped shape the city",
    word: "HENRY",
    characterCount: 5,
  },
  {
    date: "2025-02-08",
    hint: "A grand meeting hall on Alameda Avenue hosts events for a longtime fraternal order",
    word: "LODGE",
    characterCount: 5,
  },
  {
    date: "2025-02-09",
    hint: "Bay Farm once was separate, a tiny example of this geographical feature",
    word: "ISLET",
    characterCount: 5,
  },
  {
    date: "2025-02-10",
    hint: "Alameda High’s insect mascot might move together in this coordinated formation",
    word: "SWARM",
    characterCount: 5,
  },
  {
    date: "2025-02-11",
    hint: "In 1906, the city felt major shakes from this seismic event impacting the Bay Area",
    word: "QUAKE",
    characterCount: 5,
  },
  {
    date: "2025-02-12",
    hint: "Travelers once converged at this Key System stop near Park Street for daily commutes",
    word: "DEPOT",
    characterCount: 5,
  },
  {
    date: "2025-02-13",
    hint: "Engineered to fully separate the island, this waterway was completed in the early 1900s",
    word: "CANAL",
    characterCount: 5,
  },
  {
    date: "2025-02-14",
    hint: "Seaplane Lagoon is an example of this sheltered body of water used for maritime activities",
    word: "BASIN",
    characterCount: 5,
  },
  {
    date: "2025-02-15",
    hint: "A stone structure jutting from Shore Line Drive helps prevent coastal erosion",
    word: "JETTY",
    characterCount: 5,
  },
];

export const getWordForDate = (date) => {
  if (!date || !ALAMEDA_WORDS || !Array.isArray(ALAMEDA_WORDS)) {
    return null;
  }

  // Convert to PT/Los Angeles timezone
  const ptDate = new Date(
    date.toLocaleString("en-US", { timeZone: "America/Los_Angeles" })
  );

  // Get current date in PT
  const currentPTDate = new Date(
    new Date().toLocaleString("en-US", { timeZone: "America/Los_Angeles" })
  );

  // Reset time parts for accurate date comparison
  currentPTDate.setHours(0, 0, 0, 0);
  ptDate.setHours(0, 0, 0, 0);

  // If requested date is in the future, return null
  if (ptDate > currentPTDate) {
    return null;
  }

  // Format the PT date as YYYY-MM-DD
  const dateString =
    ptDate.getFullYear() +
    "-" +
    String(ptDate.getMonth() + 1).padStart(2, "0") +
    "-" +
    String(ptDate.getDate()).padStart(2, "0");

  return ALAMEDA_WORDS.find((entry) => entry.date === dateString);
};

export const findLatestAvailableDate = () => {
  if (!ALAMEDA_WORDS || !Array.isArray(ALAMEDA_WORDS)) {
    return null;
  }

  // Get current date in PT
  const currentPTDate = new Date(
    new Date().toLocaleString("en-US", { timeZone: "America/Los_Angeles" })
  );
  currentPTDate.setHours(0, 0, 0, 0);

  // Create dates in PT timezone and filter out future dates
  const sortedEntries = ALAMEDA_WORDS.map((entry) => ({
    date: new Date(entry.date + "T00:00:00-08:00"), // Force PT timezone
    original: entry,
  }))
    .filter((entry) => entry.date <= currentPTDate) // Filter out future dates
    .sort((a, b) => b.date - a.date);

  return sortedEntries[0]?.original;
};
