// src/games/WordyVerse/components/GameBoard/GameHeader.js
import React from "react";
import { Box, Typography } from "@mui/material";

const GameHeader = ({ title, subtitle, iconPath, bannerPath }) => {
  if (bannerPath) {
    return <GameHeaderBanner title={title} bannerPath={bannerPath} />;
  }

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "center",
        gap: 2,
        mb: 2,
        ml: { xs: 3.5, sm: 2 },
      }}
    >
      {iconPath && (
        <img
          src={iconPath}
          alt={`${title} Logo`}
          style={{
            height: "80px",
          }}
        />
      )}

      <Box sx={{ display: "flex", flexDirection: "column", gap: 0.5 }}>
        <Typography
          variant="h4"
          textAlign={"left"}
          fontSize={"2.2rem"}
          sx={{ fontWeight: "bold", color: "black", lineHeight: 1 }}
        >
          {title}
        </Typography>
        {typeof subtitle === "string" ? (
          <Typography variant="body2" sx={{ color: "text.secondary" }}>
            {subtitle}
          </Typography>
        ) : (
          subtitle
        )}
      </Box>
    </Box>
  );
};

const GameHeaderBanner = ({ title, bannerPath }) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "center",
        height: "100px",
        width: "300px",
        mx: "auto",
      }}
    >
      <img
        src={bannerPath}
        alt={`${title} Logo`}
        style={{
          height: "100%",
        }}
      />
    </Box>
  );
};

export { GameHeader, GameHeaderBanner };
