import React, { useState, useEffect } from "react";
import DemoPreviewPage from "./DemoPreviewPage";

// This component serves as a standalone route for the preview
// It extracts config from URL parameters and passes it to the DemoPreviewPage
const DemoPreviewRoute = () => {
  const [config, setConfig] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    try {
      // Extract and parse config from URL parameters
      const urlParams = new URLSearchParams(window.location.search);
      const configParam = urlParams.get("config");

      if (configParam) {
        const decodedConfig = JSON.parse(decodeURIComponent(configParam));
        setConfig(decodedConfig);
      } else {
        // If no config is provided, use default values
        setConfig({
          publicationName: "Demo Publication",
          highlightColor: "#2277C8",
          headerBarColor: "#000000",
          articleName: "Sample Article Title",
          articleLink: "https://example.com/article",
          headerImageLink: "",
          headerSvg: "",
          wordyTitle: "Demo Wordy",
          wordyWord: "GAMES",
          wordyHint: "This is what you're creating with Flying Comet",
          keywordWordList: ["GAMES", "WORDY", "SHARE", "PRESS", "DEMO"],
          keywordAnswer: "MEDIA",
        });
      }

      setLoading(false);
    } catch (error) {
      console.error("Failed to parse configuration:", error);
      setError("Failed to load demo. Invalid configuration.");
      setLoading(false);
    }
  }, []);

  if (loading) {
    return (
      <div
        style={{
          display: "flex",
          height: "100vh",
          justifyContent: "center",
          alignItems: "center",
          color: "#666",
          fontFamily: "sans-serif",
        }}
      >
        Loading demo...
      </div>
    );
  }

  if (error) {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          height: "100vh",
          justifyContent: "center",
          alignItems: "center",
          color: "#666",
          fontFamily: "sans-serif",
          padding: "2rem",
          textAlign: "center",
        }}
      >
        <h2>Error</h2>
        <p>{error}</p>
        <a
          href="/tools/demo-creator"
          style={{ color: "#2277C8", marginTop: "1rem" }}
        >
          Go back to Demo Creator
        </a>
      </div>
    );
  }

  return <DemoPreviewPage config={config} />;
};

export default DemoPreviewRoute;
