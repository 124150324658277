import React, { useState, useEffect } from "react";
import { Box, Snackbar, Alert, Typography } from "@mui/material";
import { useStytchUser } from "@stytch/react";
import { useNavigate } from "react-router-dom";
import {
  logGameEnded,
  logGameShared,
  logGuessMade,
  logAbandonedGame,
  logFeedbackGiven,
} from "../../../analytics";
import GameGrid from "./GameBoard/GameGrid";
import Keyboard from "./GameBoard/Keyboard";
import GameControls from "./GameBoard/GameControls";
import GameOver from "./GameBoard/GameOver";
import LockedContent from "./LockedContent";
import GoogleAd from "./GoogleAd";
import {
  GameOverShare,
  ShareButton,
  ShareModal,
} from "../../../components/ShareModal";
import {
  getStreakFromStorage,
  newStreak,
  updateStreak,
  getIsStreakActive,
} from "../../../components/StreakUtil";
import Footer from "../../../components/Footer";
import { motion, AnimatePresence } from "framer-motion";
import { GameHeader } from "./GameBoard/GameHeader";
import { handleAchievementsGameEnded } from "../../../components/AchievementsUtil";
import SurveyModal from "../../../components/SurveyModal";

const BaseWordyGame = ({
  title,
  customTitle,
  iconPath,
  subtitle,
  shareText,
  shareUrl,
  getWordForDate,
  findLatestAvailableDate,
  correctColor = ["#91B2D1", "black"],
  presentColor = ["#ECB061", "white"],
  absentColor = ["white", "#010101"],
  successBackgroundColor = "#91B2D1",
  shareButtonColor = ["black", "#333"],
  keyboardBackgroundColor = "white",
  shareEmojiCorrect = "🟦",
  shareEmojiPresent = "🟨",
  shareEmojiAbsent = "⬜️",
  isEmbedded = false,
  bannerPath,
  publicationId = null,
  hideTitle = false,
}) => {
  const { user } = useStytchUser();
  const navigate = useNavigate();
  const [currentDate, setCurrentDate] = useState(null);
  const [wordData, setWordData] = useState(null);
  const [guesses, setGuesses] = useState([]);
  const [currentGuess, setCurrentGuess] = useState("");
  const [gameOver, setGameOver] = useState(false);
  const [showHint, setShowHint] = useState(false);
  const [showShareToast, setShowShareToast] = useState(false);
  const [isGuessFocused, setIsGuessFocused] = useState(false);
  const [shareModalOpen, setShareModalOpen] = useState(false);
  const [isValidating, setIsValidating] = useState(false);
  const [invalidWord, setInvalidWord] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [isPreSolveShare, setIsPreSolveShare] = useState(false);
  const [gameState, setGameState] = useState(null);
  const [streak, setStreak] = useState(
    getStreakFromStorage(publicationId).count
  );
  const [isStreakActive, setIsStreakActive] = useState(
    getIsStreakActive(publicationId)
  );
  const [isFirstSolver, setIsFirstSolver] = useState(false);
  const [showSurveyModal, setShowSurveyModal] = useState(false);

  const isDateLocked = (date) => {
    return false;
    if (user) return false;
    const gameDate = new Date(date);
    const currentDate = new Date();
    const differenceInTime = currentDate.getTime() - gameDate.getTime();
    const differenceInDays = differenceInTime / (1000 * 3600 * 24);
    return differenceInDays > 7;
  };

  // Initial game setup
  useEffect(() => {
    const latestWordData = findLatestAvailableDate();
    if (latestWordData) {
      setCurrentDate(normalizeDate(latestWordData.date));
      setWordData(latestWordData);
    }
  }, []);

  // Handle date changes
  useEffect(() => {
    const data = getWordForDate(currentDate);
    if (!data) {
      return;
    }

    setWordData(data);
    setGuesses([]);
    setCurrentGuess("");
    setGameOver(false);
    setShowHint(false);
  }, [currentDate]);

  useEffect(() => {
    const handleUnload = () => {
      if (!gameOver && guesses.length > 0 && currentDate) {
        const dateStr = currentDate.toLocaleDateString("en-US");
        logAbandonedGame(title, publicationId, {
          attempts: guesses.length,
          word: wordData.word,
          date: dateStr,
        });
      }
    };

    window.addEventListener("beforeunload", handleUnload);
    return () => window.removeEventListener("beforeunload", handleUnload);
  }, [gameOver, guesses]);

  // Show survey modal on game load
  useEffect(() => {
    // Only show for Alameda Post publication
    if (publicationId !== "ZqUdNcP7DWb2WexYu7GDRd") {
      return;
    }

    // Check if we're within the survey period
    const currentDate = new Date();
    const endDate = new Date("2025-03-30T23:59:59");
    const withinSurveyPeriod = currentDate <= endDate;

    // Always show the survey if we're within the survey period
    if (withinSurveyPeriod) {
      // Add a slight delay to let the game load first
      const timer = setTimeout(() => {
        setShowSurveyModal(true);
      }, 500);

      return () => clearTimeout(timer);
    }
  }, [publicationId]);

  // Function to handle survey modal close
  const handleSurveyClose = () => {
    setShowSurveyModal(false);
  };

  const validateWord = async (word) => {
    const controller = new AbortController();
    const timeoutId = setTimeout(() => controller.abort(), 1000);

    try {
      const response = await fetch(
        `https://api.dictionaryapi.dev/api/v2/entries/en/${word}`,
        { signal: controller.signal }
      );
      clearTimeout(timeoutId);
      return response.status !== 404;
    } catch (error) {
      // If fetch times out or any error occurs, return true to allow the user to keep playing
      return true;
    }
  };

  const handleGuessUpdate = async (key) => {
    if (!wordData || gameOver) return;

    const dateStr = currentDate.toLocaleDateString("en-US");

    if (key === "ENTER") {
      if (currentGuess.length !== wordData.word.length) {
        return;
      }

      setIsValidating(true);

      const isCorrect =
        currentGuess.toUpperCase() === wordData.word.toUpperCase();

      const isValid = await validateWord(currentGuess);

      if (!isValid && !isCorrect) {
        setInvalidWord(true);
        setErrorMessage("Hey... that's not a word!");
        setIsValidating(false);

        logGuessMade(title, publicationId, {
          attempt: guesses.length + 1,
          word: wordData.word,
          isCorrect,
          date: dateStr,
          isValid,
        });

        // Reset invalid state after animation
        setTimeout(() => {
          setInvalidWord(false);
          setErrorMessage(""); // This will make the Alert disappear
        }, 1000);
        return;
      }

      setIsValidating(false);
      setInvalidWord(false);

      const newGuesses = [...guesses, currentGuess];
      setGuesses(newGuesses);

      logGuessMade(title, publicationId, {
        attempt: newGuesses.length,
        word: wordData.word,
        isCorrect,
        date: dateStr,
        isValid,
      });

      setCurrentGuess("");

      if (isCorrect || newGuesses.length >= 5) {
        // Update streak when game ends, regardless of win/loss
        const newStreak = updateStreak(currentDate, publicationId);
        setStreak(newStreak);
        setIsStreakActive(true);

        logGameEnded(title, publicationId, {
          won: isCorrect,
          attempts: newGuesses.length,
          word: wordData.word,
          date: dateStr,
          streak: newStreak,
        });

        setGameOver(true);

        // Mark as first solver if applicable
        if (isCorrect && !wordData.has_been_solved && publicationId != null) {
          setIsFirstSolver(true);
          handleFirstSolved();
        }

        handleAchievementsGameEnded({
          solved: isCorrect,
          attempts: newGuesses.length,
          maxAttempts: 5,
          isFirstSolver: isFirstSolver,
          theme: wordData.theme,
          streakCount: newStreak,
          publicationId: publicationId,
        });

        setShareModalOpen(true);
      }

      setCurrentGuess("");
    } else if (key === "BACKSPACE") {
      setCurrentGuess((prev) => prev.slice(0, -1));
    } else if (currentGuess.length < wordData.word.length) {
      setCurrentGuess((prev) => prev + key);
    }
  };

  const normalizeDate = (date) => {
    if (date instanceof Date) {
      return date; // Already a Date object, return as is
    }
    return new Date(date + "T00:00:00-08:00"); // Convert string to Date
  };

  const changeDate = (increment) => {
    if (!currentDate) return;

    const newDate = new Date(currentDate);
    newDate.setDate(newDate.getDate() + increment);

    const newWordData = getWordForDate(newDate);
    if (newWordData && newWordData.date) {
      setCurrentDate(normalizeDate(newWordData.date));
    }
  };

  const handleShare = () => {
    // Determine game state
    if (guesses.length > 0) {
      const won =
        guesses[guesses.length - 1].toUpperCase() ===
        wordData.word.toUpperCase();
      setGameState(won ? "success" : "failure");
    }
    setShareModalOpen(true);
  };

  // This function provides a robust clipboard implementation
  // that works across browsers including Chrome with its stricter permissions

  const copyToClipboard = (text) => {
    return new Promise((resolve, reject) => {
      // First try using the Clipboard API (modern approach)
      if (navigator.clipboard && navigator.clipboard.writeText) {
        navigator.clipboard
          .writeText(text)
          .then(() => resolve(true))
          .catch((err) => {
            console.warn("Clipboard API failed, trying fallback:", err);

            // Try the fallback method if Clipboard API fails
            const success = fallbackCopyMethod(text);
            if (success) {
              resolve(true);
            } else {
              reject(new Error("All clipboard methods failed"));
            }
          });
      } else {
        // Clipboard API not available, use fallback
        const success = fallbackCopyMethod(text);
        if (success) {
          resolve(true);
        } else {
          reject(new Error("Fallback clipboard method failed"));
        }
      }
    });
  };

  // Fallback method that uses the older execCommand approach
  const fallbackCopyMethod = (text) => {
    try {
      // Create temporary element
      const textArea = document.createElement("textarea");
      textArea.value = text;

      // Set styles to make it as invisible as possible but still functional
      textArea.style.position = "fixed";
      textArea.style.left = "0";
      textArea.style.top = "0";
      textArea.style.width = "2em";
      textArea.style.height = "2em";
      textArea.style.padding = "0";
      textArea.style.border = "none";
      textArea.style.outline = "none";
      textArea.style.boxShadow = "none";
      textArea.style.background = "transparent";
      textArea.style.zIndex = "999999";

      document.body.appendChild(textArea);

      // Focus and select the text
      textArea.focus();
      textArea.select();

      // Execute the copy command
      const successful = document.execCommand("copy");

      // Clean up
      document.body.removeChild(textArea);

      return successful;
    } catch (err) {
      console.error("Fallback clipboard method error:", err);
      return false;
    }
  };

  const handleFirstSolved = async () => {
    const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
    try {
      const response = await fetch(`${API_BASE_URL}/words/${wordData.id}`, {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          mark_as_solved: true,
          encoded_publication_id: publicationId,
        }),
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.detail || "Failed to update word");
      }
    } catch (error) {
      console.error("Failed to mark word as solved:", error);
      return null;
    }
  };

  const handleShareConfirm = () => {
    const dateStr = currentDate.toLocaleDateString("en-US");
    let shareString;

    // If there are no guesses yet, just share the game link
    if (guesses.length === 0) {
      shareString = `${shareText} for ${dateStr}\n\nPlay at: ${shareUrl}`;
    } else {
      // Original sharing logic for when there are guesses
      const attemptCount = guesses.length;
      const won =
        guesses[guesses.length - 1].toUpperCase() ===
        wordData.word.toUpperCase();

      shareString = `${shareText} ${dateStr} ${won ? attemptCount : "X"}/5\n\n`;
      shareString += guesses
        .map((guess) =>
          Array.from(guess)
            .map((letter, i) => getShareEmoji(letter, i, wordData.word))
            .join("")
        )
        .join("\n");
      shareString += `\n\nPlay at: ${shareUrl}`;
    }

    // Use our enhanced clipboard function
    copyToClipboard(shareString)
      .then(() => {
        setShowShareToast(true);

        // Log share analytics
        if (guesses.length === 0) {
          logGameShared(title, publicationId, {
            isPreShare: true,
            word: wordData.word,
            date: dateStr,
          });
        } else {
          const won =
            guesses[guesses.length - 1].toUpperCase() ===
            wordData.word.toUpperCase();

          logGameShared(title, publicationId, {
            won: won,
            attempts: guesses.length,
            word: wordData.word,
            date: dateStr,
          });
        }
      })
      .catch((err) => {
        console.error("Share failed:", err);
        // Show a fallback toast to let the user know copying failed
        alert(
          "Sorry, couldn't copy to clipboard. Please copy this manually:\n\n" +
            shareString
        );
      });
  };

  const getLetterBGColor = (letter, index, isGuessed, rowIndex) => {
    if (!isGuessed || !wordData) return "white";

    const guessUpperCase = letter.toUpperCase();
    const targetWord = wordData.word.toUpperCase();
    const currentRowGuess = guesses[rowIndex].toUpperCase();

    // First check for exact match
    if (guessUpperCase === targetWord[index]) {
      return correctColor;
    }

    // If not an exact match, we need to check if this letter can be yellow
    // Count how many times this letter appears in the target word
    const letterCountInTarget = targetWord.split(guessUpperCase).length - 1;

    if (letterCountInTarget === 0) {
      return absentColor;
    }

    // Count how many exact matches of this letter exist
    // and how many times it appears before this position in THIS row's guess
    let exactMatches = 0;
    let yellowCandidatesSoFar = 0;

    for (let i = 0; i < currentRowGuess.length; i++) {
      if (currentRowGuess[i] === guessUpperCase) {
        if (currentRowGuess[i] === targetWord[i]) {
          exactMatches++;
        } else if (i < index) {
          yellowCandidatesSoFar++;
        }
      }
    }

    // Calculate remaining yellows available
    const remainingYellows = letterCountInTarget - exactMatches;

    // If we still have yellows available and haven't used them all up
    // in previous positions of this guess
    if (remainingYellows > 0 && yellowCandidatesSoFar < remainingYellows) {
      return presentColor;
    }

    return absentColor;
  };

  const getShareEmoji = (letter, index, word) => {
    if (letter.toUpperCase() === word[index].toUpperCase())
      return shareEmojiCorrect;
    if (word.toUpperCase().includes(letter.toUpperCase()))
      return shareEmojiPresent;
    return shareEmojiAbsent;
  };

  const onRateWord = (isPositiveRating, isCorrect) => {
    if (currentDate) {
      const dateStr = currentDate.toLocaleDateString("en-US");
      logFeedbackGiven(title, isPositiveRating, publicationId, {
        won: isCorrect,
        word: wordData.word,
        date: dateStr,
      });
    }
  };

  if (!wordData) return null;

  return (
    <Box
      sx={{
        textAlign: "center",
        p: 2,
        minHeight: isEmbedded ? "none" : "100vh",
        display: "flex",
        flexDirection: "column",
        backgroundColor: "background.default",
        position: "relative",
      }}
    >
      <Box>
        <SurveyModal
          open={showSurveyModal}
          onClose={handleSurveyClose}
          gameTitle={title}
          publicationId={publicationId}
        />

        {!hideTitle && (
          <GameHeader
            title={customTitle || title}
            subtitle={subtitle}
            iconPath={iconPath}
            bannerPath={bannerPath}
          />
        )}

        {wordData.theme && (
          <Box sx={{ textAlign: "center", my: 1 }}>
            <Typography
              variant="h6"
              component="h2"
              sx={{
                fontWeight: 600,
                fontSize: "1.25rem",
                color: "text.primary",
              }}
            >
              <strong fontWeight="800">Theme:</strong> {wordData.theme}
            </Typography>
          </Box>
        )}

        <GameControls
          currentDate={currentDate}
          onDateChange={changeDate}
          showHint={showHint}
          onHintToggle={() => setShowHint(!showHint)}
          wordData={wordData}
          correctColor={correctColor}
          presentColor={presentColor}
          absentColor={absentColor}
          streak={streak}
          publicationId={publicationId}
          isFirstSolver={isFirstSolver}
          isStreakActive={isStreakActive}
        />
        <AnimatePresence>
          {!!errorMessage && (
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0 }}
              transition={{ duration: 0.3 }}
              style={{
                position: "absolute",
                top: "23%",
                left: "0",
                transform: "translateX(-50%)",
                zIndex: 1000,
                width: "100%",
              }}
            >
              <Alert
                severity="error"
                sx={{
                  width: { xs: "80%", md: "20%" },
                  maxWidth: "400px",
                  mx: "auto",
                  fontSize: "1rem",
                  backgroundColor: "white",
                  border: "1px solid black",
                  borderRadius: "5px",
                  color: "black",
                  "& .MuiAlert-message": {
                    fontSize: "1rem",
                  },
                }}
              >
                {errorMessage}
              </Alert>
            </motion.div>
          )}
        </AnimatePresence>

        {isDateLocked(wordData.date) ? (
          <LockedContent onSignUp={() => navigate("/wordy-verse/auth")} />
        ) : (
          <>
            <GameGrid
              wordData={wordData}
              guesses={guesses}
              currentGuess={currentGuess}
              getLetterBGColor={getLetterBGColor}
              invalidWord={invalidWord} // Add this prop
            />

            <Keyboard
              onGuessUpdate={handleGuessUpdate}
              currentGuess={currentGuess}
              wordData={wordData}
              gameOver={gameOver}
              isGuessFocused={isGuessFocused}
              isLocked={false}
              guesses={guesses}
              correctColor={correctColor}
              presentColor={presentColor}
              absentColor={absentColor}
              keyboardBackgroundColor={keyboardBackgroundColor}
            />

            <ShareModal
              open={shareModalOpen}
              onClose={() => setShareModalOpen(false)}
              onShare={handleShareConfirm}
              guesses={guesses}
              word={wordData.word}
              correctColor={correctColor}
              presentColor={presentColor}
              absentColor={absentColor}
              successBackgroundColor={successBackgroundColor}
              shareButtonColor={shareButtonColor}
              maxGuesses={5}
              onRateWord={onRateWord}
              isCorrect={
                guesses.length > 0 &&
                guesses[guesses.length - 1].toUpperCase() ===
                  wordData.word.toUpperCase()
              }
              isFirstSolver={isFirstSolver}
              onCreateAccount={() => navigate("/wordy-verse/auth")}
            />

            {!isEmbedded && <ShareButton onClick={handleShare} />}

            {!isEmbedded && (
              <Box
                sx={{
                  maxWidth: "600px",
                  border: "1px black dotted",
                  textAlign: "center",
                  p: 2,
                  mb: 2,
                  mx: "auto",
                  backgroundColor: "background.default",
                  overflow: "hidden", // This helps contain the ad
                }}
              >
                <GoogleAd slot="9715652655" />
              </Box>
            )}
          </>
        )}

        <Snackbar
          open={showShareToast}
          autoHideDuration={3000}
          onClose={() => setShowShareToast(false)}
          message="Results copied to clipboard!"
        />
      </Box>
      {!isEmbedded && <Footer />}
    </Box>
  );
};

export default BaseWordyGame;
