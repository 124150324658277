import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import BaseKeyword from "../../Keyword/BaseKeyword";

const JocoKeywordDemo = () => {
  const [wordData, setWordData] = useState(null);

  useEffect(() => {
    // Static data for demo
    setWordData({
      wordList: ["PIECES", "DONATE", "LEGOS", "FOSTER", "SORT"],
      answer: "COLOR",
    });
  }, []);

  if (!wordData) return null;

  return (
    <>
      <BaseKeyword
        title="Johnson County Post"
        subtitle="Slide each word horizontally until the center letters line up to reveal the keyword."
        wordList={wordData.wordList}
        isEmbedded={true}
        buttonColor="#2273BE"
        modalBackgroundColor="#2273BE"
        answer={wordData.answer}
        shareText="I played Keyword"
        shareUrl="https://demo.flyingcometgames.com/joco"
        publicationName="Johnson County Post article"
        articleTitle="Roeland Park nonprofit gives used Legos away to foster kids. They need help sorting millions of pieces"
        articleUrl="https://johnsoncountypost.com/2025/03/27/roeland-park-lego-sorting-event-256071/"
      />
    </>
  );
};

export default JocoKeywordDemo;
