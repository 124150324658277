import React, { useState, useEffect, useRef } from "react";
import {
  Box,
  Typography,
  Button,
  Grid,
  Paper,
  Snackbar,
  IconButton,
  Tooltip,
  Fade,
  Zoom,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import ShareIcon from "@mui/icons-material/Share";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import CloseIcon from "@mui/icons-material/Close";
// New imports
import UndoIcon from "@mui/icons-material/Undo";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import { motion, AnimatePresence } from "framer-motion";
import Confetti from "react-confetti";
import StartIcon from "@mui/icons-material/PlayCircleOutline";

const GRID_SIZE = 10;
// Define colors using ONLY the exact Wordy brand colors specified, but with increased contrast
const COLORS = [
  {
    id: "olive",
    color: "#b8c26c", // olive green
    borderColor: "#8a9340", // darker border for contrast
  },
  {
    id: "amber",
    color: "#ECB061", // amber
    borderColor: "#c48a3a", // darker border for contrast
  },
  {
    id: "blue",
    color: "#91B2D1", // light blue
    borderColor: "#6a8eae", // darker border for contrast
  },
  {
    id: "lavender",
    color: "#c9bbde", // lavender
    borderColor: "#a394bb", // darker border for contrast
  },
  {
    id: "teal",
    color: "#5BBFBA", // teal (replacing tan for better differentiation)
    borderColor: "#3a9a96", // darker border for contrast
  },
];

// Number of maximum undos allowed
const MAX_UNDOS = 3;

// Background color matching the app's cream/beige theme
const BACKGROUND_COLOR = "#F5F2E7"; // Cream background from screenshot
const ACCENT_COLOR = "#b8c26c"; // Olive green from brand colors
const SECONDARY_ACCENT = "#ECB061"; // Amber as secondary accent

const ColorFlood = () => {
  const theme = useTheme();
  const [grid, setGrid] = useState([]);
  const [currentColor, setCurrentColor] = useState("");
  const [moves, setMoves] = useState(0);
  const [gameOver, setGameOver] = useState(false);
  const [win, setWin] = useState(false);
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [showHint, setShowHint] = useState(false);
  const [currentDate, setCurrentDate] = useState(new Date());
  const hasInitialized = useRef(false);
  const [undosLeft, setUndosLeft] = useState(MAX_UNDOS);
  const [previousGrids, setPreviousGrids] = useState([]);
  const [isAnimating, setIsAnimating] = useState(false);
  const [floodedPercentage, setFloodedPercentage] = useState(0);

  // Tutorial state
  const [tutorialActive, setTutorialActive] = useState(false); // Start with tutorial inactive by default
  const [tutorialStep, setTutorialStep] = useState(1);
  const [showTutorialTooltip, setShowTutorialTooltip] = useState(true);
  const [skipTooltipTimeout, setSkipTooltipTimeout] = useState(null);
  const [tutorialMoveCount, setTutorialMoveCount] = useState(0);
  const [tutorialAnimation, setTutorialAnimation] = useState(false);
  const [hasChosenAdjacentColor, setHasChosenAdjacentColor] = useState(false);
  const [showConfetti, setShowConfetti] = useState(false);

  // Tutorial refs
  const startingTileRef = useRef(null);
  const colorButtonsRef = useRef([]);

  useEffect(() => {
    // Only initialize if not already initialized or if the date changes
    if (!hasInitialized.current || !gameOver) {
      initializeFixedPuzzle();
      hasInitialized.current = true;
    }
  }, [currentDate]); // Re-run when date changes

  useEffect(() => {
    const startTime = Date.now();
    return () => {
      const sessionTime = (Date.now() - startTime) / 1000; // in seconds
    };
  }, []);

  // Calculate flooded percentage whenever the grid or current color changes
  useEffect(() => {
    if (grid.length > 0) {
      calculateFloodedPercentage();
    }
  }, [grid, currentColor]);

  // Function to calculate the percentage of the board that has been flooded
  const calculateFloodedPercentage = () => {
    if (!grid.length) return;

    const totalCells = GRID_SIZE * GRID_SIZE;
    const floodedCells = grid
      .flat()
      .filter((cell) => cell === currentColor).length;
    const percentage = Math.round((floodedCells / totalCells) * 100);
    setFloodedPercentage(percentage);
  };

  // Generate a predictable grid based on the date
  const initializeFixedPuzzle = () => {
    // Use the date as seed for pseudo-randomness
    const dateString = `${currentDate.getFullYear()}${currentDate.getMonth()}${currentDate.getDate()}`;
    const seed = parseInt(dateString, 10);

    // Simple seeded random function
    const seededRandom = (seed, i = 0) => {
      const x = Math.sin(seed + i) * 10000;
      return x - Math.floor(x);
    };

    // Generate grid using the seeded random
    const newGrid = Array(GRID_SIZE)
      .fill()
      .map((_, rowIndex) =>
        Array(GRID_SIZE)
          .fill()
          .map((_, colIndex) => {
            const colorIndex = Math.floor(
              seededRandom(seed, rowIndex * GRID_SIZE + colIndex) *
                COLORS.length
            );
            return COLORS[colorIndex].id;
          })
      );

    setGrid(newGrid);
    setCurrentColor(newGrid[0][0]);
    setMoves(0);
    setGameOver(false);
    setWin(false);
    setUndosLeft(MAX_UNDOS);
    setPreviousGrids([]);
  };

  // Helper function to check if two colors are adjacent in the current grid
  const checkAdjacentColors = (colorId) => {
    if (!grid || grid.length === 0) return false;

    // Get the current flooded region (all cells with current color)
    const floodedRegion = [];
    const currentColorValue = currentColor;

    // Find all cells with current color
    for (let i = 0; i < GRID_SIZE; i++) {
      for (let j = 0; j < GRID_SIZE; j++) {
        if (grid[i][j] === currentColorValue) {
          floodedRegion.push([i, j]);
        }
      }
    }

    // Check if any cell in the new colorId is adjacent to the flooded region
    for (const [x, y] of floodedRegion) {
      // Check all adjacent cells
      const adjacentCells = [
        [x + 1, y],
        [x - 1, y],
        [x, y + 1],
        [x, y - 1],
      ];

      for (const [nx, ny] of adjacentCells) {
        // Check if in bounds
        if (nx >= 0 && nx < GRID_SIZE && ny >= 0 && ny < GRID_SIZE) {
          // If an adjacent cell has the target color, it's adjacent
          if (grid[nx][ny] === colorId) {
            return true;
          }
        }
      }
    }

    return false;
  };

  // Initialize with tutorial active only on first visit (using sessionStorage to avoid annoying returning users)
  useEffect(() => {
    // Check if the user has seen the tutorial in this session
    const hasSeenTutorial = sessionStorage.getItem("hasSeenColorFloodTutorial");

    if (!hasSeenTutorial) {
      // Set a small delay to avoid the tutorial starting immediately on load
      const timer = setTimeout(() => {
        setTutorialActive(true);
        setTutorialStep(1);
        setShowTutorialTooltip(true);
      }, 800);

      return () => clearTimeout(timer);
    }
  }, []);

  // Mark tutorial as seen when it's completed or skipped
  useEffect(() => {
    if (!tutorialActive && tutorialStep > 1) {
      // Only mark as seen if the user has actually started the tutorial (step > 1)
      // This prevents marking it as seen if the initial state is inactive
      sessionStorage.setItem("hasSeenColorFloodTutorial", "true");
    }
  }, [tutorialActive, tutorialStep]);

  // Tutorial initialization and cleanup
  useEffect(() => {
    if (tutorialActive) {
      // Set up tooltip visibility pulsing for better visibility
      const interval = setInterval(() => {
        if (tutorialStep === 1) {
          setShowTutorialTooltip((prev) => !prev);
          setTimeout(() => {
            setShowTutorialTooltip(true);
          }, 500);
        }
      }, 8000);

      return () => {
        clearInterval(interval);
        if (skipTooltipTimeout) {
          clearTimeout(skipTooltipTimeout);
        }
      };
    }
  }, [tutorialStep, tutorialActive, skipTooltipTimeout]);

  // Function to progress through tutorial
  const advanceTutorial = () => {
    if (!tutorialActive) return;

    // Clear any existing timeout
    if (skipTooltipTimeout) {
      clearTimeout(skipTooltipTimeout);
    }

    // Move to next step
    setTutorialStep((prevStep) => prevStep + 1);
    setShowTutorialTooltip(true);

    // Auto-dismiss tooltip after a few seconds
    const timeout = setTimeout(() => {
      setShowTutorialTooltip(false);
    }, 7000);

    setSkipTooltipTimeout(timeout);

    // If we reached the end of the tutorial
    if (tutorialStep >= 3) {
      // Wait a bit before ending the tutorial to let the user see the final message
      const endTutorialTimer = setTimeout(() => {
        skipTutorial();
      }, 8000);

      setSkipTooltipTimeout(endTutorialTimer);
    }
  };

  // Function to skip the tutorial
  const skipTutorial = () => {
    setTutorialActive(false);
    setShowTutorialTooltip(false);

    if (skipTooltipTimeout) {
      clearTimeout(skipTooltipTimeout);
    }

    // Mark as seen in session storage
    sessionStorage.setItem("hasSeenColorFloodTutorial", "true");
  };

  // Modify the floodFill function to handle tutorial progress
  const floodFill = (colorId) => {
    if (colorId === currentColor || gameOver || isAnimating) return;

    // Save current grid for undo
    setPreviousGrids([...previousGrids, JSON.parse(JSON.stringify(grid))]);

    const newGrid = [...grid];
    const stack = [[0, 0]];
    const originalColor = newGrid[0][0];
    const previousFloodedCount = countFloodedCells(newGrid, currentColor);

    // Set animating state
    setIsAnimating(true);

    // For tutorial: Add animation and track progress
    if (tutorialActive) {
      setTutorialAnimation(true);
      setTutorialMoveCount((prev) => prev + 1);

      // Check if we're in step 1 and progress to step 2
      if (tutorialStep === 1) {
        // Add a slight delay before advancing to step 2 to let the user see the animation
        setTimeout(() => {
          advanceTutorial();
        }, 500);
      }

      // For step 2, check if they chose an adjacent color (beneficial move)
      if (tutorialStep === 2) {
        // Check if the selected color is adjacent to current flooded region
        const isAdjacentColor = checkAdjacentColors(colorId);
        setHasChosenAdjacentColor(isAdjacentColor);

        // If they chose a good color, progress to next step
        if (isAdjacentColor) {
          setTimeout(() => {
            advanceTutorial();
          }, 1200);
        } else {
          // Show the tip about adjacent colors
          setTimeout(() => {
            setTutorialStep(2.5);
            setShowTutorialTooltip(true);

            // Auto-hide the tip after 5 seconds and go back to step 2
            setTimeout(() => {
              setTutorialStep(2);
              setShowTutorialTooltip(true);
            }, 5000);
          }, 1000);
        }
      }
    }

    // Vibration feedback for mobile devices (if supported)
    if (navigator.vibrate) {
      navigator.vibrate(30);
    }

    while (stack.length > 0) {
      const [x, y] = stack.pop();
      if (
        x < 0 ||
        x >= GRID_SIZE ||
        y < 0 ||
        y >= GRID_SIZE ||
        newGrid[x][y] !== originalColor
      )
        continue;

      newGrid[x][y] = colorId;
      stack.push([x + 1, y], [x - 1, y], [x, y + 1], [x, y - 1]);
    }

    setGrid(newGrid);
    setCurrentColor(colorId);
    setMoves(moves + 1);

    const newMoves = moves + 1;

    // Calculate flooded percentage for animation
    const newFloodedCount = countFloodedCells(newGrid, colorId);
    const floodPercentage = (newFloodedCount / (GRID_SIZE * GRID_SIZE)) * 100;
    setFloodedPercentage(Math.round(floodPercentage));

    // End animation state after a short delay
    setTimeout(() => {
      setIsAnimating(false);
      if (tutorialActive) {
        setTutorialAnimation(false);
      }
    }, 300);

    // Check if game is won
    if (newGrid.every((row) => row.every((cell) => cell === colorId))) {
      setWin(true);
      setGameOver(true);
      setShowConfetti(true);

      // Tutorial completion
      if (tutorialActive && tutorialStep === 3) {
        setTutorialStep(4);
        setShowTutorialTooltip(true);
      }

      setSnackbarMessage(
        `Well done! You completed the puzzle in ${newMoves} moves.`
      );
      setShowSnackbar(true);
    }
  };

  // Helper function to count flood cells
  const countFloodedCells = (grid, colorId) => {
    return grid.reduce((count, row) => {
      return count + row.filter((cell) => cell === colorId).length;
    }, 0);
  };

  // Undo last move
  const undoMove = () => {
    if (undosLeft <= 0 || previousGrids.length === 0 || gameOver) return;

    // Vibration feedback for undo
    if (navigator.vibrate) {
      navigator.vibrate([20, 30, 20]);
    }

    const lastGrid = previousGrids.pop();
    setPreviousGrids([...previousGrids]);
    setGrid(lastGrid);
    setCurrentColor(lastGrid[0][0]);
    setMoves(Math.max(0, moves - 1));
    setUndosLeft(undosLeft - 1);
  };

  // Reset current puzzle
  const resetPuzzle = () => {
    // Vibration feedback for reset
    if (navigator.vibrate) {
      navigator.vibrate([10, 20, 10, 20, 10]);
    }

    initializeFixedPuzzle();
  };

  const getColorById = (id) => {
    return COLORS.find((color) => color.id === id);
  };

  const getHoverColor = (colorId) => {
    const color = getColorById(colorId).color;
    // More pronounced darkening for better contrast
    const darkenColor = (color, amount) => {
      return (
        "#" +
        color
          .replace(/^#/, "")
          .replace(/../g, (color) =>
            (
              "0" +
              Math.min(255, Math.max(0, parseInt(color, 16) - amount)).toString(
                16
              )
            ).substr(-2)
          )
      );
    };
    return darkenColor(color, 30); // Increased darkening amount
  };

  const handleShare = () => {
    const dateStr = currentDate.toLocaleDateString("en-US", {
      month: "short",
      day: "numeric",
      year: "numeric",
    });

    const shareText = `Color Flood ${dateStr} - Completed in ${moves} moves!\n\nPlay at: ${window.location.href}`;

    // Use navigator.clipboard directly for better browser support
    navigator.clipboard
      .writeText(shareText)
      .then(() => {
        setSnackbarMessage("Score copied to clipboard!");
        setShowSnackbar(true);
      })
      .catch((error) => {
        console.error("Failed to copy text: ", error);
        setSnackbarMessage("Failed to copy score. Please try again.");
        setShowSnackbar(true);
      });
  };

  const changeDate = (increment) => {
    // Change the date and let the useEffect handle refreshing the grid
    const newDate = new Date(currentDate);
    newDate.setDate(newDate.getDate() + increment);
    setCurrentDate(newDate);

    // Force refresh of the grid
    hasInitialized.current = false;
  };

  // Get today's date with time set to midnight
  const today = new Date();
  today.setHours(0, 0, 0, 0);

  // Format the date as shown in the screenshot (e.g., "Mar 27, 2025")
  const formattedDate = currentDate.toLocaleDateString("en-US", {
    month: "short",
    day: "numeric",
    year: "numeric",
  });

  // Get text color based on background color brightness
  const getTextColor = (backgroundColor) => {
    // Convert hex to RGB
    const r = parseInt(backgroundColor.slice(1, 3), 16);
    const g = parseInt(backgroundColor.slice(3, 5), 16);
    const b = parseInt(backgroundColor.slice(5, 7), 16);

    // Calculate brightness (YIQ formula)
    const brightness = (r * 299 + g * 587 + b * 114) / 1000;

    // Return black for bright colors, white for dark colors
    return brightness > 128 ? "#000000" : "#FFFFFF";
  };

  // Confetti cleanup
  useEffect(() => {
    if (showConfetti) {
      const timeout = setTimeout(() => {
        setShowConfetti(false);
      }, 5000);

      return () => clearTimeout(timeout);
    }
  }, [showConfetti]);

  // How to Play Modal with GIF Demo
  const renderHowToPlayModal = () => {
    return (
      <Paper
        elevation={3}
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          zIndex: 1000,
          width: "90%",
          maxWidth: "400px",
          p: 3,
          borderRadius: 2,
          backgroundColor: "white",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            mb: 1,
          }}
        >
          <Typography variant="h5" sx={{ fontWeight: 700, color: "#333" }}>
            How to Play
          </Typography>
          <IconButton
            onClick={() => setShowHint(false)}
            sx={{ p: 0.5 }}
            aria-label="Close"
          >
            <CloseIcon />
          </IconButton>
        </Box>

        <Box sx={{ mb: 2, textAlign: "center" }}>
          {/* Animated GIF Demo Placeholder */}
          <Box
            component={motion.div}
            animate={{
              boxShadow: [
                "0 0 0 rgba(0,0,0,0.1)",
                "0 0 10px rgba(0,0,0,0.2)",
                "0 0 0 rgba(0,0,0,0.1)",
              ],
            }}
            transition={{
              repeat: Infinity,
              duration: 2,
            }}
            sx={{
              width: "100%",
              height: 180,
              bgcolor: "#f8f8f8",
              borderRadius: 2,
              border: "1px solid rgba(0,0,0,0.1)",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              mb: 2,
              overflow: "hidden",
              position: "relative",
            }}
          >
            {/* Simple Animation Demo of ColorFlood */}
            <Box
              sx={{
                position: "relative",
                width: "70%",
                height: "70%",
                display: "grid",
                gridTemplateColumns: "repeat(5, 1fr)",
                gap: 0.5,
              }}
            >
              {[...Array(25)].map((_, i) => {
                // Create a simple animation pattern for the demo
                const row = Math.floor(i / 5);
                const col = i % 5;
                const isStartArea = row < 2 && col < 2;
                const isSecondArea = row < 3 && col < 3 && !isStartArea;
                const isThirdArea =
                  row < 4 && col < 4 && !isStartArea && !isSecondArea;

                return (
                  <Box
                    component={motion.div}
                    key={i}
                    initial={{
                      backgroundColor: isStartArea
                        ? COLORS[0].color
                        : COLORS[Math.floor(Math.random() * COLORS.length)]
                            .color,
                    }}
                    animate={{
                      backgroundColor: [
                        isStartArea
                          ? COLORS[0].color
                          : COLORS[Math.floor(Math.random() * COLORS.length)]
                              .color,
                        isSecondArea
                          ? COLORS[0].color
                          : COLORS[Math.floor(Math.random() * COLORS.length)]
                              .color,
                        isThirdArea
                          ? COLORS[0].color
                          : COLORS[Math.floor(Math.random() * COLORS.length)]
                              .color,
                        COLORS[0].color,
                      ],
                      scale: isStartArea ? [1, 1.05, 1] : 1,
                    }}
                    transition={{
                      duration: 8,
                      times: [0, 0.3, 0.6, 1],
                      repeat: Infinity,
                      delay: isStartArea
                        ? 0
                        : isSecondArea
                        ? 2
                        : isThirdArea
                        ? 4
                        : 6,
                    }}
                    sx={{
                      width: "100%",
                      height: "100%",
                      borderRadius: 0.5,
                    }}
                  />
                );
              })}
            </Box>
            <Typography sx={{ mt: 1, fontSize: "0.9rem", color: "#666" }}>
              Flood the board in as few moves as possible!
            </Typography>
          </Box>
        </Box>

        <Typography
          variant="body1"
          sx={{ color: "#555", mb: 1.5, fontSize: "0.95rem" }}
        >
          1. Start from the top-left corner and flood the board with one color.
        </Typography>

        <Typography
          variant="body1"
          sx={{ color: "#555", mb: 1.5, fontSize: "0.95rem" }}
        >
          2. Click on a color to flood-fill connected cells of the same color.
        </Typography>

        <Typography
          variant="body1"
          sx={{ color: "#555", mb: 1.5, fontSize: "0.95rem" }}
        >
          3. Try to make the entire board one color in as few moves as possible.
        </Typography>

        <Typography
          variant="body1"
          sx={{ color: "#555", mb: 2.5, fontSize: "0.95rem" }}
        >
          4. Each day features a new puzzle to solve!
        </Typography>

        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Button
            onClick={() => {
              setShowHint(false);
              setTutorialActive(true);
              setTutorialStep(1);
              setShowTutorialTooltip(true);
            }}
            variant="outlined"
            sx={{
              color: "#555",
              borderColor: "rgba(0,0,0,0.2)",
              fontWeight: 600,
              textTransform: "none",
            }}
          >
            Start Tutorial
          </Button>

          <Button
            onClick={() => setShowHint(false)}
            variant="contained"
            sx={{
              bgcolor: "#b8c26c",
              color: "#000",
              fontWeight: 600,
              textTransform: "none",
            }}
          >
            Got it
          </Button>
        </Box>
      </Paper>
    );
  };

  return (
    <Box
      sx={{ backgroundColor: BACKGROUND_COLOR, height: "100%", width: "100%" }}
    >
      {/* Confetti effect for wins */}
      {showConfetti && (
        <Confetti
          width={window.innerWidth}
          height={window.innerHeight}
          recycle={false}
          numberOfPieces={500}
          gravity={0.15}
          colors={COLORS.map((c) => c.color)}
        />
      )}

      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          px: { xs: 1.5, sm: 3 },
          pt: { xs: 2, sm: 4 },
          pb: { xs: 2, sm: 4 },
          maxWidth: "600px",
          mx: "auto",
          gap: { xs: 1, sm: 2 },
          position: "relative", // For absolute positioning of tutorial elements
        }}
      >
        {/* Tutorial Skip Button */}
        {tutorialActive && (
          <Box
            sx={{
              position: "absolute",
              top: 10,
              right: 10,
              zIndex: 10,
            }}
          >
            <Button
              onClick={skipTutorial}
              variant="contained"
              size="small"
              sx={{
                fontSize: "0.8rem",
                backgroundColor: "white",
                color: "#333",
                borderColor: "rgba(0,0,0,0.4)",
                py: 0.75,
                px: 1.5,
                minWidth: "auto",
                textTransform: "none",
                fontWeight: 600,
                boxShadow: "0 2px 5px rgba(0,0,0,0.2)",
                "&:hover": {
                  backgroundColor: "rgba(255,255,255,0.9)",
                },
              }}
              endIcon={<CloseIcon fontSize="small" />}
            >
              Skip Tutorial
            </Button>
          </Box>
        )}

        {/* Tutorial Tooltip - Step 2 (after first move) */}
        {tutorialActive && tutorialStep === 2 && showTutorialTooltip && (
          <Fade in={true}>
            <Box
              component={motion.div}
              animate={{
                y: [0, -3, 0],
                boxShadow: [
                  "0 2px 10px rgba(0,0,0,0.2)",
                  "0 4px 15px rgba(0,0,0,0.3)",
                  "0 2px 10px rgba(0,0,0,0.2)",
                ],
              }}
              transition={{
                repeat: Infinity,
                duration: 2,
              }}
              sx={{
                position: "absolute",
                top: "22%", // Position it near the top of the board where the first move would spread
                left: "12%", // Positioned to the right of where flooding starts
                transform: "none", // Direct positioning instead of centered
                zIndex: 100, // Higher z-index to appear above the board
                bgcolor: "white",
                p: 1.5,
                borderRadius: 2,
                maxWidth: "80%",
                boxShadow: "0 2px 10px rgba(0,0,0,0.3)",
                "&:before": {
                  content: '""',
                  position: "absolute",
                  top: "10px",
                  left: "-10px",
                  borderWidth: "10px",
                  borderStyle: "solid",
                  borderColor: "transparent white transparent transparent",
                  transform: "translateX(-50%)",
                },
              }}
            >
              <Typography
                sx={{
                  fontSize: "0.95rem",
                  fontWeight: 600,
                  textAlign: "left",
                }}
              >
                Nice! Each move spreads your color to adjacent matching cells.
                Keep going!
              </Typography>
            </Box>
          </Fade>
        )}

        {/* Tutorial Tooltip - Step 2.5 (Strategy Tip) */}
        {tutorialActive && tutorialStep === 2.5 && showTutorialTooltip && (
          <Fade in={true}>
            <Box
              component={motion.div}
              animate={{
                y: [0, -3, 0],
                boxShadow: [
                  "0 2px 10px rgba(0,0,0,0.2)",
                  "0 4px 15px rgba(0,0,0,0.3)",
                  "0 2px 10px rgba(0,0,0,0.2)",
                ],
              }}
              transition={{
                repeat: Infinity,
                duration: 2,
              }}
              sx={{
                position: "absolute",
                bottom: { xs: "180px", sm: "200px" }, // Position it just above the color selection buttons
                left: "50%",
                transform: "translateX(-50%)",
                zIndex: 100,
                bgcolor: "white",
                p: 1.5,
                borderRadius: 2,
                maxWidth: "85%",
                boxShadow: "0 2px 10px rgba(0,0,0,0.3)",
                "&:after": {
                  content: '""',
                  position: "absolute",
                  top: "100%", // Arrow pointing down at the color selector
                  left: "50%",
                  transform: "translateX(-50%)",
                  borderWidth: "8px",
                  borderStyle: "solid",
                  borderColor: "white transparent transparent transparent",
                },
              }}
            >
              <Typography
                sx={{
                  fontSize: "0.95rem",
                  fontWeight: 600,
                  textAlign: "center",
                }}
              >
                <Box
                  component="span"
                  sx={{ color: "#EC8B5E", fontWeight: 800 }}
                >
                  Tip:
                </Box>{" "}
                Pick a color that touches your flooded area for best results!
              </Typography>
            </Box>
          </Fade>
        )}

        {/* Tutorial Tooltip - Step 4 (Completion) */}
        {tutorialActive && tutorialStep === 4 && showTutorialTooltip && (
          <Fade in={true}>
            <Box
              component={motion.div}
              animate={{
                boxShadow: [
                  "0 4px 20px rgba(0,0,0,0.2)",
                  "0 8px 25px rgba(0,0,0,0.3)",
                  "0 4px 20px rgba(0,0,0,0.2)",
                ],
              }}
              transition={{
                repeat: Infinity,
                duration: 2.5,
              }}
              sx={{
                position: "absolute",
                top: "45%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                zIndex: 100,
                bgcolor: "white",
                p: 2,
                borderRadius: 2,
                maxWidth: "85%",
                textAlign: "center",
                boxShadow: "0 4px 20px rgba(0,0,0,0.3)",
              }}
            >
              <Typography sx={{ fontSize: "1.1rem", fontWeight: 700, mb: 1 }}>
                Great job!
              </Typography>
              <Typography sx={{ fontSize: "0.9rem", mb: 2 }}>
                Try again and flood the board in fewer moves! The ideal solution
                takes around 17-21 moves.
              </Typography>
              <Button
                onClick={skipTutorial}
                variant="contained"
                sx={{
                  bgcolor: "#b8c26c",
                  color: "#000",
                  fontWeight: 600,
                }}
              >
                Continue Playing
              </Button>
            </Box>
          </Fade>
        )}

        {/* Game Title */}
        <Typography
          variant="h1"
          component="h1"
          sx={{
            fontSize: { xs: "2rem", sm: "3rem" },
            fontWeight: 900,
            color: "#000",
            mb: { xs: 0.5, sm: 1 },
            lineHeight: 1.2,
          }}
        >
          Color Flood
        </Typography>

        {/* Game Instructions */}
        <Typography
          variant="body1"
          sx={{
            mb: { xs: 1, sm: 3 },
            color: "rgba(0,0,0,0.8)",
            fontSize: { xs: "0.9rem", sm: "1.1rem" },
            maxWidth: "90%",
            lineHeight: 1.3,
          }}
        >
          Flood the board with one color starting from the top-left corner.
        </Typography>

        {/* Game Controls - Compact Layout */}
        <Box
          sx={{
            display: "flex",
            width: "100%",
            justifyContent: "space-between",
            mb: { xs: 1, sm: 2 },
            gap: 1,
            alignItems: "center",
          }}
        >
          {/* Date Selector - More Compact */}
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              bgcolor: "white",
              borderRadius: { xs: 2, sm: 4 },
              border: "1px solid rgba(0,0,0,0.12)",
              overflow: "hidden",
              maxWidth: { xs: "55%", sm: "40%" },
              flexGrow: 0,
            }}
          >
            <IconButton
              onClick={() => changeDate(-1)}
              sx={{
                color: "#555",
                height: { xs: 35, sm: 40 },
                width: { xs: 35, sm: 40 },
                borderRadius: 0,
                p: { xs: 0.5, sm: 1 },
              }}
            >
              <ChevronLeftIcon fontSize="small" />
            </IconButton>

            <Typography
              sx={{
                fontWeight: 700,
                color: "#333",
                textAlign: "center",
                px: { xs: 1, sm: 2 },
                fontSize: { xs: "0.8rem", sm: "1rem" },
              }}
            >
              {formattedDate}
            </Typography>

            {currentDate < today && (
              <IconButton
                onClick={() => changeDate(1)}
                sx={{
                  color: "#555",
                  height: { xs: 35, sm: 40 },
                  width: { xs: 35, sm: 40 },
                  borderRadius: 0,
                  p: { xs: 0.5, sm: 1 },
                }}
              >
                <ChevronRightIcon fontSize="small" />
              </IconButton>
            )}
          </Box>

          {/* Game Action Icons Row - Moved inline with date picker */}
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              gap: { xs: 1, sm: 1.5 },
              flexShrink: 0,
            }}
          >
            {/* Undo Button */}
            <IconButton
              disabled={
                undosLeft <= 0 || previousGrids.length === 0 || gameOver
              }
              onClick={undoMove}
              sx={{
                bgcolor: "white",
                color: undosLeft > 0 ? "#555" : "#ccc",
                border: "1px solid rgba(0,0,0,0.12)",
                borderRadius: "50%",
                p: { xs: 0.75, sm: 1 },
                width: { xs: 32, sm: 40 },
                height: { xs: 32, sm: 40 },
                "&:hover": {
                  bgcolor: "rgba(0,0,0,0.03)",
                },
                "&:disabled": {
                  opacity: 0.5,
                  bgcolor: "white",
                },
              }}
              aria-label="Undo move"
            >
              <UndoIcon fontSize="small" />
            </IconButton>

            {/* Reset Button */}
            <IconButton
              onClick={resetPuzzle}
              disabled={gameOver}
              sx={{
                bgcolor: "white",
                color: "#555",
                border: "1px solid rgba(0,0,0,0.12)",
                borderRadius: "50%",
                p: { xs: 0.75, sm: 1 },
                width: { xs: 32, sm: 40 },
                height: { xs: 32, sm: 40 },
                "&:hover": {
                  bgcolor: "rgba(0,0,0,0.03)",
                },
                "&:disabled": {
                  opacity: 0.5,
                  bgcolor: "white",
                },
              }}
              aria-label="Reset puzzle"
            >
              <RestartAltIcon fontSize="small" />
            </IconButton>

            {/* Help Button */}
            <IconButton
              onClick={() => setShowHint(!showHint)}
              sx={{
                bgcolor: "white",
                color: "#555",
                border: "1px solid rgba(0,0,0,0.12)",
                borderRadius: "50%",
                p: { xs: 0.75, sm: 1 },
                width: { xs: 32, sm: 40 },
                height: { xs: 32, sm: 40 },
                "&:hover": {
                  bgcolor: "rgba(0,0,0,0.03)",
                },
              }}
              aria-label="How to play"
            >
              <HelpOutlineIcon fontSize="small" />
            </IconButton>
          </Box>
        </Box>

        {/* Game Board Stats Bar - Move Count with Progress Bar */}
        <Box
          sx={{
            display: "flex",
            width: "100%",
            justifyContent: "space-between",
            alignItems: "center",
            mb: { xs: 1, sm: 1.5 },
            backgroundColor: "white",
            borderRadius: 2,
            border: "1px solid rgba(0,0,0,0.12)",
            p: 1.5,
            position: "relative",
            overflow: "hidden",
          }}
        >
          {/* Move Counter - Repositioned near the board */}
          <Typography
            sx={{
              fontWeight: 700,
              color: "#333",
              fontSize: { xs: "0.9rem", sm: "1.1rem" },
              zIndex: 2,
            }}
          >
            Moves: {moves}
          </Typography>

          {/* Progress Indicator */}
          <Box
            sx={{ display: "flex", alignItems: "center", gap: 1, zIndex: 2 }}
          >
            <Typography
              sx={{
                fontWeight: 600,
                color: "#333",
                fontSize: { xs: "0.9rem", sm: "1.1rem" },
              }}
            >
              {floodedPercentage}% Flooded
            </Typography>
          </Box>

          {/* Progress Bar Background */}
          <Box
            sx={{
              position: "absolute",
              left: 0,
              top: 0,
              height: "100%",
              width: `${floodedPercentage}%`,
              backgroundColor: getColorById(currentColor)?.color || "#b8c26c",
              opacity: 0.2,
              transition: "width 0.5s ease-out",
            }}
          />
        </Box>

        {/* Current Color Display - More Prominent */}
        {!gameOver && currentColor && (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              gap: { xs: 1, sm: 1.5 },
              mb: { xs: 1, sm: 2 },
              width: "100%",
            }}
          >
            <Typography
              sx={{
                fontWeight: 600,
                color: "#333",
                fontSize: { xs: "0.9rem", sm: "1rem" },
              }}
            >
              Current Color:
            </Typography>

            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                gap: 1,
                py: { xs: 0.5, sm: 0.75 },
                px: { xs: 1.5, sm: 2 },
                borderRadius: { xs: 3, sm: 4 },
                backgroundColor:
                  getColorById(currentColor)?.color || "transparent",
                boxShadow: `0 0 0 2px ${
                  getColorById(currentColor)?.borderColor || "#333"
                }`,
                minWidth: 80,
              }}
            >
              <Typography
                sx={{
                  fontWeight: 600,
                  color: getTextColor(
                    getColorById(currentColor)?.color || "#fff"
                  ),
                  fontSize: { xs: "0.9rem", sm: "1rem" },
                  textTransform: "capitalize",
                }}
              >
                {currentColor}
              </Typography>
            </Box>
          </Box>
        )}

        {/* Game Board - Optimized for mobile with tutorial highlight for first tile */}
        <Box
          sx={{
            width: "100%",
            aspectRatio: "1/1",
            borderRadius: 2,
            bgcolor: "white",
            border: "1px solid rgba(0,0,0,0.12)",
            mb: { xs: 1, sm: 2 },
            boxSizing: "border-box",
            position: "relative", // For tutorial highlighting
          }}
        >
          {/* Tutorial overlay for first tile - Positioned relative to the game board */}
          {tutorialActive && tutorialStep === 1 && (
            <>
              {/* Overlay highlight for the first cell */}
              <Box
                component={motion.div}
                animate={{
                  boxShadow: [
                    "0 0 0 4px rgba(244, 180, 0, 0.5)",
                    "0 0 0 8px rgba(244, 180, 0, 0.8)",
                    "0 0 0 4px rgba(244, 180, 0, 0.5)",
                  ],
                }}
                transition={{
                  repeat: Infinity,
                  duration: 2,
                }}
                sx={{
                  position: "absolute",
                  top: "1.5%",
                  left: "1.5%",
                  width: "8%",
                  height: "8%",
                  borderRadius: "2px",
                  zIndex: 5,
                  pointerEvents: "none",
                }}
              />

              {/* Play icon indicator */}
              <Box
                component={motion.div}
                animate={{
                  scale: [1, 1.1, 1],
                  opacity: [0.9, 1, 0.9],
                }}
                transition={{
                  repeat: Infinity,
                  duration: 1.5,
                }}
                sx={{
                  position: "absolute",
                  top: "0.5%",
                  left: "0.5%",
                  zIndex: 6,
                  width: "30px",
                  height: "30px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  borderRadius: "50%",
                  backgroundColor: "rgba(255,255,255,0.9)",
                  boxShadow: "0 0 5px rgba(0,0,0,0.3)",
                }}
              >
                <StartIcon sx={{ color: "#f4b400", fontSize: 24 }} />
              </Box>

              {/* Tooltip positioned directly at the top-left cell */}
              {showTutorialTooltip && (
                <Fade in={true}>
                  <Box
                    component={motion.div}
                    animate={{
                      y: [0, -3, 0],
                    }}
                    transition={{
                      repeat: Infinity,
                      duration: 2,
                    }}
                    sx={{
                      position: "absolute",
                      top: "12%",
                      left: "2%",
                      zIndex: 10,
                      bgcolor: "white",
                      p: 1.5,
                      borderRadius: 2,
                      maxWidth: { xs: "90%", sm: "70%" },
                      boxShadow: "0 2px 10px rgba(0,0,0,0.2)",
                      "&:after": {
                        content: '""',
                        position: "absolute",
                        bottom: "100%", // Position at the top of the tooltip box
                        left: "15px",
                        borderWidth: "8px",
                        borderStyle: "solid",
                        borderColor:
                          "transparent transparent white transparent",
                      },
                    }}
                  >
                    <Typography sx={{ fontSize: "0.9rem", fontWeight: 600 }}>
                      Start here! Select a color below to begin flooding.
                    </Typography>
                  </Box>
                </Fade>
              )}
            </>
          )}

          <Grid
            container
            spacing={0.25}
            sx={{
              p: 0.25,
              height: "100%",
              m: 0,
              width: "100%",
            }}
          >
            {grid.map((row, i) =>
              row.map((cell, j) => {
                const colorInfo = getColorById(cell);
                // Determine if this is the starting tile for tutorial
                const isStartingTile = i === 0 && j === 0;
                return (
                  <Grid
                    item
                    xs={1.2}
                    key={`${i}-${j}`}
                    sx={{
                      p: "1px",
                      boxSizing: "border-box",
                    }}
                    ref={isStartingTile ? startingTileRef : null}
                  >
                    <Box
                      component={motion.div}
                      animate={{
                        scale:
                          isAnimating && cell === currentColor
                            ? [1, 1.05, 1]
                            : 1,
                        transition: { duration: 0.3 },
                      }}
                      sx={{
                        width: "100%",
                        paddingBottom: "100%",
                        backgroundColor: colorInfo ? colorInfo.color : "#fff",
                        border:
                          tutorialActive && tutorialStep === 1 && isStartingTile
                            ? "2px solid rgba(244, 180, 0, 1)"
                            : colorInfo
                            ? `1px solid ${colorInfo.borderColor}`
                            : "1px solid #ccc",
                        transition: "all 0.3s",
                        position: "relative",
                        zIndex: isStartingTile && tutorialActive ? 2 : 1,
                      }}
                    />
                  </Grid>
                );
              })
            )}
          </Grid>
        </Box>

        {/* Color Selection with Tutorial Reference */}
        <Typography
          variant="h6"
          sx={{
            fontWeight: 700,
            mb: { xs: 0.5, sm: 1 },
            mt: { xs: 0.5, sm: 1 },
            color: "#333",
            fontSize: { xs: "0.9rem", sm: "1rem" },
          }}
        >
          Select a Color:
        </Typography>

        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            gap: { xs: 2, sm: 3 },
            flexWrap: "wrap",
            maxWidth: "400px",
            mb: { xs: 1, sm: 3 },
            pt: 0.5,
            position: "relative", // For tutorial highlighting
          }}
        >
          {COLORS.map((colorInfo, index) => (
            <Button
              key={colorInfo.id}
              onClick={() => floodFill(colorInfo.id)}
              disabled={
                gameOver || colorInfo.id === currentColor || isAnimating
              }
              ref={(el) => (colorButtonsRef.current[index] = el)}
              component={motion.div}
              whileHover={{ scale: 1.05 }}
              animate={
                tutorialActive && tutorialStep === 1
                  ? {
                      scale: [1, 1.07, 1],
                      boxShadow: [
                        "0 1px 3px rgba(0,0,0,0.15)",
                        "0 3px 8px rgba(0,0,0,0.25)",
                        "0 1px 3px rgba(0,0,0,0.15)",
                      ],
                    }
                  : {}
              }
              transition={{
                repeat: tutorialActive && tutorialStep === 1 ? Infinity : 0,
                duration: 1.5,
              }}
              sx={{
                width: { xs: 50, sm: 65 },
                height: { xs: 50, sm: 65 },
                minWidth: { xs: 50, sm: 60 },
                p: 0,
                borderRadius: "50%",
                backgroundColor: colorInfo.color,
                border:
                  colorInfo.id === currentColor
                    ? "3px solid white"
                    : `2px solid ${colorInfo.borderColor}`,
                boxShadow:
                  colorInfo.id === currentColor
                    ? `0 0 8px 2px ${colorInfo.color}`
                    : "0 1px 3px rgba(0,0,0,0.15)",
                transition: "all 0.2s ease",
                "&:hover": {
                  backgroundColor: colorInfo.color,
                  opacity: 0.9,
                  transform: "scale(1.05)",
                  boxShadow: `0 0 5px 1px ${colorInfo.color}`,
                },
                "&:disabled": {
                  backgroundColor: colorInfo.color,
                  opacity: colorInfo.id === currentColor ? 1 : 0.6,
                },
              }}
              aria-label={`Select ${colorInfo.id} color`}
            />
          ))}
        </Box>

        {/* Game Complete Message - More compact for mobile */}
        {gameOver && (
          <Box
            sx={{
              textAlign: "center",
              mb: { xs: 1, sm: 3 },
              mt: { xs: 0.5, sm: 1 },
              p: { xs: 2, sm: 3 },
              bgcolor: "white",
              borderRadius: 2,
              border: "1px solid rgba(0,0,0,0.12)",
              width: "100%",
              maxWidth: "400px",
            }}
          >
            <Typography
              variant="h5"
              sx={{
                fontWeight: 800,
                color: "#b8c26c", // Using exact olive green from brand colors
                mb: { xs: 1, sm: 2 },
                fontSize: { xs: "1.2rem", sm: "1.5rem" },
              }}
            >
              Puzzle Completed!
            </Typography>

            <Typography
              variant="body1"
              sx={{
                color: "#333",
                mb: { xs: 1.5, sm: 3 },
                fontSize: { xs: "0.9rem", sm: "1rem" },
              }}
            >
              You solved it in {moves} moves.
            </Typography>

            <Button
              variant="contained"
              startIcon={<ShareIcon />}
              onClick={handleShare}
              sx={{
                bgcolor: "#b8c26c", // Using exact olive green from brand colors
                color: "#000", // Black text for better contrast
                fontWeight: 700,
                px: { xs: 2, sm: 3 },
                py: { xs: 1, sm: 1.5 },
                borderRadius: 2,
                textTransform: "none",
                fontSize: { xs: "0.9rem", sm: "1rem" },
                "&:hover": {
                  bgcolor: "#a6b05e", // Slightly darker green
                },
              }}
            >
              Share Score
            </Button>
          </Box>
        )}

        {/* How to Play Dialog */}
        {showHint && renderHowToPlayModal()}

        {/* Floating Help Button (always visible when not in tutorial) */}
        {!tutorialActive && !showHint && (
          <IconButton
            onClick={() => setShowHint(true)}
            component={motion.div}
            whileHover={{ scale: 1.1 }}
            whileTap={{ scale: 0.95 }}
            animate={{
              boxShadow: [
                "0 3px 10px rgba(0, 0, 0, 0.2)",
                "0 6px 15px rgba(0, 0, 0, 0.3)",
                "0 3px 10px rgba(0, 0, 0, 0.2)",
              ],
            }}
            transition={{
              boxShadow: {
                repeat: Infinity,
                duration: 2,
              },
            }}
            sx={{
              position: "fixed",
              bottom: "20px",
              right: "20px",
              bgcolor: "white",
              width: { xs: 45, sm: 55 },
              height: { xs: 45, sm: 55 },
              zIndex: 5,
              "&:hover": {
                bgcolor: "white",
                opacity: 0.9,
              },
            }}
            aria-label="Help"
          >
            <HelpOutlineIcon
              sx={{ color: ACCENT_COLOR, fontSize: { xs: 24, sm: 28 } }}
            />
          </IconButton>
        )}

        <Snackbar
          open={showSnackbar}
          autoHideDuration={3000}
          onClose={() => setShowSnackbar(false)}
          message={snackbarMessage}
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          sx={{
            "& .MuiSnackbarContent-root": {
              bgcolor: "#b8c26c", // Using exact olive green from brand colors
              color: "#000", // Black text for better contrast
              fontWeight: 500,
            },
          }}
        />
      </Box>
    </Box>
  );
};

export default ColorFlood;
