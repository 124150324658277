import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import FeaturedMobileGame from "./FeaturedMobileGame";
import GameButton from "./GameButton";
import CollapsibleSEOContent from "./CollapsibleSEOContent";
import GamesBody from "./GamesBody";
import { Helmet } from "react-helmet";
import { Assessment } from "@mui/icons-material";
import AsSeenInSection from "./AsSeenIn";
import WhyGames from "./WhyGames";
import CTAButtons from "./CTAButtons";
import Testimonials from "./Testimonials";
import WorkWithUs from "./WorkWithUs";
import AboutUs from "./About";
import Footer from "./Footer";
import GameTileBaseSmall from "./GameTileBaseSmall";
import Grid from "@mui/material/Grid2";
import BaseWordyGame from "../games/WordyVerse/Components/BaseWordyGame";
import ColorFlood from "../games/ColorFlood";
import {
  getWordForDate,
  findLatestAvailableDate,
} from "../games/WordyVerse/Topics/FlyingCometGames/Data";
import BaseKeyword from "../games/Keyword/BaseKeyword";
import Fade from "@mui/material/Fade";
import GameLoader from "./GameLoader";

// Embedded Game components that can be loaded
const GameComponents = {
  "wordy-verse": () => (
    <Box p={2}>
      <BaseWordyGame
        title="The Daily Comet Wordy"
        bannerPath="/assets/customer-logos/banner/daily-commet.svg"
        shareText="The Daily Comet Wordy"
        shareUrl="https://flyingcometgames.com"
        isEmbedded={true}
        getWordForDate={getWordForDate}
        findLatestAvailableDate={findLatestAvailableDate}
      />
    </Box>
  ),
  "color-flood": () => (
    <Box>
      <ColorFlood />
    </Box>
  ),
  "keyword-game": () => (
    <Box>
      <BaseKeyword
        title="Flying Comet Games"
        subtitle="Slide each word horizontally until the center letters line up to reveal the keyword."
        wordList={["SLACK", "WORDS", "GAMES", "GEEKS", "TECH"]}
        isEmbedded={true}
        buttonColor="black"
        modalBackgroundColor="#B8C26C"
        answer="COMET"
        shareText="I played Keyword!"
        shareUrl="https://flyingcometgames.com"
        publicationName="Geekwire"
        articleTitle="Tech vets launch their own game company and create 'Wordle'-style puzzle with a Seattle focus"
        articleUrl="https://www.geekwire.com/2024/tech-vets-launch-their-own-game-company-and-create-wordle-style-puzzle-with-a-seattle-focus/"
      />
    </Box>
  ),
  "weather-wiz": () => (
    <Box
      sx={{
        p: 4,
        textAlign: "center",
        border: "1px solid #ccc",
        borderRadius: "8px",
        bgcolor: "#f5f5f5",
      }}
    >
      <Typography variant="h4">Weather Wiz Game</Typography>
      <Typography>Game would be embedded here</Typography>
    </Box>
  ),
};

const Home = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [selectedGame, setSelectedGame] = useState("wordy-verse");
  const [showGame, setShowGame] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [fadeIn, setFadeIn] = useState(true);

  // Handle game switching with animation
  const handleSelectGame = (link, title) => {
    const gameId = link.split("/")[1];

    if (gameId === selectedGame) return; // Don't reload if it's the same game

    // Start transition animation
    setFadeIn(false);

    // Quick transition
    setTimeout(() => {
      setIsLoading(true);

      // After fade out, change the game immediately
      setTimeout(() => {
        setSelectedGame(gameId);

        // Minimal delay for component initialization
        setTimeout(() => {
          setIsLoading(false);
          // Fade in immediately
          setFadeIn(true);
        }, 150); // Just enough time to render the new component
      }, 50);
    }, 50); // Minimal initial delay
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        overflowX: "hidden",
        backgroundColor: theme.palette.background.default,
      }}
    >
      <Helmet>
        <title>
          Flying Comet Games | Daily games for digital publications.
        </title>
        <meta
          name="description"
          content="Discover daily games for digital publications. Engage your community with Flying Comet Games."
        />
      </Helmet>

      {/* Hero Section */}
      <Box borderBottom={1} borderColor="black" backgroundColor="white">
        <Box
          sx={{
            display: "flex",
            flexDirection: { xs: "column", md: "row" },
            alignItems: { xs: "center", md: "flex-start" },
            justifyContent: { md: "space-between" },
            width: "100%",
            maxWidth: "1200px",
            mx: "auto",
            px: { xs: 2, md: 4 },
            pt: { xs: 5, md: 9 },
            pb: { xs: 4, md: 6 },
            boxSizing: "border-box",
            my: { md: 4 },
          }}
        >
          {/* Left Content - Text and CTA */}
          <Box
            sx={{
              maxWidth: { xs: "450px", md: "500px" },
              textAlign: "left",
              mb: { xs: 4, md: 0 },
              width: { xs: "100%", md: "45%" },
              mx: { xs: "auto", md: "0" },
            }}
          >
            <Typography
              variant="h1"
              component="h1"
              gutterBottom
              sx={{
                fontSize: { xs: "2.25rem", sm: "2.75rem", md: "3rem" },
                fontWeight: 800,
                mb: 2,
                lineHeight: 1.2,
                textAlign: "left",
              }}
            >
              Daily games for <br />
              <span style={{ textDecoration: "underline" }}>digital</span>{" "}
              publications.
            </Typography>

            <Typography
              variant="h2"
              component="h2"
              gutterBottom
              sx={{
                fontSize: { xs: "1.35rem", sm: "1.4rem", md: "1.5rem" },
                pb: 3,
                fontWeight: 400,
                lineHeight: 1.4,
                textAlign: "left",
              }}
            >
              Increase traffic and keep readers coming back every day with games
              your community loves.
            </Typography>

            {/* Button Container for proper centering */}
            <Box
              sx={{
                display: "flex",
                justifyContent: { xs: "center", md: "flex-start" },
                width: "100%",
              }}
            >
              {/* Single Responsive CTA Button */}
              <Button
                variant="contained"
                href="https://cal.com/calli-fuchigami/15min"
                target="_blank"
                rel="noopener noreferrer"
                sx={{
                  fontWeight: 600,
                  textTransform: "none",
                  borderRadius: 20,
                  boxShadow: "0 2px 6px rgba(0,0,0,0.15)",
                  backgroundColor: "#000000",
                  color: "white",
                  px: 5,
                  py: 1.5,
                  mb: { xs: 0, md: 2 },
                  fontSize: "1.1rem",
                  width: { xs: "100%", md: "auto" },
                  minWidth: { md: "200px" },
                  textAlign: "center",
                  justifyContent: "center",
                  alignItems: "center",
                  "&:hover": {
                    transform: "translateY(-2px)",
                    boxShadow: "0 4px 8px rgba(0,0,0,0.2)",
                    backgroundColor: "#333333",
                    color: "white",
                  },
                }}
              >
                Book a demo
              </Button>
            </Box>
          </Box>

          {/* Right Content - Game Preview */}
          <Box
            sx={{
              width: { xs: "100%", md: "50%" },
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              position: "relative",
              mt: { xs: 4, md: 0 }, // Add margin top on mobile for spacing after button
            }}
          >
            {/* Game Preview Display with stacked effect */}
            <Box
              component="img"
              src={`${process.env.PUBLIC_URL}/assets/game-preview.png`}
              alt="The Seattle Wordy Game Preview"
              sx={{
                width: "100%",
                maxWidth: "400px",
                height: "auto",
                position: "relative",
                zIndex: 1,
              }}
            />
          </Box>
        </Box>
      </Box>

      {/* As Seen In Section */}
      <Box borderBottom={1} borderColor="black" backgroundColor="white">
        <Box
          sx={{
            width: "100%",
            maxWidth: "1200px",
            mx: "auto",
            // mb: { xs: 6, md: 8 },
            // px: { xs: 2, md: 4 },
          }}
        >
          <AsSeenInSection />
        </Box>
      </Box>

      {/* Our games section */}
      <Box my={6}>
        <Box maxWidth="1200px" mx="auto" px={2}>
          {/* Section Title */}
          <Typography
            variant="h2"
            component="h2"
            sx={{
              fontWeight: 800,
              fontSize: { xs: "2rem", sm: "2.5rem", md: "3rem" },
              textAlign: "center",
              mb: 3,
            }}
          >
            Our games
          </Typography>
          <Grid container spacing={2}>
            <Grid size={{ xs: 6, md: 3 }}>
              <GameTileBaseSmall
                title="The Wordy"
                svgLogo={`${process.env.PUBLIC_URL}/assets/game-tiles/wordyverse2.svg`}
                bgColor="#ffd583"
                link="/wordy-verse/seattle"
                isSelected={selectedGame === "wordy-verse"}
                onSelect={handleSelectGame}
              />
            </Grid>
            <Grid size={{ xs: 6, md: 3 }}>
              <GameTileBaseSmall
                title="Keyword"
                svgLogo={`${process.env.PUBLIC_URL}/assets/game-tiles/keyword.svg`}
                bgColor="#B8C26C"
                link="/keyword-game"
                isSelected={selectedGame === "keyword-game"}
                onSelect={handleSelectGame}
              />
            </Grid>
            <Grid size={{ xs: 6, md: 3 }}>
              <GameTileBaseSmall
                title="Color Flood"
                svgLogo={`${process.env.PUBLIC_URL}/assets/game-tiles/color-flood.svg`}
                link="/color-flood"
                bgColor="#91B2D1"
                isSelected={selectedGame === "color-flood"}
                onSelect={handleSelectGame}
              />
            </Grid>
            <Grid size={{ xs: 6, md: 3 }}>
              <GameTileBaseSmall
                title="Weather Wiz"
                isLocked={true}
                svgLogo={`${process.env.PUBLIC_URL}/assets/game-tiles/weather-wiz.svg`}
                bgColor="#91B2D1"
                link="/weather-wiz"
                isSelected={selectedGame === "weather-wiz"}
                onSelect={handleSelectGame}
              />
            </Grid>
          </Grid>

          {/* Embedded Game Section with Fade Transition */}
          {showGame && (
            <Box
              mt={6}
              mx="auto"
              mb={3}
              sx={{
                border: "1px solid black",
                borderRadius: "2px",
                boxShadow: "0px 4px 12px rgba(0,0,0,0.1)",
                height: "fit-content",
                minHeight: "500px", // Provide a minimum height to prevent layout shifts
                position: "relative",
                overflow: "hidden", // Prevent content from overflowing during animations
              }}
            >
              {isLoading ? (
                <GameLoader height={500} />
              ) : (
                <Fade in={fadeIn} timeout={400}>
                  <Box>
                    {GameComponents[selectedGame] &&
                      GameComponents[selectedGame]()}
                  </Box>
                </Fade>
              )}
            </Box>
          )}
        </Box>
      </Box>

      {/* Why Games Section */}
      <Box backgroundColor="white">
        <Box
          sx={{
            width: "100%",
            maxWidth: "1200px",
            mx: "auto",
            my: { xs: 6, md: 8 },
            px: { xs: 2, md: 4 },
          }}
        >
          <WhyGames />
        </Box>
      </Box>

      {/* Work With Us */}
      <Box
        sx={{
          width: "100%",
          maxWidth: "1200px",
          mx: "auto",
          mb: { xs: 4, md: 4 },
          px: { xs: 2, md: 4 },
        }}
      >
        <WorkWithUs />
      </Box>

      {/* About Us */}
      <Box
        sx={{
          width: "100%",
          maxWidth: "1200px",
          mx: "auto",
          px: { xs: 2, md: 4 },
        }}
      >
        <AboutUs isComponent={true} />
      </Box>

      <Footer />
    </Box>
  );
};

export default Home;
