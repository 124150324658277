// Weather emojis to use
const WEATHER_SYMBOLS = ["☀️", "☁️", "🌧️", "❄️", "⚡️"];
const EMPTY_CELL = "";
const GRID_SIZE = 5;

/**
 * Check if it's valid to place a symbol at the given position
 * @param {Array} grid - The current grid
 * @param {number} row - Row index
 * @param {number} col - Column index
 * @param {string} symbol - Weather symbol to check
 * @returns {boolean} Whether the placement is valid
 */
const isValidPlacement = (grid, row, col, symbol) => {
  // Check row for duplicates
  for (let j = 0; j < GRID_SIZE; j++) {
    if (j !== col && grid[row][j] === symbol) {
      return false;
    }
  }

  // Check column for duplicates
  for (let i = 0; i < GRID_SIZE; i++) {
    if (i !== row && grid[i][col] === symbol) {
      return false;
    }
  }

  return true;
};

/**
 * Find the next empty cell in the grid
 * @param {Array} grid - The current grid
 * @param {number} startRow - Starting row index
 * @param {number} startCol - Starting column index
 * @returns {Array|null} [row, col] of next empty cell, or null if none found
 */
const findNextEmpty = (grid, startRow, startCol) => {
  for (let row = startRow; row < GRID_SIZE; row++) {
    for (let col = row === startRow ? startCol : 0; col < GRID_SIZE; col++) {
      if (grid[row][col] === EMPTY_CELL) {
        return [row, col];
      }
    }
  }
  return null;
};

/**
 * Solve the puzzle using backtracking
 * @param {Array} grid - The current grid
 * @param {number} row - Current row index
 * @param {number} col - Current column index
 * @returns {boolean} Whether a solution was found
 */
export const backtrackingSolver = (grid, row = 0, col = 0) => {
  // Find next empty cell
  const next = findNextEmpty(grid, row, col);
  if (!next) {
    // No empty cells left, check if solution is valid
    return isValidSolution(grid);
  }

  const [nextRow, nextCol] = next;

  // Try each weather symbol in a random order to avoid getting stuck in patterns
  const shuffledSymbols = [...WEATHER_SYMBOLS].sort(() => Math.random() - 0.5);
  for (const symbol of shuffledSymbols) {
    if (isValidPlacement(grid, nextRow, nextCol, symbol)) {
      grid[nextRow][nextCol] = symbol;

      if (backtrackingSolver(grid, nextRow, nextCol)) {
        return true;
      }

      // If no solution found, backtrack
      grid[nextRow][nextCol] = EMPTY_CELL;
    }
  }

  return false;
};

/**
 * Check if the current grid is a valid solution
 * @param {Array} grid - The completed grid
 * @returns {boolean} Whether the solution is valid
 */
export const isValidSolution = (grid) => {
  // Count symbols in each row and column
  for (let i = 0; i < GRID_SIZE; i++) {
    const rowCounts = {};
    const colCounts = {};
    WEATHER_SYMBOLS.forEach((symbol) => {
      rowCounts[symbol] = 0;
      colCounts[symbol] = 0;
    });

    for (let j = 0; j < GRID_SIZE; j++) {
      const rowSymbol = grid[i][j];
      const colSymbol = grid[j][i];

      if (WEATHER_SYMBOLS.includes(rowSymbol)) {
        rowCounts[rowSymbol]++;
        if (rowCounts[rowSymbol] > 1) return false;
      }

      if (WEATHER_SYMBOLS.includes(colSymbol)) {
        colCounts[colSymbol]++;
        if (colCounts[colSymbol] > 1) return false;
      }
    }

    // Check if each row and column has all symbols
    for (const symbol of WEATHER_SYMBOLS) {
      if (rowCounts[symbol] !== 1 || colCounts[symbol] !== 1) {
        return false;
      }
    }
  }

  return true;
};

export { WEATHER_SYMBOLS, EMPTY_CELL, GRID_SIZE };
