import React, { useState } from "react";
import {
  Box,
  Typography,
  Container,
  Link as MuiLink,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { Link } from "react-router-dom";
import Footer from "./Footer";
import EmailIcon from "@mui/icons-material/Email";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import LanguageIcon from "@mui/icons-material/Language";

const AboutUs = ({ isComponent = false }) => {
  const [calliHover, setCalliHover] = useState(false);
  const [edenHover, setEdenHover] = useState(false);
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("sm"));

  return (
    <Container
      maxWidth="400px"
      sx={{
        textAlign: "center",
        px: 2,
        ...(!isComponent && {
          p: 4,
          backgroundColor: "background.default",
          minHeight: "100vh",
          backgroundImage: {
            xs: `url(${process.env.PUBLIC_URL}/assets/background.svg)`, // For small screens
            md: `url(${process.env.PUBLIC_URL}/assets/background-desktop.svg)`, // For medium and larger screens
          },
          backgroundRepeat: "no-repeat repeat",
          backgroundSize: "auto",
          backgroundPosition: "center 40px",
        }),
        ...(isComponent && {
          maxWidth: "400px",
        }),
      }}
    >
      <Box
        sx={{
          maxWidth: "sm",
          mx: "auto",
          pb: 4,
          position: "relative",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: 4,
        }}
      >
        {/* About Us heading with star */}
        <Box
          sx={{
            display: "flex",
            textAlign: "center",
            alignItems: "center",
            justifyContent: "center",
            gap: 1,
            width: "100%",
            mt: 3,
          }}
        >
          <Box
            component="img"
            src={`${process.env.PUBLIC_URL}/assets/icons/star-bullet.svg`}
            alt="Star Bullet"
            sx={{ width: "36px", height: "36px" }}
          />
          {/* Section Title */}
          <Typography
            variant="h2"
            component="h2"
            sx={{
              fontWeight: 800,
              fontSize: { xs: "2rem", sm: "2.5rem", md: "3rem" },
              textAlign: "center",
            }}
          >
            About us
          </Typography>
        </Box>

        {/* Co-founder profiles section */}
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
            maxWidth: "450px",
            gap: { xs: 2, sm: 4 },
            mt: 2,
          }}
        >
          {/* Calli's Profile */}
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              flex: 1,
            }}
          >
            <Box
              component="img"
              src={`${process.env.PUBLIC_URL}/assets/avatars/${
                calliHover && isDesktop ? "calli-smile.jpg" : "calli.jpg"
              }`}
              alt="Calli's avatar"
              onMouseEnter={() => setCalliHover(true)}
              onMouseLeave={() => setCalliHover(false)}
              sx={{
                width: { xs: 120, sm: 150 },
                height: { xs: 120, sm: 150 },
                borderRadius: "50%",
                mb: 2,
                transition: "all 0.3s ease",
                cursor: "pointer",
                ...(isDesktop && {
                  "&:hover": {
                    transform: "scale(1.05)",
                  },
                }),
              }}
            />
            <Typography
              variant="h6"
              sx={{
                fontWeight: "bold",
                fontSize: { xs: "1.1rem", sm: "1.25rem" },
              }}
            >
              Calli Fuchigami
            </Typography>
            <Typography variant="body1" sx={{ mb: 1 }}>
              Co-founder
            </Typography>
            <Box sx={{ display: "flex", gap: 1 }}>
              <MuiLink
                href="mailto:calli@flyingcometgames.com"
                sx={{ color: "inherit" }}
              >
                <EmailIcon sx={{ fontSize: 20 }} />
              </MuiLink>
              <MuiLink
                href="https://bsky.app/profile/flyingcometgames.com"
                sx={{ color: "inherit" }}
              >
                <Box
                  component="img"
                  src={`${process.env.PUBLIC_URL}/assets/icons/bsky.svg`}
                  alt="BlueSky"
                  sx={{ width: 20, height: 20 }}
                />
              </MuiLink>
              <MuiLink
                href="https://www.linkedin.com/in/calli-fuchigami-582a9456/"
                sx={{ color: "inherit" }}
              >
                <LinkedInIcon sx={{ fontSize: 20 }} />
              </MuiLink>
            </Box>
          </Box>

          {/* Eden's Profile */}
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              flex: 1,
            }}
          >
            <Box
              component="img"
              src={`${process.env.PUBLIC_URL}/assets/avatars/${
                edenHover && isDesktop ? "eden-smile.jpg" : "eden.jpg"
              }`}
              alt="Eden's avatar"
              onMouseEnter={() => setEdenHover(true)}
              onMouseLeave={() => setEdenHover(false)}
              sx={{
                width: { xs: 120, sm: 150 },
                height: { xs: 120, sm: 150 },
                borderRadius: "50%",
                mb: 2,
                transition: "all 0.3s ease",
                cursor: "pointer",
                ...(isDesktop && {
                  "&:hover": {
                    transform: "scale(1.05)",
                  },
                }),
              }}
            />
            <Typography
              variant="h6"
              sx={{
                fontWeight: "bold",
                fontSize: { xs: "1.1rem", sm: "1.25rem" },
              }}
            >
              Eden Ghirmai
            </Typography>
            <Typography variant="body1" sx={{ mb: 1 }}>
              Co-founder
            </Typography>
            <Box sx={{ display: "flex", gap: 1 }}>
              <MuiLink
                href="mailto:eden@flyingcometgames.com"
                sx={{ color: "inherit" }}
              >
                <EmailIcon sx={{ fontSize: 20 }} />
              </MuiLink>
              <MuiLink
                href="https://bsky.app/profile/3den.dev"
                sx={{ color: "inherit" }}
              >
                <Box
                  component="img"
                  src={`${process.env.PUBLIC_URL}/assets/icons/bsky.svg`}
                  alt="BlueSky"
                  sx={{ width: 20, height: 20 }}
                />
              </MuiLink>
              <MuiLink
                href="http://linkedin.com/in/edenghirmai/"
                sx={{ color: "inherit" }}
              >
                <LinkedInIcon sx={{ fontSize: 20 }} />
              </MuiLink>
              <MuiLink href="https://3den.dev" sx={{ color: "inherit" }}>
                <LanguageIcon sx={{ fontSize: 20 }} />
              </MuiLink>
            </Box>
          </Box>
        </Box>

        {/* About text paragraphs */}
        <Typography
          variant="body1"
          sx={{ fontSize: "1.2rem", textAlign: "center", mt: 1 }}
        >
          We're friends Calli & Eden, the humans behind Flying Comet Games. We
          make fun, guilt-free games for digital publications to keep readers
          engaged and coming back each day.
        </Typography>

        <Typography
          variant="body1"
          sx={{ fontSize: "1.2rem", textAlign: "center", mt: -2 }}
        >
          In a world full of doomscrolling and distractions, we offer a quick,
          refreshing break that's easy to share with friends, family, and
          community.
        </Typography>

        <Typography variant="h6" sx={{ textAlign: "center", mt: -1 }}>
          Thanks for joining the fun! ✨
        </Typography>

        {/* Contact information */}
        <Box sx={{ mt: 2 }}>
          <Typography variant="body1" sx={{ textAlign: "center" }}>
            Contact us at
          </Typography>
          <MuiLink
            href="mailto:calli@flyingcometgames.com"
            sx={{
              textDecoration: "underline",
              color: "inherit",
              "&:hover": {
                opacity: 0.8,
              },
              fontWeight: "medium",
              fontSize: "1.1rem",
            }}
          >
            calli@flyingcometgames.com
          </MuiLink>
        </Box>
      </Box>
      {!isComponent && <Footer />}
    </Container>
  );
};

export default AboutUs;
