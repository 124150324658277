export const SEATTLE_WORDS = [
  // November 2024 - January 2025 (Original List)
  {
    date: "2024-11-15",
    hint: "This park was named after the Secretary of State who negotiated the Alaska Purchase, featuring a gorgeous lakefront and amphitheater",
    word: "SEWARD",
    characterCount: 6,
  },
  {
    date: "2024-11-16",
    hint: "These springtime blossoms draw thousands of visitors to the UW Quad each March and April",
    word: "CHERRY",
    characterCount: 6,
  },
  {
    date: "2024-11-17",
    hint: "This engineering marvel in Ballard allows boats to travel between different water levels",
    word: "LOCKS",
    characterCount: 5,
  },
  {
    date: "2024-11-18",
    hint: "This snow-capped peak is visible from Seattle on clear days, standing as Washington's third-highest mountain",
    word: "BAKER",
    characterCount: 5,
  },
  {
    date: "2024-11-19",
    hint: "This Alki Beach restaurant offers spectacular water views and renowned seafood dishes",
    word: "SALTYS",
    characterCount: 6,
  },
  {
    date: "2024-11-20",
    hint: "This stadium, previously known as CenturyLink Field, hosts NFL games and major concerts",
    word: "LUMEN",
    characterCount: 5,
  },
  {
    date: "2024-11-21",
    hint: "This waterfront neighborhood honors a Nisqually chief who was friendly with early settlers",
    word: "LESCHI",
    characterCount: 6,
  },
  {
    date: "2024-11-22",
    hint: "Home to Seattle's first public swimming pool, this Central District park remains a community hub",
    word: "PRATT",
    characterCount: 5,
  },
  {
    date: "2024-11-23",
    hint: "Seattle's oldest Japanese restaurant, serving sushi in the International District since 1904",
    word: "MANEKI",
    characterCount: 6,
  },
  {
    date: "2024-11-24",
    hint: "Look for the iconic red neon sign of this historic bakery near the waterfront",
    word: "FRANZ",
    characterCount: 5,
  },
  {
    date: "2024-11-25",
    hint: "A major downtown avenue named for a pioneering family who arrived in the 1850s",
    word: "BOREN",
    characterCount: 5,
  },
  {
    date: "2024-11-26",
    hint: "This retail giant began in Seattle's SODO district, famous for bulk shopping and $1.50 hot dogs",
    word: "COSTCO",
    characterCount: 6,
  },
  {
    date: "2024-11-27",
    hint: "Visit this city park's Japanese gardens for an unmatched view of the Cascades",
    word: "HILLMAN",
    characterCount: 7,
  },
  {
    date: "2024-11-28",
    hint: "This fine dining institution above Lake Union has hosted celebrities and special occasions since 1950",
    word: "CANLIS",
    characterCount: 6,
  },
  {
    date: "2024-11-29",
    hint: "A beloved local coffee chain that started in 1993 on Queen Anne Hill",
    word: "LADRO",
    characterCount: 5,
  },
  {
    date: "2024-11-30",
    hint: "Floating bridges connect Seattle to this Lake Washington island",
    word: "MERCER",
    characterCount: 6,
  },
  {
    date: "2024-12-01",
    hint: "This Capitol Hill restaurant puts an Asian twist on American fast food classics",
    word: "UMAMI",
    characterCount: 5,
  },
  {
    date: "2024-12-02",
    hint: "These flowers create stunning multicolored fields in the Skagit Valley each spring",
    word: "TULIP",
    characterCount: 5,
  },
  {
    date: "2024-12-03",
    hint: "When forecasters predict this weather event, Seattleites clear grocery store shelves",
    word: "FREEZE",
    characterCount: 6,
  },
  {
    date: "2024-12-04",
    hint: "This Seattle startup created an innovative video booth for capturing guest messages at weddings and events",
    word: "VOAST",
    characterCount: 5,
  },
  {
    date: "2024-12-05",
    hint: "This underground passageway replaced the Alaskan Way Viaduct",
    word: "TUNNEL",
    characterCount: 6,
  },
  {
    date: "2024-12-06",
    hint: "This music movement put Seattle on the cultural map in the early 1990s",
    word: "GRUNGE",
    characterCount: 6,
  },
  {
    date: "2024-12-07",
    hint: "This volcanic peak creates its own weather systems visible from Seattle",
    word: "BAKER",
    characterCount: 5,
  },
  {
    date: "2024-12-08",
    hint: "Seattle's WNBA team, four-time league champions",
    word: "STORM",
    characterCount: 5,
  },
  {
    date: "2024-12-09",
    hint: "These majestic birds can often be spotted fishing along the Puget Sound shoreline",
    word: "EAGLE",
    characterCount: 5,
  },
  {
    date: "2024-12-10",
    hint: "This Seattle Center museum celebrates popular culture and music history",
    word: "MOPOP",
    characterCount: 5,
  },
  {
    date: "2024-12-11",
    hint: "The weather condition Seattle is most famous for",
    word: "RAINY",
    characterCount: 5,
  },
  {
    date: "2024-12-12",
    hint: "This stunning Japanese garden in South Seattle was created by a pioneering Asian-American landscaper",
    word: "KUBOTA",
    characterCount: 6,
  },
  {
    date: "2024-12-13",
    hint: "This martial arts legend is buried in Lake View Cemetery on Capitol Hill",
    word: "BRUCE",
    characterCount: 5,
  },
  {
    date: "2024-12-14",
    hint: "Watch these iconic fish leap up the fish ladder at the Ballard Locks",
    word: "SALMON",
    characterCount: 6,
  },
  {
    date: "2024-12-15",
    hint: "These commercial fishing boats can be seen heading out from Fishermen's Terminal",
    word: "FISHER",
    characterCount: 6,
  },
  {
    date: "2024-12-16",
    hint: "These playful marine mammals can sometimes be spotted in Elliott Bay",
    word: "OTTER",
    characterCount: 5,
  },
  {
    date: "2024-12-17",
    hint: "This hill offers some of the best city views and was once Seattle's primary LGBTQ+ neighborhood",
    word: "QUEEN",
    characterCount: 5,
  },
  {
    date: "2024-12-18",
    hint: "These famous trees line the UW Quad and bloom each spring",
    word: "CHERRY",
    characterCount: 6,
  },
  {
    date: "2024-12-19",
    hint: "This performing arts venue near Seattle Center was named for a telecommunications pioneer",
    word: "MCCAW",
    characterCount: 5,
  },
  {
    date: "2024-12-20",
    hint: "This waterfront area houses hundreds of boats and offers stunning sunset views",
    word: "MARINA",
    characterCount: 6,
  },
  {
    date: "2024-12-21",
    hint: "This valley north of Seattle is famous for its tulip fields and eagle watching",
    word: "SKAGIT",
    characterCount: 6,
  },
  {
    date: "2024-12-22",
    hint: "These large public art pieces can be found throughout Pioneer Square and the International District",
    word: "MURAL",
    characterCount: 5,
  },
  {
    date: "2024-12-23",
    hint: "This street on Capitol Hill is home to many popular restaurants and bars",
    word: "OLIVE",
    characterCount: 5,
  },
  {
    date: "2024-12-24",
    hint: "This island, accessible only by ferry, is known for its artist community and local farms",
    word: "VASHON",
    characterCount: 6,
  },
  {
    date: "2024-12-25",
    hint: "This Mariners baseball legend has a statue outside T-Mobile Park",
    word: "EDGAR",
    characterCount: 5,
  },
  {
    date: "2024-12-26",
    hint: "This iconic restaurant near Pike Place Market is known for its coconut cream pies",
    word: "THEPI",
    characterCount: 5,
  },
  {
    date: "2024-12-27",
    hint: "This park offers one of Seattle's best viewpoints of downtown and Mount Rainier",
    word: "KRACKE",
    characterCount: 6,
  },
  {
    date: "2024-12-28",
    hint: "This historic downtown destination is famous for fish-throwing and local vendors",
    word: "MARKET",
    characterCount: 6,
  },
  {
    date: "2024-12-29",
    hint: "Watch planes take off and land from the observation deck at Seatac",
    word: "FLIGHT",
    characterCount: 6,
  },
  {
    date: "2024-12-30",
    hint: "This South Lake Union museum preserves and shares local history",
    word: "MOHAI",
    characterCount: 5,
  },
  {
    date: "2024-12-31",
    hint: "This popular medical TV show was set in Seattle but filmed elsewhere",
    word: "GREYS",
    characterCount: 5,
  },
  {
    date: "2025-01-01",
    hint: "This major downtown street is home to the original Nordstrom store",
    word: "THEAVE",
    characterCount: 6,
  },
  {
    date: "2025-01-03",
    hint: "This local newspaper has been publishing since 1891",
    word: "TIMES",
    characterCount: 5,
  },
  {
    date: "2025-01-04",
    hint: "This Ballard street leads to the Ballard Bridge",
    word: "DRAVUS",
    characterCount: 6,
  },
  {
    date: "2025-01-05",
    hint: "This gold rush trail connected Seattle to Alaska",
    word: "YUKON",
    characterCount: 5,
  },
  {
    date: "2025-01-06",
    hint: "This park in Ballard features a popular off-leash dog area",
    word: "GOLDEN",
    characterCount: 6,
  },
  {
    date: "2025-01-07",
    hint: "This botanical attraction near the Space Needle features numerous glass sculptures",
    word: "GARDEN",
    characterCount: 6,
  },
  {
    date: "2025-01-08",
    hint: "UW's athletic teams are known by this mascot",
    word: "HUSKY",
    characterCount: 5,
  },
  {
    date: "2025-01-09",
    hint: "This minimalist coffee shop chain started in Capitol Hill",
    word: "VOAST",
    characterCount: 5,
  },
  {
    date: "2025-01-10",
    hint: "Seattle is known as a hub for this type of beer production",
    word: "CRAFT",
    characterCount: 5,
  },
  {
    date: "2025-01-11",
    hint: "This historic burger joint in Fremont is known for its walk-up window",
    word: "UNEEDA",
    characterCount: 6,
  },
  {
    date: "2025-01-12",
    hint: "This street in Capitol Hill is lined with popular bars and restaurants",
    word: "PEARL",
    characterCount: 5,
  },
  {
    date: "2025-01-13",
    hint: "This distillery in SODO is known for its locally-made spirits",
    word: "BRANDY",
    characterCount: 6,
  },
  {
    date: "2025-01-14",
    hint: "These flowers bloom throughout the city in summer, especially at the Woodland Park Zoo",
    word: "DAHLIA",
    characterCount: 6,
  },
  {
    date: "2025-01-15",
    hint: "These NFL birds play their home games at Lumen Field",
    word: "HAWKS",
    characterCount: 5,
  },

  // New Verified Entries (Starting January 16, 2025)
  {
    date: "2025-01-16",
    hint: "This iconic Seattle ice cream shop is known for its seasonal flavors and long lines",
    word: "MOLLY",
    characterCount: 5,
  },
  {
    date: "2025-01-17",
    hint: "This drive-in burger institution has been serving Seattle since 1954",
    word: "DICKS",
    characterCount: 5,
  },
  {
    date: "2025-01-18",
    hint: "This Pioneer Square tower was once the tallest building west of the Mississippi",
    word: "SMITH",
    characterCount: 5,
  },
  {
    date: "2025-01-19",
    hint: "This seafood company runs oyster bars throughout the city",
    word: "TAYLOR",
    characterCount: 6,
  },
  {
    date: "2025-01-20",
    hint: "This Capitol Hill restaurant served upscale Spanish cuisine and dancing",
    word: "TANGO",
    characterCount: 5,
  },
  {
    date: "2025-01-21",
    hint: "This seafood restaurant chain started as a waterfront fish bar in 1938",
    word: "IVARS",
    characterCount: 5,
  },
  {
    date: "2025-01-22",
    hint: "This Capitol Hill coffee shop features vinyl records and local art",
    word: "ANALOG",
    characterCount: 6,
  },
  {
    date: "2025-01-23",
    hint: "This bay shares its name with a historic downtown bookstore",
    word: "ELLIOT",
    characterCount: 6,
  },
  {
    date: "2025-01-24",
    hint: "This Greenlake salon is known for its talented colorists like @hollybaylisshair",
    word: "FRINGE",
    characterCount: 6,
  },
  {
    date: "2025-01-25",
    hint: "This International District restaurant specializes in hand-pulled noodles",
    word: "BIANG",
    characterCount: 5,
  },
  {
    date: "2025-01-26",
    hint: "This Capitol Hill music venue has hosted countless iconic Seattle shows",
    word: "NEUMOS",
    characterCount: 6,
  },
  {
    date: "2025-01-27",
    hint: "This street hosts one of Capitol Hill's most popular Halloween celebrations",
    word: "ALOHA",
    characterCount: 5,
  },
  {
    date: "2025-01-28",
    hint: "This Queen Anne fine dining institution offers spectacular city views",
    word: "CANLIS",
    characterCount: 6,
  },
  {
    date: "2025-01-29",
    hint: "This Alki Beach institution is famous for their fish and chips",
    word: "SPUDS",
    characterCount: 5,
  },
  {
    date: "2025-01-30",
    hint: "This Capitol Hill spot serves Malaysian street food",
    word: "KEDAI",
    characterCount: 5,
  },
  {
    date: "2025-01-31",
    hint: "This Caribbean sandwich shop started in Fremont",
    word: "PASEO",
    characterCount: 5,
  },
  {
    date: "2025-02-01",
    hint: "This First Hill Italian restaurant has served Seattle since 1953",
    word: "VITOS",
    characterCount: 5,
  },
  {
    date: "2025-02-02",
    hint: "This Capitol Hill bakery specializes in Japanese bread and pastries",
    word: "RONDO",
    characterCount: 5,
  },
  {
    date: "2025-02-03",
    hint: "This Capitol Hill Italian restaurant and bar is known for small plates",
    word: "ARTUSI",
    characterCount: 6,
  },
  {
    date: "2025-02-04",
    hint: "This Pike Street bar features Spanish tapas and multiple levels",
    word: "BARCA",
    characterCount: 5,
  },
  {
    date: "2025-02-05",
    hint: "This Capitol Hill Mexican restaurant is known for their happy hour",
    word: "FOGON",
    characterCount: 5,
  },
  {
    date: "2025-02-06",
    hint: "This International District grocery store makes fresh bento boxes",
    word: "MARUTA",
    characterCount: 6,
  },
  {
    date: "2025-02-07",
    hint: "This South Lake Union spot serves Indian fusion cuisine",
    word: "RUPEE",
    characterCount: 5,
  },
  {
    date: "2025-02-08",
    hint: "This Ballard restaurant is known for inventive Northwest cuisine",
    word: "SAWYER",
    characterCount: 6,
  },
  {
    date: "2025-02-09",
    hint: "This Beacon Hill Filipino restaurant celebrates Pacific Northwest ingredients",
    word: "MUSANG",
    characterCount: 6,
  },
  {
    date: "2025-02-10",
    hint: "This Fremont Korean restaurant is known for their rice bowls",
    word: "REVEL",
    characterCount: 5,
  },
  {
    date: "2025-02-11",
    hint: "This beloved basketball team played their last Seattle game in 2008",
    word: "SONICS",
    characterCount: 6,
  },
  {
    date: "2025-02-12",
    hint: "This tech company's waterfront building features public art",
    word: "ADOBE",
    characterCount: 5,
  },
  {
    date: "2025-02-13",
    hint: "This company built Seattle's iconic transportation system for the World's Fair",
    word: "ALWEG",
    characterCount: 5,
  },
  {
    date: "2025-02-14",
    hint: "This pioneer's name graces a historic Seattle thoroughfare",
    word: "YESLER",
    characterCount: 6,
  },
  {
    date: "2025-02-15",
    hint: "This historic vessel on Lake Union served seafood for decades",
    word: "LUSTY",
    characterCount: 5,
  },
  {
    date: "2025-02-16",
    hint: "This historic waterfront tavern has been serving since 1953",
    word: "SLOOP",
    characterCount: 5,
  },
  {
    date: "2025-02-17",
    hint: "This historic Japanese-American baseball team played at old Sick's Stadium",
    word: "TIGERS",
    characterCount: 6,
  },
  {
    date: "2025-02-18",
    hint: "This historic Capitol Hill music venue helped launch many local bands",
    word: "REDUX",
    characterCount: 5,
  },
  {
    date: "2025-02-19",
    hint: "This company transformed South Lake Union into a tech hub",
    word: "VULCAN",
    characterCount: 6,
  },
  {
    date: "2025-02-20",
    hint: "This historic Queen Anne building housed a youth organization",
    word: "SCOUT",
    characterCount: 5,
  },
  {
    date: "2025-02-21",
    hint: "This arts organization funded many of Seattle's cultural institutions",
    word: "PONCHO",
    characterCount: 6,
  },
  {
    date: "2025-02-22",
    hint: "These races have been a Seattle summer tradition since 1950",
    word: "HYDRO",
    characterCount: 5,
  },
  {
    date: "2025-02-23",
    hint: "These spooky mansion tours reveal Pioneer Square's haunted history",
    word: "SCAREY",
    characterCount: 6,
  },
  {
    date: "2025-02-24",
    hint: "This historic vessel at South Lake Union preserves maritime history",
    word: "BETHEL",
    characterCount: 6,
  },
  {
    date: "2025-02-25",
    hint: "Local coffee company with cafes throughout the city",
    word: "STUMP",
    characterCount: 5,
  },
  {
    date: "2025-02-26",
    hint: "This triangular downtown park is known for its music events and fountain",
    word: "PINE",
    characterCount: 5,
  },
  {
    date: "2025-02-27",
    hint: "This Ballard street hosts a popular farmers market",
    word: "LEARY",
    characterCount: 5,
  },
  {
    date: "2025-02-28",
    hint: "This historic neighborhood was once a separate city until 1907",
    word: "FREMONT",
    characterCount: 6,
  },
  {
    date: "2025-03-01",
    hint: "This popular Central District ice cream shop is known for its unique flavors",
    word: "FRANKIE",
    characterCount: 6,
  },
  {
    date: "2025-03-02",
    hint: "This colorful bridge connects Queen Anne to Fremont",
    word: "AURORA",
    characterCount: 6,
  },
  {
    date: "2025-03-03",
    hint: "This massive park in West Seattle offers beach access and forest trails",
    word: "LINCOLN",
    characterCount: 6,
  },
  {
    date: "2025-03-04",
    hint: "This South Lake Union seafood restaurant serves classic chowder",
    word: "DUKES",
    characterCount: 5,
  },
  {
    date: "2025-03-05",
    hint: "This Capitol Hill bookstore hosts author readings and community events",
    word: "THIRD",
    characterCount: 5,
  },
  {
    date: "2025-03-06",
    hint: "This waterfront attraction features an enormous Ferris wheel with spectacular views",
    word: "GIANT",
    characterCount: 5,
  },
  {
    date: "2025-03-07",
    hint: "This popular Seattle-based clothing brand focuses on outdoor gear",
    word: "FILSON",
    characterCount: 6,
  },
  {
    date: "2025-03-08",
    hint: "This university radio station broadcasts from the UW campus",
    word: "SOUND",
    characterCount: 5,
  },
  {
    date: "2025-03-09",
    hint: "This longtime Seattle cookie bakery is famous for pink boxes",
    word: "HELLO",
    characterCount: 5,
  },
  {
    date: "2025-03-10",
    hint: "This Wallingford diner has been serving breakfast since 1976",
    word: "JULIA",
    characterCount: 5,
  },
  {
    date: "2025-03-11",
    hint: "This historic building in Pioneer Square has distinctive triangular floor plan",
    word: "GLOBE",
    characterCount: 5,
  },
  {
    date: "2025-03-12",
    hint: "This waterfront sculpture park features works by major artists",
    word: "MYRTLE",
    characterCount: 6,
  },
  {
    date: "2025-03-13",
    hint: "This colorful boathouse on Lake Union offers watercraft rentals",
    word: "VERDE",
    characterCount: 5,
  },
  {
    date: "2025-03-14",
    hint: "This multicolored gas station in Georgetown is a local landmark",
    word: "OXBOW",
    characterCount: 5,
  },
  {
    date: "2025-03-15",
    hint: "This Chinatown dessert spot specializes in bubble tea and shaved ice",
    word: "GOSSIP",
    characterCount: 6,
  },
  {
    date: "2025-03-16",
    hint: "This famous food stand at Pike Place Market sells piroshky pastries",
    word: "LOWELL",
    characterCount: 6,
  },
  {
    date: "2025-03-17",
    hint: "This historic theater in the U-District shows independent films",
    word: "GRAND",
    characterCount: 5,
  },
  {
    date: "2025-03-18",
    hint: "This iconic Lake Union restaurant floats on the water",
    word: "BARGE",
    characterCount: 5,
  },
  {
    date: "2025-03-19",
    hint: "This city beach offers views of the Olympic Mountains",
    word: "SHORES",
    characterCount: 6,
  },
  {
    date: "2025-03-20",
    hint: "This signature Seattle beverage combines carbonated water with flavored Italian extracts",
    word: "TONIC",
    characterCount: 5,
  },
  {
    date: "2025-03-21",
    hint: "This Eastlake bistro offers French-inspired Northwest cuisine",
    word: "PETIT",
    characterCount: 5,
  },
  {
    date: "2025-03-22",
    hint: "This Fremont Sunday gathering sells vintage items and local crafts",
    word: "BAZAAR",
    characterCount: 6,
  },
  {
    date: "2025-03-23",
    hint: "This waterfront pizza restaurant offers spectacular bay views",
    word: "SLICES",
    characterCount: 6,
  },
  {
    date: "2025-03-24",
    hint: "This local brewery in Ballard is known for its IPAs",
    word: "REUBEN",
    characterCount: 6,
  },
  {
    date: "2025-03-25",
    hint: "This historic cemetery on Capitol Hill has famous graves",
    word: "TOMBS",
    characterCount: 5,
  },
  {
    date: "2025-03-26",
    hint: "This Seattle sandwich shop specializes in Vietnamese banh mi",
    word: "SAIGON",
    characterCount: 6,
  },
];

export const getWordForDate = (date) => {
  if (!date || !SEATTLE_WORDS || !Array.isArray(SEATTLE_WORDS)) {
    return null;
  }

  // Convert to PT/Los Angeles timezone
  const ptDate = new Date(
    date.toLocaleString("en-US", { timeZone: "America/Los_Angeles" })
  );

  // Get current date in PT
  const currentPTDate = new Date(
    new Date().toLocaleString("en-US", { timeZone: "America/Los_Angeles" })
  );

  // Reset time parts for accurate date comparison
  currentPTDate.setHours(0, 0, 0, 0);
  ptDate.setHours(0, 0, 0, 0);

  // If requested date is in the future, return null
  if (ptDate > currentPTDate) {
    return null;
  }

  // Format the PT date as YYYY-MM-DD
  const dateString =
    ptDate.getFullYear() +
    "-" +
    String(ptDate.getMonth() + 1).padStart(2, "0") +
    "-" +
    String(ptDate.getDate()).padStart(2, "0");

  return SEATTLE_WORDS.find((entry) => entry.date === dateString);
};

export const findLatestAvailableDate = () => {
  if (!SEATTLE_WORDS || !Array.isArray(SEATTLE_WORDS)) {
    return null;
  }

  const currentPTDate = new Date(
    new Date().toLocaleString("en-US", { timeZone: "America/Los_Angeles" })
  );

  // Create date string in YYYY-MM-DD format to ignore time
  const currentDateString = [
    currentPTDate.getFullYear(),
    (currentPTDate.getMonth() + 1).toString().padStart(2, "0"),
    currentPTDate.getDate().toString().padStart(2, "0"),
  ].join("-");

  // Filter by comparing normalized date strings
  const latestEntry = SEATTLE_WORDS.filter((entry) => {
    // Compare date strings directly (they're already in YYYY-MM-DD format)
    return entry.date <= currentDateString;
  }).sort((a, b) => b.date.localeCompare(a.date))[0];

  return latestEntry || null;
};
