import React, { useState, useRef, useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Button from "@mui/material/Button";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import LogoutIcon from "@mui/icons-material/Logout";
import ListItemText from "@mui/material/ListItemText";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import Tooltip from "@mui/material/Tooltip";
import MenuIcon from "@mui/icons-material/Menu";
import InfoIcon from "@mui/icons-material/Info";
import SecurityIcon from "@mui/icons-material/Security";
import DescriptionIcon from "@mui/icons-material/Description";
import FeedbackIcon from "@mui/icons-material/Feedback";
import SportsEsportsIcon from "@mui/icons-material/SportsEsports";
import useScrollTrigger from "@mui/material/useScrollTrigger";
import Slide from "@mui/material/Slide";
import { useStytchUser } from "@stytch/react";
import AuthListItem from "../components/AuthListItem";

// Function to hide the toolbar on scroll down and show on scroll up
function HideOnScroll(props) {
  const { children } = props;
  // Note: threshold determines how far user must scroll before showing/hiding
  const trigger = useScrollTrigger({
    disableHysteresis: false,
    threshold: 100,
  });

  return (
    <Slide appear={false} direction="down" in={!trigger}>
      {children}
    </Slide>
  );
}

const GameToolbar = ({
  basePath,
  backgroundColor = "#f4f0df",
  menuItems = [
    {
      text: "Home",
      icon: `${process.env.PUBLIC_URL}/assets/logo.svg`,
      path: "/",
    },
    // {
    //   text: "Ready-to-play games",
    //   icon: `${process.env.PUBLIC_URL}/assets/icons/wordy-verse-icon2.svg`,
    //   path: "/play",
    // },
    // {
    //   text: "Trivia Roundup",
    //   icon: `${process.env.PUBLIC_URL}/assets/icons/trivia-roundup-icon.svg`,
    //   path: "/trivia-roundup/seattle",
    // },
  ],
}) => {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const { user } = useStytchUser();
  const toolbarRef = useRef(null);
  const [toolbarHeight, setToolbarHeight] = useState(0);
  const [prevScrollPos, setPrevScrollPos] = useState(0);
  const [visible, setVisible] = useState(true);
  const location = useLocation();

  // Measure toolbar height on mount and window resize
  useEffect(() => {
    const updateToolbarHeight = () => {
      if (toolbarRef.current) {
        setToolbarHeight(toolbarRef.current.clientHeight);
      }
    };

    // Initial measurement
    updateToolbarHeight();

    // Add resize listener
    window.addEventListener("resize", updateToolbarHeight);

    // Clean up
    return () => {
      window.removeEventListener("resize", updateToolbarHeight);
    };
  }, []);

  // Close drawer when route changes
  useEffect(() => {
    setDrawerOpen(false);
  }, [location.pathname]);

  const handleKofiClick = () => {
    window.open("https://ko-fi.com/V7V2162LVY", "_blank");
  };

  const handleBookDemoClick = () => {
    // You can replace this with your actual booking URL or handle it differently
    window.open("https://cal.com/calli-fuchigami/15min", "_blank");
    // Analytics tracking could be added here
  };

  const toggleDrawer = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setDrawerOpen(open);
  };

  const bottomMenuItems = [
    { text: "About Us", icon: <InfoIcon />, path: "/about" },
    { text: "Privacy Policy", icon: <SecurityIcon />, path: "/privacy" },
    { text: "Terms of Use", icon: <DescriptionIcon />, path: "/terms" },
    // { text: "Send Feedback", icon: <FeedbackIcon />, path: "/feedback" },
  ];

  const drawerContent = (
    <Box
      sx={{
        width: 250,
        height: "100%",
        display: "flex",
        flexDirection: "column",
      }}
      role="presentation"
      onClick={toggleDrawer(false)}
      onKeyDown={toggleDrawer(false)}
    >
      {/* <Box
        component="a"
        href="https://docs.google.com/forms/d/e/1FAIpQLSeYUR5M-y0ys8IYcgHAp2duNV-RphHx9h82TWCmY-5zEYoKSA/viewform"
        target="_blank"
        rel="noopener noreferrer"
        sx={{
          display: "block",
          width: "100%",
          cursor: "pointer",
          transition: "transform 0.2s ease, opacity 0.2s ease",
          paddingRight: 0.5,
          paddingTop: 1,
          "&:hover": {
            transform: "scale(1.02)",
            opacity: 0.9,
          },
          "&:active": {
            transform: "scale(0.98)",
          },
        }}
      >
        <img
          src={`${process.env.PUBLIC_URL}/assets/wordy-topics/request-a-topic.svg`}
          alt="Request a topic"
          style={{
            width: "100%",
            display: "block",
          }}
        />
      </Box> */}

      <List>
        {menuItems.map((item) => (
          <ListItem
            button
            key={item.text}
            component={Link}
            to={`${item.path}`}
            sx={{
              py: 2,
              "&:hover": {
                backgroundColor: "rgba(0, 0, 0, 0.04)",
              },
            }}
          >
            <ListItemIcon sx={{ minWidth: 40 }}>
              <img
                src={item.icon}
                alt={item.title}
                style={{ width: 24, height: 24 }}
              />
            </ListItemIcon>
            <ListItemText primary={item.text} />
          </ListItem>
        ))}
      </List>

      <Divider />

      <List sx={{ mt: "auto" }}>
        {bottomMenuItems.map((item) => (
          <ListItem
            button
            key={item.text}
            component={Link}
            to={item.path}
            sx={{
              py: 2,
              "&:hover": {
                backgroundColor: "rgba(0, 0, 0, 0.04)",
              },
            }}
          >
            <ListItemIcon sx={{ minWidth: 40 }}>{item.icon}</ListItemIcon>
            <ListItemText primary={item.text} />
          </ListItem>
        ))}
      </List>
    </Box>
  );

  return (
    <>
      {/* This wrapper provides the space for the fixed toolbar */}
      <Box
        sx={{
          paddingTop: `${toolbarHeight}px`,
          transition: "padding-top 0.3s ease",
        }}
      >
        <HideOnScroll>
          <AppBar
            position="fixed"
            elevation={visible ? 2 : 0}
            sx={{
              top: 0,
              left: 0,
              right: 0,
              width: "100%",
              zIndex: 1100,
              backgroundColor: backgroundColor,
              borderBottom: "1px solid black",
              transition: "box-shadow 0.3s ease, transform 0.3s ease",
            }}
          >
            <Box maxWidth="1200px" mx="auto" width="100%" ref={toolbarRef}>
              <Toolbar
                sx={{ backgroundColor, justifyContent: "space-between" }}
              >
                <Box
                  component={Link}
                  to="/"
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    textDecoration: "none",
                    color: "inherit",
                    transition: "opacity 0.2s ease",
                    "&:hover": { opacity: 0.85 },
                    "&:focus": {
                      outline: "2px solid #000",
                      borderRadius: "4px",
                    },
                    "&:focus:not(:focus-visible)": { outline: "none" },
                  }}
                  aria-label="Flying Comet Games Home"
                >
                  <img
                    src={`${process.env.PUBLIC_URL}/assets/logo-vertical.svg`}
                    alt={`Flying Comet Games Logo`}
                    style={{ height: "45px", marginRight: "10px" }}
                  />
                </Box>

                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <Button
                    variant="contained"
                    onClick={handleBookDemoClick}
                    aria-label="Book a demo"
                    sx={{
                      bgcolor: "#000",
                      color: "#fff",
                      px: { xs: 2, md: 4 },
                      borderRadius: "25px",
                      textDecoration: "none",
                      fontWeight: 600,
                      fontSize: { xs: "0.8rem", md: "1.1rem" },
                      mr: { xs: 1, md: 2 },
                      whiteSpace: "nowrap",
                      minWidth: { xs: "auto", md: "140px" },
                      boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
                      transition: "all 0.3s ease",
                      "&:hover": {
                        bgcolor: "#333",
                        transform: "translateY(-2px)",
                        boxShadow: "0px 6px 12px rgba(0, 0, 0, 0.15)",
                      },
                      "&:active": {
                        transform: "translateY(1px)",
                        boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
                      },
                      "&:focus": {
                        outline: "2px solid #666",
                        outlineOffset: "2px",
                      },
                      "&:focus:not(:focus-visible)": { outline: "none" },
                      display: { xs: "flex", md: "inline-flex" },
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    Book a Demo
                  </Button>
                  <IconButton
                    color="black"
                    edge="start"
                    onClick={toggleDrawer(true)}
                    aria-label="Open menu"
                    sx={{
                      ml: { xs: "1px", md: "4px" },
                      transition: "background-color 0.2s ease",
                      "&:hover": { backgroundColor: "rgba(0, 0, 0, 0.05)" },
                      "&:focus": {
                        outline: "2px solid #000",
                        borderRadius: "50%",
                      },
                      "&:focus:not(:focus-visible)": { outline: "none" },
                    }}
                  >
                    <MenuIcon />
                  </IconButton>
                </Box>
              </Toolbar>
            </Box>
          </AppBar>
        </HideOnScroll>
      </Box>

      <Drawer
        anchor="right"
        open={drawerOpen}
        onClose={toggleDrawer(false)}
        SlideProps={{
          timeout: { enter: 300, exit: 200 },
        }}
      >
        {drawerContent}
      </Drawer>
    </>
  );
};

export default GameToolbar;
