import React from "react";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import Typography from "@mui/material/Typography";
import { keyframes } from "@mui/system";

// Define animation keyframes
const fadeIn = keyframes`
  from { opacity: 0; }
  to { opacity: 1; }
`;

const pulse = keyframes`
  0% { transform: scale(1); }
  50% { transform: scale(1.05); }
  100% { transform: scale(1); }
`;

const GameLoader = ({ height = 500 }) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        height: `${height}px`,
        width: "100%",
        animation: `${fadeIn} 0.3s ease-in-out`,
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          animation: `${pulse} 2s infinite ease-in-out`,
        }}
      >
        <CircularProgress
          size={60}
          thickness={4}
          sx={{
            color: "#000",
            mb: 2,
          }}
        />
        <Typography
          variant="body1"
          sx={{
            fontWeight: 500,
            opacity: 0.8,
          }}
        >
          Loading game...
        </Typography>
      </Box>
    </Box>
  );
};

export default GameLoader;
