import React, { useState, useEffect } from "react";
import {
  Dialog,
  Box,
  IconButton,
  Typography,
  Divider,
  Tab,
  Tabs,
  Button,
  Paper,
  Modal,
  Stepper,
  Step,
  StepLabel,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import { X, Lightbulb, Newspaper, HelpCircle } from "lucide-react";
import { motion, AnimatePresence } from "framer-motion";

export const HintModal = ({ hint, url, open, onClose, children }) => {
  const [activeTab, setActiveTab] = useState(0);
  const [showFullArticle, setShowFullArticle] = useState(false);

  // Extract domain name for article title display
  const getArticleTitle = (url) => {
    console.log("URL: ", url);
    if (!url) return "Related Article";
    try {
      const domain = new URL(url).hostname.replace("www.", "");
      return `Article from ${domain}`;
    } catch (e) {
      return "Related Article";
    }
  };

  const articleData = url
    ? {
        title: getArticleTitle(url),
        snippet:
          "Learn more about the context behind this word puzzle in our featured article...",
      }
    : null;

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  return (
    <Modal open={open} onClose={onClose}>
      <Box
        maxWidth="xs"
        fullWidth
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: "90%",
          maxWidth: "400px",
          bgcolor: "white",
          p: 4,
          outline: "none",
          textAlign: "center",
          overflow: "hidden",
          boxShadow: "0 10px 25px rgba(0, 0, 0, 0.2)",
          border: "1px solid black",
        }}
      >
        <IconButton
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: "text.secondary",
          }}
        >
          <X size={24} />
        </IconButton>

        {/* Tabs for toggling between hint and news */}
        {url && (
          <Tabs
            value={activeTab}
            onChange={handleTabChange}
            centered
            sx={{ mb: 2 }}
          >
            <Tab
              icon={<Lightbulb size={16} />}
              label="HINT"
              iconPosition="start"
              sx={{
                fontWeight: 600,
                fontSize: "0.75rem",
                minHeight: 36,
                py: 0,
              }}
            />
            <Tab
              icon={<Newspaper size={16} />}
              label="ARTICLE"
              iconPosition="start"
              sx={{
                fontWeight: 600,
                fontSize: "0.75rem",
                minHeight: 36,
                py: 0,
              }}
            />
          </Tabs>
        )}

        {/* Hint content */}
        {(!url || activeTab === 0) && (
          <Box
            sx={{
              textAlign: "left",
              mb: 2,
              display: activeTab === 0 ? "block" : "none",
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                mb: 3,
              }}
            >
              <Lightbulb size={24} />
              <Typography
                variant="h6"
                component="h2"
                sx={{
                  fontWeight: 600,
                  fontSize: "1.25rem",
                  ml: 1,
                }}
              >
                Hint
              </Typography>
            </Box>

            <Typography
              variant="body1"
              sx={{
                fontSize: "1rem",
                lineHeight: 1.5,
              }}
            >
              {hint}
            </Typography>
          </Box>
        )}

        {/* News article content */}
        {url && activeTab === 1 && (
          <Box sx={{ mb: 2 }}>
            <Typography
              variant="h6"
              component="h2"
              sx={{
                fontWeight: 600,
                fontSize: "1.25rem",
                color: "text.primary",
                mb: 2,
              }}
            >
              {articleData.title}
            </Typography>

            {!showFullArticle ? (
              <>
                <Typography
                  variant="body2"
                  sx={{ color: "text.secondary", mb: 2 }}
                >
                  {articleData.snippet}
                </Typography>
                <Button
                  variant="outlined"
                  size="small"
                  onClick={() => setShowFullArticle(true)}
                  sx={{ mt: 1 }}
                >
                  Read Full Article
                </Button>
              </>
            ) : (
              <Box
                sx={{
                  height: 350,
                  width: "100%",
                  overflow: "hidden",
                  borderRadius: 1,
                  border: "1px solid rgba(0,0,0,0.12)",
                }}
              >
                <iframe
                  src={url}
                  title="Article Content"
                  width="100%"
                  height="100%"
                  style={{ border: "none" }}
                />
                <Button
                  variant="text"
                  size="small"
                  onClick={() => setShowFullArticle(false)}
                  sx={{ mt: 2 }}
                >
                  Show Less
                </Button>
              </Box>
            )}
          </Box>
        )}

        {/* Optional news indicator when on hint tab */}
        {url && activeTab === 0 && (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              mt: 2,
            }}
          >
            <Paper
              elevation={0}
              sx={{
                backgroundColor: "rgba(0,0,0,0.05)",
                px: 1.5,
                py: 0.5,
                borderRadius: 4,
                display: "inline-flex",
                alignItems: "center",
                gap: 1,
                cursor: "pointer",
              }}
              onClick={() => setActiveTab(1)}
            >
              <Newspaper size={14} />
              <Typography variant="caption" sx={{ fontWeight: 500 }}>
                Related Article Available
              </Typography>
            </Paper>
          </Box>
        )}
      </Box>
    </Modal>
  );
};

export const HowToModal = ({
  open,
  onClose,
  correctColor,
  presentColor,
  absentColor,
}) => {
  const handleClose = () => {
    onClose();
  };

  // Shadow from GameGrid component
  const boxShadow = "4px 4px 0px rgba(0, 0, 0, 0.3)";

  return (
    <Modal open={open} onClose={handleClose}>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: "90%",
          maxWidth: "400px",
          height: "auto",
          minHeight: "580px",
          bgcolor: "white",
          borderRadius: "12px",
          p: 2,
          pt: 2.5,
          pb: 12,
          outline: "none",
          textAlign: "center",
          position: "relative",
          overflow: "visible",
          boxShadow: "0 10px 25px rgba(0, 0, 0, 0.2)",
        }}
      >
        {/* Close button (X) */}
        <IconButton
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: "text.secondary",
            zIndex: 2,
          }}
        >
          <X size={22} />
        </IconButton>

        {/* Header with icon and title */}
        {/* <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            mb: 4,
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              width: 36,
              height: 36,
              bgcolor: "#f5f5f5",
              borderRadius: "50%",
              mr: 1.5,
              fontSize: "1.25rem",
              fontWeight: "bold",
            }}
          >
            <HelpCircle size={32} strokeWidth={2} />
          </Box>
          <Typography
            variant="h4"
            sx={{
              fontWeight: "bold",
              color: "text.primary",
              fontSize: "1.75rem",
            }}
          >
            How to play
          </Typography>
        </Box> */}

        {/* Question mark icon and title */}
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            gap: 2,
            mb: 4,
          }}
        >
          <HelpCircle size={32} strokeWidth={2} />
          <Typography
            variant="h4"
            component="h2"
            sx={{
              fontWeight: "bold",
              fontSize: { xs: "2rem", sm: "2.2rem" },
              lineHeight: 1.3,
              color: "black",
            }}
          >
            How to play
          </Typography>
        </Box>

        {/* Instructions */}
        <Box sx={{ textAlign: "left", mb: 4 }}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              mb: 2,
              marginLeft: "20px",
            }}
          >
            <Typography
              variant="body1"
              sx={{ fontSize: "1rem", lineHeight: 1.3 }}
            >
              Type any word to start.
              <br />
              Click enter to submit.
            </Typography>
          </Box>

          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              mb: 2,
              marginLeft: "20px",
            }}
          >
            <Typography
              variant="body1"
              sx={{ fontSize: "1rem", lineHeight: 1.3 }}
            >
              Letters will change color based upon
              <br />
              the correct letter and placement.
            </Typography>
          </Box>

          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              mb: 2,
              marginLeft: "20px",
            }}
          >
            <Typography
              variant="body1"
              sx={{ fontSize: "1rem", lineHeight: 1.3 }}
            >
              Guess the correct word in the provided
              <br />
              number of rows to solve it!
            </Typography>
          </Box>

          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              mb: 2,
              marginLeft: "20px",
            }}
          >
            <Typography
              variant="body1"
              sx={{ fontSize: "1rem", lineHeight: 1.3 }}
            >
              Click the <span style={{ fontSize: "1.1rem" }}>💡</span> lightbulb
              for a hint.
            </Typography>
          </Box>
        </Box>

        {/* Color boxes explanation */}
        <Box sx={{ mb: 4 }}>
          {/* Gray box - incorrect letter */}
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              mb: 2,
              gap: 2,
              marginLeft: "20px",
            }}
          >
            <Box
              sx={{
                width: 50,
                height: 50,
                backgroundColor: absentColor[0],
                border: "2px solid #000",
                borderRadius: "8px",
                boxShadow,
                minWidth: 50,
              }}
            />
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Box
                component="span"
                sx={{ display: "flex", alignItems: "center" }}
              >
                <Box
                  component="span"
                  sx={{
                    color: "red",
                    mr: 0.75,
                    fontSize: "1.1rem",
                    fontWeight: "bold",
                  }}
                >
                  ✗
                </Box>
                <Typography sx={{ fontSize: "0.95rem" }}>
                  Incorrect letter
                </Typography>
              </Box>
            </Box>
          </Box>

          {/* Red box - correct letter, incorrect place */}
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              mb: 2,
              gap: 2,
              marginLeft: "20px",
            }}
          >
            <Box
              sx={{
                width: 50,
                height: 50,
                backgroundColor: presentColor[0],
                border: "2px solid #000",
                borderRadius: "8px",
                boxShadow,
                minWidth: 50,
              }}
            />
            <Box>
              <Box
                component="span"
                sx={{ display: "flex", alignItems: "center" }}
              >
                <Box
                  component="span"
                  sx={{
                    color: "green",
                    mr: 0.75,
                    fontSize: "1.1rem",
                    fontWeight: "bold",
                  }}
                >
                  ✓
                </Box>
                <Typography sx={{ fontSize: "0.95rem" }}>
                  Correct letter
                </Typography>
              </Box>
              <Box
                component="span"
                sx={{ display: "flex", alignItems: "center" }}
              >
                <Box
                  component="span"
                  sx={{
                    color: "red",
                    mr: 0.75,
                    fontSize: "1.1rem",
                    fontWeight: "bold",
                  }}
                >
                  ✗
                </Box>
                <Typography sx={{ fontSize: "0.95rem" }}>
                  <span style={{ textDecoration: "underline" }}>Incorrect</span>{" "}
                  place
                </Typography>
              </Box>
            </Box>
          </Box>

          {/* Blue box - correct letter, correct place */}
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              mb: 2,
              gap: 2,
              marginLeft: "20px",
            }}
          >
            <Box
              sx={{
                width: 50,
                height: 50,
                backgroundColor: correctColor[0],
                border: "2px solid #000",
                borderRadius: "8px",
                boxShadow,
                minWidth: 50,
              }}
            />
            <Box>
              <Box
                component="span"
                sx={{ display: "flex", alignItems: "center" }}
              >
                <Box
                  component="span"
                  sx={{
                    color: "green",
                    mr: 0.75,
                    fontSize: "1.1rem",
                    fontWeight: "bold",
                  }}
                >
                  ✓
                </Box>
                <Typography sx={{ fontSize: "0.95rem" }}>
                  Correct letter
                </Typography>
              </Box>
              <Box
                component="span"
                sx={{ display: "flex", alignItems: "center" }}
              >
                <Box
                  component="span"
                  sx={{
                    color: "green",
                    mr: 0.75,
                    fontSize: "1.1rem",
                    fontWeight: "bold",
                  }}
                >
                  ✓
                </Box>
                <Typography sx={{ fontSize: "0.95rem" }}>
                  Correct place
                </Typography>
              </Box>
            </Box>
          </Box>
        </Box>

        {/* Play button at the bottom */}
        <Box
          sx={{
            position: "absolute",
            bottom: 30,
            left: 0,
            right: 0,
            width: "100%",
            overflow: "hidden",
            height: "70px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            padding: "0 16px",
          }}
        >
          <Button
            variant="contained"
            onClick={handleClose}
            disableElevation
            sx={{
              bgcolor: "#000",
              color: "#fff",
              width: "85%",
              height: "80%",
              fontSize: "1.3rem",
              fontWeight: "bold",
              textTransform: "none",
              border: "2px solid rgba(255,255,255,0.2)",
              borderRadius: "20px",
              boxShadow: "none",
              py: 1,
              transition: "all 0.2s ease",
              "&:hover": {
                bgcolor: "#333",
                transform: "scale(1.02)",
              },
              "&:active": {
                transform: "scale(0.98)",
              },
            }}
          >
            Play!
          </Button>
        </Box>

        {/* Footer with Flying Comet Games link */}
        <Box
          sx={{
            position: "absolute",
            bottom: 0,
            left: 0,
            right: 0,
            width: "100%",
            height: "30px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            padding: "0 16px",
            paddingBottom: 1,
            backgroundColor: "white",
            borderBottomLeftRadius: "12px",
            borderBottomRightRadius: "12px",
          }}
        >
          <Box
            sx={{
              fontSize: "0.7rem",
              color: "#777",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            Powered by{" "}
            <Box
              component="a"
              href="https://flyingcometgames.com"
              target="_blank"
              rel="noopener noreferrer"
              sx={{
                textDecoration: "none",
                color: "#777",
                cursor: "pointer",
                ml: 0.5,
                "&:hover": {
                  color: "#000",
                  textDecoration: "underline",
                },
              }}
            >
              Flying Comet Games
            </Box>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
};

const ColorBox = ({
  color,
  backgroundColor,
  children,
  presentColor,
  absentColor,
}) => (
  <motion.div
    initial={{ opacity: 0 }}
    animate={{ opacity: 1 }}
    transition={{ duration: 0.3 }}
  >
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        marginLeft: "25px",
        mb: 2,
        gap: 2,
      }}
    >
      <Box
        sx={{
          width: 45,
          height: 45,
          backgroundColor,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          color,
          fontWeight: "bold",
          fontSize: "1.25rem",
          border: "1px black solid",
          borderRadius: "4px",
        }}
      >
        {children}
      </Box>
      <Typography>
        {backgroundColor === absentColor[0] ? (
          <Box component="span" sx={{ display: "flex", alignItems: "center" }}>
            <Box
              component="span"
              sx={{
                color: "red",
                mr: 1,
                fontSize: "1.2rem",
                fontWeight: "bold",
              }}
            >
              ✗
            </Box>
            Incorrect letter
          </Box>
        ) : backgroundColor === presentColor[0] ? (
          <Box>
            <Box
              component="span"
              sx={{ display: "flex", alignItems: "center" }}
            >
              <Box
                component="span"
                sx={{
                  color: "green",
                  mr: 1,
                  fontSize: "1.2rem",
                  fontWeight: "bold",
                }}
              >
                ✓
              </Box>
              Correct letter
            </Box>
            <Box
              component="span"
              sx={{ display: "flex", alignItems: "center" }}
            >
              <Box
                component="span"
                sx={{
                  color: "red",
                  mr: 1,
                  fontSize: "1.2rem",
                  fontWeight: "bold",
                }}
              >
                ✗
              </Box>
              Incorrect place
            </Box>
          </Box>
        ) : (
          <Box>
            <Box
              component="span"
              sx={{ display: "flex", alignItems: "center" }}
            >
              <Box
                component="span"
                sx={{
                  color: "green",
                  mr: 1,
                  fontSize: "1.2rem",
                  fontWeight: "bold",
                }}
              >
                ✓
              </Box>
              Correct letter
            </Box>
            <Box
              component="span"
              sx={{ display: "flex", alignItems: "center" }}
            >
              <Box
                component="span"
                sx={{
                  color: "green",
                  mr: 1,
                  fontSize: "1.2rem",
                  fontWeight: "bold",
                }}
              >
                ✓
              </Box>
              Correct place
            </Box>
          </Box>
        )}
      </Typography>
    </Box>
  </motion.div>
);

const HintContent = ({ correctColor, presentColor, absentColor }) => (
  <>
    <ColorBox
      color={absentColor[1]}
      backgroundColor={absentColor[0]}
      presentColor={presentColor}
      absentColor={absentColor}
    >
      A
    </ColorBox>
    <ColorBox
      color={presentColor[1]}
      backgroundColor={presentColor[0]}
      presentColor={presentColor}
      absentColor={absentColor}
    >
      B
    </ColorBox>
    <ColorBox
      color={correctColor[1]}
      backgroundColor={correctColor[0]}
      presentColor={presentColor}
      absentColor={absentColor}
    >
      C
    </ColorBox>
  </>
);

export default function GameHint({
  hint,
  theme,
  url,
  open,
  onClose,
  correctColor,
  presentColor,
  absentColor,
}) {
  if (theme) {
    return (
      <HowToModal
        open={open}
        onClose={onClose}
        correctColor={correctColor}
        presentColor={presentColor}
        absentColor={absentColor}
      />
    );
  }
  return (
    <HintModal url={url} hint={hint} open={open} onClose={onClose}>
      <HintContent
        correctColor={correctColor}
        presentColor={presentColor}
        absentColor={absentColor}
      />
    </HintModal>
  );
}
