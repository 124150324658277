import {
getWordForDate,
 findLatestAvailableDate,
} from "./Data/AlamedaPostData";
import BaseWordyGame from "../Components/BaseWordyGame";

const AlamedaPostDemoEmbed = () => {
  return (
    <BaseWordyGame
      title={"The Alameda Post"}
      customTitle="The Alameda Post Wordy"
      iconPath={`${process.env.PUBLIC_URL}/assets/customer-logos/ap-logo.png`}
      shareText="The Alemda Post Wordy"
      shareUrl="https://flyingcometgames.com/wordy-verse/burien"
      getWordForDate={getWordForDate}
      findLatestAvailableDate={findLatestAvailableDate}
      correctColor="#75B4B7"
      presentColor="#ECB061"
      absentColor="#000000"
      keyboardBackgroundColor="#d3d6da"
      isEmbedded={true}
    />
  );
};

export default AlamedaPostDemoEmbed;
