import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { useTheme } from "@mui/material/styles";

const GameTileBaseSmall = ({
  svgLogo,
  description,
  title,
  link,
  bgColor,
  isLocked = false,
  isSelected = false,
  onSelect = () => {},
}) => {
  const theme = useTheme();
  const activeState = isSelected;

  return (
    <Box
      sx={{
        position: "relative",
        width: "100%",
        backgroundColor: "#f5f5f5", // Light gray background for the polaroid effect
        borderRadius: "3px",
        border: "1px solid black",
        padding: "8px",
        boxShadow: activeState
          ? `0 8px 16px rgba(0,0,0,0.2)`
          : theme.shadows[2],
        display: "flex",
        flexDirection: "column",
        aspectRatio: "1/1.3", // Approximate polaroid proportions
        transform: activeState ? "translateY(-8px)" : "none",
        transition:
          "transform 0.3s cubic-bezier(0.34, 1.56, 0.64, 1), box-shadow 0.3s ease",
        zIndex: activeState ? 2 : 1,
        animation: activeState ? "pulse 2s infinite ease-in-out" : "none",
        "@keyframes pulse": {
          "0%": { boxShadow: `0 8px 16px rgba(0,0,0,0.2)` },
          "50%": { boxShadow: `0 8px 20px rgba(0,0,0,0.3)` },
          "100%": { boxShadow: `0 8px 16px rgba(0,0,0,0.2)` },
        },
      }}
    >
      {/* Colored box containing the SVG */}
      <Box
        sx={{
          flex: 1,
          backgroundColor: bgColor || theme.palette.background.default,
          borderRadius: "3px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          mx: 2,
          my: 3,
          position: "relative",
          overflow: "hidden",
          border: "1px solid black",
          transition: "all 0.3s cubic-bezier(0.34, 1.56, 0.64, 1)",
          transform: activeState ? "scale(1.05)" : "scale(1)",
        }}
      >
        {/* SVG logo centered in the colored area */}
        <Box
          component="img"
          src={svgLogo}
          alt={`${title} logo`}
          sx={{
            width: "50%",
            maxHeight: "60%",
            objectFit: "contain",
            zIndex: 1,
          }}
        />
      </Box>

      {/* Title button at the bottom */}
      <Box sx={{ display: "flex", justifyContent: "center", width: "100%" }}>
        <Button
          onClick={() => !isLocked && onSelect(link, title)}
          variant="contained"
          disabled={isLocked}
          sx={{
            width: "80%",
            backgroundColor: activeState ? "#444444" : "#000000",
            color: "#ffffff",
            borderRadius: "25px",
            py: 1,
            mb: 2,
            textTransform: "none",
            fontWeight: "bold",
            boxShadow: activeState
              ? "0 4px 8px rgba(0,0,0,0.3)"
              : theme.shadows[1],
            "&:hover": {
              backgroundColor: activeState ? "#555555" : "#333333",
              transform: "translateY(-2px)",
            },
            "&.Mui-disabled": {
              backgroundColor: "#555555",
              color: "#aaaaaa",
            },
            transition: "all 0.3s cubic-bezier(0.34, 1.56, 0.64, 1)",
          }}
        >
          <Typography
            sx={{
              fontSize: { xs: "1rem", sm: "1.25rem" },
              fontWeight: 600,
              textAlign: "center",
              color: "#ffffff",
              width: "100%",
            }}
          >
            {title}
          </Typography>
        </Button>
      </Box>

      {/* Selection indicator */}
      {activeState && (
        <Box
          sx={{
            position: "absolute",
            bottom: "-12px",
            left: "50%",
            transform: "translateX(-50%)",
            width: 0,
            height: 0,
            borderLeft: "10px solid transparent",
            borderRight: "10px solid transparent",
            borderTop: "12px solid #444444",
            zIndex: 1,
          }}
        />
      )}

      {/* Lock overlay covering the entire component */}
      {isLocked && (
        <Box
          sx={{
            position: "absolute",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            zIndex: 10,
            borderRadius: "3px",
          }}
        >
          <Box
            component="img"
            src="/assets/icons/lock.svg"
            alt="Locked"
            sx={{
              width: "40px",
              height: "40px",
            }}
          />
        </Box>
      )}
    </Box>
  );
};

export default GameTileBaseSmall;
