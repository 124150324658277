// Custom event name for achievement updates
export const ACHIEVEMENT_UPDATED_EVENT = "wordameda_achievements_updated";

/**
 * Dispatch an event to notify components that achievements have been updated
 */
export const notifyAchievementsUpdated = (publicationId, newAchievements) => {
  try {
    // Create and dispatch a custom event
    const event = new CustomEvent(ACHIEVEMENT_UPDATED_EVENT, {
      detail: {
        publicationId,
        newAchievements,
      },
    });
    window.dispatchEvent(event);
  } catch (e) {
    console.error("Error dispatching achievement update event:", e);
  }
};
/**
 * Handle all achievement updates when a game ends
 * @param {Object} gameData Game completion data
 * @param {boolean} gameData.solved Whether the puzzle was solved
 * @param {number} gameData.attempts Number of attempts taken (guesses)
 * @param {number} gameData.maxAttempts Maximum allowed attempts
 * @param {boolean} gameData.isFirstSolver Whether this player was the first to solve
 * @param {string} gameData.theme Current theme (e.g., "Railroads")
 * @param {number} gameData.streakCount Current streak count after this game
 * @param {string} gameData.publicationId The publication ID for this game
 * @returns {Object} Newly unlocked achievements and updated stats
 */
export const handleAchievementsGameEnded = (gameData) => {
  try {
    const {
      solved,
      attempts,
      maxAttempts,
      isFirstSolver,
      theme,
      streakCount,
      publicationId,
    } = gameData;

    // Initialize achievements if needed
    initializeAchievements(publicationId);

    // Get the current state before updates
    const previousAchievements = getAchievements(publicationId);

    // Track newly unlocked achievements in this session
    const newlyUnlocked = [];

    // Only process achievements if puzzle was solved
    if (solved) {
      // 1. Check for first solver achievement
      if (isFirstSolver) {
        const firstSolverUnlocked = recordFirstSolver(publicationId);
        if (firstSolverUnlocked) {
          newlyUnlocked.push("first-solver");
        }
      }

      // 2. Record theme completion
      if (theme) {
        recordThemeCompletion(theme, publicationId);
        recordThemeWordSolved(theme, publicationId);

        // Add theme-specific achievements that were unlocked
        // This is handled internally by recordThemeCompletion, but we need to check
        // what was newly added to report back
        const themeSpecificIds = ACHIEVEMENTS.filter(
          (a) =>
            a.type === ACHIEVEMENT_TYPES.TIME_LIMITED &&
            a.icon === theme.toLowerCase()
        ).map((a) => a.id);

        const currentAchievements = getAchievements(publicationId);
        const newThemeAchievements = currentAchievements
          .filter(
            (a) =>
              themeSpecificIds.includes(a.id) &&
              !previousAchievements.some((pa) => pa.id === a.id)
          )
          .map((a) => a.id);

        newlyUnlocked.push(...newThemeAchievements);
      }

      // 3. Check streak achievements
      if (streakCount > 0) {
        checkStreakAchievements(streakCount, publicationId);

        // Find newly unlocked streak achievements
        const streakAchievementIds = ACHIEVEMENTS.filter(
          (a) => a.type === ACHIEVEMENT_TYPES.STREAK
        ).map((a) => a.id);

        const currentAchievements = getAchievements(publicationId);
        const newStreakAchievements = currentAchievements
          .filter(
            (a) =>
              streakAchievementIds.includes(a.id) &&
              !previousAchievements.some((pa) => pa.id === a.id)
          )
          .map((a) => a.id);

        newlyUnlocked.push(...newStreakAchievements);
      }

      // 4. Check skill achievements
      if (attempts > 0) {
        checkSkillAchievements(attempts, maxAttempts, publicationId);

        // Find newly unlocked skill achievements
        const skillAchievementIds = ACHIEVEMENTS.filter(
          (a) => a.type === ACHIEVEMENT_TYPES.SKILL
        ).map((a) => a.id);

        const currentAchievements = getAchievements(publicationId);
        const newSkillAchievements = currentAchievements
          .filter(
            (a) =>
              skillAchievementIds.includes(a.id) &&
              !previousAchievements.some((pa) => pa.id === a.id)
          )
          .map((a) => a.id);

        newlyUnlocked.push(...newSkillAchievements);
      }
    }

    // Get the final state
    const achievements = getAchievements(publicationId);
    const stats = getAchievementStats(publicationId);
    const themeStats = theme ? getThemeStats(theme, publicationId) : null;
    const streakProtection = getStreakProtection(publicationId);

    // Return newly unlocked achievements and updated stats
    const result = {
      newAchievements: newlyUnlocked,
      totalAchievements: achievements.length,
      stats,
      themeStats,
      streakProtection,
    };

    // Notify components about the achievement updates
    notifyAchievementsUpdated(publicationId, newlyUnlocked);

    return result;
  } catch (e) {
    console.error("Error handling game end achievements:", e);
    return {
      newAchievements: [],
      totalAchievements: 0,
      stats: {
        total: { unlocked: 0, total: 0, percentage: 0 },
        timeLimited: { unlocked: 0, total: 0, percentage: 0 },
        unique: { unlocked: 0, total: 0, percentage: 0 },
        streak: { unlocked: 0, total: 0, percentage: 0 },
        skill: { unlocked: 0, total: 0, percentage: 0 },
      },
      themeStats: null,
      streakProtection: { hasProtection: false, daysProtected: 0 },
    };
  }
}; // Utility functions for managing achievements using localStorage
const ACHIEVEMENTS_KEY = "wordyverse_achievements";
const NEW_ACHIEVEMENTS_KEY = "wordyverse_new_achievements";
const PLAYED_THEMES_KEY = "wordyverse_played_themes";
const TIMEZONE = "America/Los_Angeles"; // Pacific Time

// Achievement types
export const ACHIEVEMENT_TYPES = {
  TIME_LIMITED: "time-limited", // Available only during specific themes/events
  UNIQUE: "unique", // One-time or rare special achievements
  STREAK: "streak", // Based on continuous play
  SKILL: "skill", // Based on player performance
};

// List of all possible achievements with their definitions
export const ACHIEVEMENTS = [
  // Time-limited achievements
  {
    id: "first-solver",
    title: "First Solver",
    description: "You were the first person to solve today's puzzle!",
    type: ACHIEVEMENT_TYPES.UNIQUE,
    icon: "trophy",
  },
  {
    id: "train-week",
    title: "Train Enthusiast",
    description: "Successfully solved a puzzle during Railroads Week",
    type: ACHIEVEMENT_TYPES.TIME_LIMITED,
    icon: "train",
    startDate: "2025-03-02",
    endDate: "2025-03-08",
    publicationIds: ["AdXbHK5DNgwyBWpckJRkRF", "ZqUdNcP7DWb2WexYu7GDRd"],
  },
  {
    id: "waterfront-week",
    title: "Harbor Master",
    description: "Successfully solved a puzzle during Waterfront Features Week",
    type: ACHIEVEMENT_TYPES.TIME_LIMITED,
    icon: "waterfront",
    startDate: "2025-03-09",
    endDate: "2025-03-15",
    publicationIds: ["AdXbHK5DNgwyBWpckJRkRF", "ZqUdNcP7DWb2WexYu7GDRd"],
  },
  // Add more themed achievements as needed

  // Streak achievements
  {
    id: "streak-3",
    title: "3-Day Streak",
    description: "Solved puzzles for 3 consecutive days",
    type: ACHIEVEMENT_TYPES.STREAK,
    icon: "fire",
    threshold: 3,
  },
  {
    id: "streak-5",
    title: "5-Day Streak",
    description: "Solved puzzles for 5 consecutive days",
    type: ACHIEVEMENT_TYPES.STREAK,
    icon: "fire",
    threshold: 5,
  },
  {
    id: "streak-7",
    title: "Week Warrior",
    description: "Solved puzzles for 7 consecutive days",
    type: ACHIEVEMENT_TYPES.STREAK,
    icon: "fire",
    threshold: 7,
  },
  {
    id: "streak-30",
    title: "Monthly Master",
    description: "Solved puzzles for 30 consecutive days",
    type: ACHIEVEMENT_TYPES.STREAK,
    icon: "fire",
    threshold: 30,
  },

  // Skill achievements
  {
    id: "perfect-solve",
    title: "Perfect Solve",
    description: "Solved a puzzle on the first try",
    type: ACHIEVEMENT_TYPES.SKILL,
    icon: "medal",
  },
  {
    id: "master-solver",
    title: "Master Solver",
    description: "Solved a puzzle in 3 or fewer tries",
    type: ACHIEVEMENT_TYPES.SKILL,
    icon: "medal",
    maxGuesses: 3,
  },
];

/**
 * Get storage keys with optional publication ID
 */
export const getAchievementsKey = (publicationId) => {
  if (!publicationId) {
    return ACHIEVEMENTS_KEY;
  }
  return `${ACHIEVEMENTS_KEY}-${publicationId}`;
};

export const getNewAchievementsKey = (publicationId) => {
  if (!publicationId) {
    return NEW_ACHIEVEMENTS_KEY;
  }
  return `${NEW_ACHIEVEMENTS_KEY}-${publicationId}`;
};

export const getPlayedThemesKey = (publicationId) => {
  if (!publicationId) {
    return PLAYED_THEMES_KEY;
  }
  return `${PLAYED_THEMES_KEY}-${publicationId}`;
};

/**
 * Get the current date in Pacific Time as a formatted string
 */
const getTodayInPTFormatted = () => {
  const now = new Date();
  return now.toLocaleDateString("en-US", {
    timeZone: TIMEZONE,
    year: "numeric",
    month: "short",
    day: "numeric",
  });
};

/**
 * Get all achievements from localStorage
 */
export const getAchievements = (publicationId) => {
  try {
    const achievementsKey = getAchievementsKey(publicationId);
    const achievementsJson = localStorage.getItem(achievementsKey);

    if (!achievementsJson) {
      return [];
    }

    return JSON.parse(achievementsJson);
  } catch (e) {
    console.error("Error reading achievements from storage:", e);
    return [];
  }
};

/**
 * Get newly unlocked achievements from localStorage
 */
export const getNewAchievements = (publicationId) => {
  try {
    const newAchievementsKey = getNewAchievementsKey(publicationId);
    const newAchievementsJson = localStorage.getItem(newAchievementsKey);

    if (!newAchievementsJson) {
      return [];
    }

    return JSON.parse(newAchievementsJson);
  } catch (e) {
    console.error("Error reading new achievements from storage:", e);
    return [];
  }
};

/**
 * Save achievements to localStorage
 */
export const saveAchievements = (achievements, publicationId) => {
  try {
    const achievementsKey = getAchievementsKey(publicationId);
    localStorage.setItem(achievementsKey, JSON.stringify(achievements));
  } catch (e) {
    console.error("Error saving achievements to storage:", e);
  }
};

/**
 * Save newly unlocked achievements to localStorage
 */
export const saveNewAchievements = (newAchievements, publicationId) => {
  try {
    const newAchievementsKey = getNewAchievementsKey(publicationId);
    localStorage.setItem(newAchievementsKey, JSON.stringify(newAchievements));
  } catch (e) {
    console.error("Error saving new achievements to storage:", e);
  }
};

/**
 * Clear new achievements after user has viewed them
 */
export const clearNewAchievements = (publicationId) => {
  try {
    const newAchievementsKey = getNewAchievementsKey(publicationId);
    localStorage.removeItem(newAchievementsKey);

    // Notify components that achievements have been cleared
    notifyAchievementsUpdated(publicationId, []);
  } catch (e) {
    console.error("Error clearing new achievements from storage:", e);
  }
};

/**
 * Add a newly unlocked achievement
 */
export const unlockAchievement = (achievementId, publicationId) => {
  try {
    // Get current achievements
    const achievements = getAchievements(publicationId);
    const newAchievements = getNewAchievements(publicationId);

    // Find achievement definition
    const achievementDef = ACHIEVEMENTS.find((a) => a.id === achievementId);
    if (!achievementDef) return false;

    // Check if already unlocked
    if (achievements.some((a) => a.id === achievementId)) {
      return false; // Already unlocked
    }

    // Check if achievement is limited to specific publications
    if (
      achievementDef.publicationIds &&
      achievementDef.publicationIds.length > 0
    ) {
      if (
        !publicationId ||
        !achievementDef.publicationIds.includes(publicationId)
      ) {
        return false; // Not applicable to this publication
      }
    }

    // Create new achievement record
    const newAchievement = {
      ...achievementDef,
      dateUnlocked: getTodayInPTFormatted(),
      locked: false,
    };

    // Add to achievements and newly unlocked
    achievements.push(newAchievement);
    newAchievements.push(achievementId);

    // Save to storage
    saveAchievements(achievements, publicationId);
    saveNewAchievements(newAchievements, publicationId);

    return true; // Successfully unlocked
  } catch (e) {
    console.error("Error unlocking achievement:", e);
    return false;
  }
};

/**
 * Record theme completion for theme-based achievements
 */
export const recordThemeCompletion = (theme, publicationId) => {
  try {
    const playedThemesKey = getPlayedThemesKey(publicationId);
    let playedThemes = {};

    // Get current played themes
    const playedThemesJson = localStorage.getItem(playedThemesKey);
    if (playedThemesJson) {
      playedThemes = JSON.parse(playedThemesJson);
    }

    // Add theme to played themes
    playedThemes[theme.toLowerCase()] = true;

    // Save to storage
    localStorage.setItem(playedThemesKey, JSON.stringify(playedThemes));

    // Check for specific theme achievements
    if (theme.toLowerCase() === "railroads") {
      // Only unlock if the achievement is currently active and applicable to this publication
      if (isAchievementActive("train-week", publicationId)) {
        unlockAchievement("train-week", publicationId);
      }
    }

    if (theme.toLowerCase() == "waterfront features") {
      if (isAchievementActive("waterfront-week", publicationId)) {
        unlockAchievement("waterfront-week", publicationId);
      }
    }
    // Add more theme checks as needed

    return true;
  } catch (e) {
    console.error("Error recording theme completion:", e);
    return false;
  }
};

/**
 * Check for streak-based achievements
 */
export const checkStreakAchievements = (streakCount, publicationId) => {
  if (!streakCount || streakCount < 3) return false;

  try {
    let unlocked = false;

    // Check each streak achievement
    const streakAchievements = ACHIEVEMENTS.filter(
      (a) => a.type === ACHIEVEMENT_TYPES.STREAK && a.threshold <= streakCount
    );

    streakAchievements.forEach((achievement) => {
      const achievementUnlocked = unlockAchievement(
        achievement.id,
        publicationId
      );
      if (achievementUnlocked) unlocked = true;
    });

    return unlocked;
  } catch (e) {
    console.error("Error checking streak achievements:", e);
    return false;
  }
};

/**
 * Check for skill-based achievements
 */
export const checkSkillAchievements = (
  guessCount,
  maxGuesses,
  publicationId
) => {
  try {
    let unlocked = false;

    // Perfect solve (first attempt)
    if (guessCount === 1) {
      const achievementUnlocked = unlockAchievement(
        "perfect-solve",
        publicationId
      );
      if (achievementUnlocked) unlocked = true;
    }

    // Master solver (3 or fewer attempts)
    if (guessCount <= 3) {
      const achievementUnlocked = unlockAchievement(
        "master-solver",
        publicationId
      );
      if (achievementUnlocked) unlocked = true;
    }

    return unlocked;
  } catch (e) {
    console.error("Error checking skill achievements:", e);
    return false;
  }
};

/**
 * Record first solver achievement
 */
export const recordFirstSolver = (publicationId) => {
  return unlockAchievement("first-solver", publicationId);
};

/**
 * Check if a time-limited achievement is currently active and applicable for a publication
 */
export const isAchievementActive = (achievementId, publicationId) => {
  try {
    const achievement = ACHIEVEMENTS.find((a) => a.id === achievementId);
    if (!achievement) return false;

    // Only time-limited achievements have activity windows
    if (achievement.type !== ACHIEVEMENT_TYPES.TIME_LIMITED) return true;

    // Check if achievement is limited to specific publications
    if (achievement.publicationIds && achievement.publicationIds.length > 0) {
      if (
        !publicationId ||
        !achievement.publicationIds.includes(publicationId)
      ) {
        return false; // Not applicable to this publication
      }
    }

    // If no dates are specified, assume it's always active
    if (!achievement.startDate || !achievement.endDate) return true;

    const now = new Date();
    const startDate = new Date(achievement.startDate);
    const endDate = new Date(achievement.endDate);

    // Set end date to end of day
    endDate.setHours(23, 59, 59, 999);

    return now >= startDate && now <= endDate;
  } catch (e) {
    console.error("Error checking if achievement is active:", e);
    return false;
  }
};

/**
 * Get all achievements that are currently active or already unlocked, with unlocked achievements first
 */
export const getAvailableAchievements = (publicationId) => {
  try {
    const unlockedAchievements = getAchievements(publicationId);
    const unlockedIds = unlockedAchievements.map((a) => a.id);

    // Filter achievements that are either:
    // 1. Already unlocked, or
    // 2. Not time-limited, or
    // 3. Time-limited but currently active and applicable to this publication
    const filteredAchievements = ACHIEVEMENTS.filter((achievement) => {
      if (unlockedIds.includes(achievement.id)) {
        return true; // Already unlocked
      }

      // Check if achievement is limited to specific publications
      if (achievement.publicationIds && achievement.publicationIds.length > 0) {
        if (
          !publicationId ||
          !achievement.publicationIds.includes(publicationId)
        ) {
          return false; // Not applicable to this publication
        }
      }

      if (achievement.type !== ACHIEVEMENT_TYPES.TIME_LIMITED) {
        return true; // Not time-limited, so always available
      }

      return isAchievementActive(achievement.id, publicationId); // Check if time-limited achievement is active
    }).map((achievement) => {
      const unlockedAchievement = unlockedAchievements.find(
        (a) => a.id === achievement.id
      );
      if (unlockedAchievement) {
        return {
          ...unlockedAchievement,
          unlocked: true, // Explicitly mark as unlocked
        };
      }

      // If not unlocked, include expiration info for time-limited achievements
      if (
        achievement.type === ACHIEVEMENT_TYPES.TIME_LIMITED &&
        achievement.endDate
      ) {
        const endDate = new Date(achievement.endDate);
        const now = new Date();

        // Calculate days remaining
        const diffTime = endDate.getTime() - now.getTime();
        const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

        return {
          ...achievement,
          locked: true,
          unlocked: false,
          dateUnlocked: null,
          expiresIn:
            diffDays > 0 ? `${diffDays} day${diffDays !== 1 ? "s" : ""}` : null,
        };
      }

      return {
        ...achievement,
        locked: true,
        unlocked: false,
        dateUnlocked: null,
      };
    });

    // Sort achievements: unlocked first, then by type and ID
    return filteredAchievements.sort((a, b) => {
      // First sort by locked/unlocked status
      if (!a.locked && b.locked) return -1;
      if (a.locked && !b.locked) return 1;

      // If same lock status, sort by type
      if (a.type !== b.type) {
        const typeOrder = {
          [ACHIEVEMENT_TYPES.UNIQUE]: 1,
          [ACHIEVEMENT_TYPES.TIME_LIMITED]: 2,
          [ACHIEVEMENT_TYPES.STREAK]: 3,
          [ACHIEVEMENT_TYPES.SKILL]: 4,
        };
        return typeOrder[a.type] - typeOrder[b.type];
      }

      // If same type, retain original order (by ID)
      const aIndex = ACHIEVEMENTS.findIndex((ach) => ach.id === a.id);
      const bIndex = ACHIEVEMENTS.findIndex((ach) => ach.id === b.id);
      return aIndex - bIndex;
    });
  } catch (e) {
    console.error("Error getting available achievements:", e);
    return [];
  }
};

/**
 * Calculate progress for various achievement collections
 */
export const getAchievementStats = (publicationId) => {
  try {
    const achievements = getAchievements(publicationId);

    // Calculate total progress stats
    const totalAchievements = ACHIEVEMENTS.length;
    const unlockedCount = achievements.length;

    // Calculate by type
    const timeLimitedTotal = ACHIEVEMENTS.filter(
      (a) => a.type === ACHIEVEMENT_TYPES.TIME_LIMITED
    ).length;
    const timeLimitedUnlocked = achievements.filter(
      (a) => a.type === ACHIEVEMENT_TYPES.TIME_LIMITED
    ).length;

    const uniqueTotal = ACHIEVEMENTS.filter(
      (a) => a.type === ACHIEVEMENT_TYPES.UNIQUE
    ).length;
    const uniqueUnlocked = achievements.filter(
      (a) => a.type === ACHIEVEMENT_TYPES.UNIQUE
    ).length;

    const streakTotal = ACHIEVEMENTS.filter(
      (a) => a.type === ACHIEVEMENT_TYPES.STREAK
    ).length;
    const streakUnlocked = achievements.filter(
      (a) => a.type === ACHIEVEMENT_TYPES.STREAK
    ).length;

    const skillTotal = ACHIEVEMENTS.filter(
      (a) => a.type === ACHIEVEMENT_TYPES.SKILL
    ).length;
    const skillUnlocked = achievements.filter(
      (a) => a.type === ACHIEVEMENT_TYPES.SKILL
    ).length;

    return {
      total: {
        unlocked: unlockedCount,
        total: totalAchievements,
        percentage: Math.round((unlockedCount / totalAchievements) * 100),
      },
      timeLimited: {
        unlocked: timeLimitedUnlocked,
        total: timeLimitedTotal,
        percentage: Math.round((timeLimitedUnlocked / timeLimitedTotal) * 100),
      },
      unique: {
        unlocked: uniqueUnlocked,
        total: uniqueTotal,
        percentage: Math.round((uniqueUnlocked / uniqueTotal) * 100),
      },
      streak: {
        unlocked: streakUnlocked,
        total: streakTotal,
        percentage: Math.round((streakUnlocked / streakTotal) * 100),
      },
      skill: {
        unlocked: skillUnlocked,
        total: skillTotal,
        percentage: Math.round((skillUnlocked / skillTotal) * 100),
      },
    };
  } catch (e) {
    console.error("Error calculating achievement stats:", e);
    return {
      total: { unlocked: 0, total: 0, percentage: 0 },
      timeLimited: { unlocked: 0, total: 0, percentage: 0 },
      unique: { unlocked: 0, total: 0, percentage: 0 },
      streak: { unlocked: 0, total: 0, percentage: 0 },
      skill: { unlocked: 0, total: 0, percentage: 0 },
    };
  }
};

/**
 * Check if player has any achievement notifications to view
 */
export const hasNewAchievements = (publicationId) => {
  const newAchievements = getNewAchievements(publicationId);
  return newAchievements.length > 0;
};

/**
 * Check if player has unlocked a specific achievement
 */
export const hasAchievement = (achievementId, publicationId) => {
  const achievements = getAchievements(publicationId);
  return achievements.some((a) => a.id === achievementId);
};

/**
 * Calculate streak protection status
 * This is a mock implementation assuming we might add streak protection features later
 */
export const getStreakProtection = (publicationId) => {
  // For now, this is a simple implementation
  // In the future, this could check if the user has earned or purchased streak protection
  return {
    hasProtection: false,
    daysProtected: 0,
  };
  return {
    hasProtection: hasAchievement("streak-7", publicationId), // Week Warrior gives streak protection
    daysProtected: 1, // Number of days the streak is protected
  };
};

/**
 * Get theme completion stats
 */
export const getThemeStats = (currentTheme, publicationId) => {
  try {
    // Mock implementation - in a real app, this would calculate actual progress
    // Simulating solved words in theme data
    const solvedWordsInTheme = localStorage.getItem(
      `${currentTheme.toLowerCase()}_solved_count`
    );
    const totalWordsInTheme = 50; // This would be from a config or API

    return {
      theme: currentTheme,
      solved: parseInt(solvedWordsInTheme || "0", 10),
      total: totalWordsInTheme,
      percentage: Math.round(
        (parseInt(solvedWordsInTheme || "0", 10) / totalWordsInTheme) * 100
      ),
    };
  } catch (e) {
    console.error("Error getting theme stats:", e);
    return {
      theme: currentTheme,
      solved: 0,
      total: 50,
      percentage: 0,
    };
  }
};

/**
 * Record word solved for theme progress
 */
export const recordThemeWordSolved = (theme, publicationId) => {
  try {
    const themeKey = `${theme.toLowerCase()}_solved_count`;
    const currentCount = parseInt(localStorage.getItem(themeKey) || "0", 10);
    localStorage.setItem(themeKey, (currentCount + 1).toString());

    // Also record theme completion for achievements
    recordThemeCompletion(theme, publicationId);

    return currentCount + 1;
  } catch (e) {
    console.error("Error recording theme word solved:", e);
    return 0;
  }
};

/**
 * Initialize player achievements if needed
 */
export const initializeAchievements = (publicationId) => {
  try {
    const achievementsKey = getAchievementsKey(publicationId);
    const achievementsJson = localStorage.getItem(achievementsKey);

    if (!achievementsJson) {
      // No achievements found, initialize with empty array
      saveAchievements([], publicationId);
    }

    return true;
  } catch (e) {
    console.error("Error initializing achievements:", e);
    return false;
  }
};
