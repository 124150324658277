import React, { useState, useEffect } from "react";
import { Modal, Box, Typography, Button, IconButton } from "@mui/material";
import { X } from "lucide-react";
import { motion } from "framer-motion";
import { logFeedbackGiven } from "../analytics";

const SurveyModal = ({
  open,
  onClose,
  gameTitle = "",
  publicationId = null,
}) => {
  // Check if we should show the modal based on date and publication
  const shouldShowModal = () => {
    // Only show for Alameda Post publication
    if (publicationId !== "ZqUdNcP7DWb2WexYu7GDRd") {
      return false;
    }

    const currentDate = new Date();
    const endDate = new Date("2025-03-30T23:59:59");

    // Only check if we're before the end date
    // We're removing the "previously dismissed" check to make it show every time
    return currentDate <= endDate;
  };

  // Handle click on feedback button
  const handleShareFeedback = () => {
    // Log analytics event
    logFeedbackGiven(gameTitle, true, publicationId, {
      feedbackSource: "popup_survey",
    });

    // Open the feedback form in a new tab/window
    window.open("https://tally.so/r/wbzLOE", "_blank");
    onClose();
  };

  // Don't render if we're past the end date or not the right publication
  if (!shouldShowModal()) {
    return null;
  }

  return (
    <Modal open={open} onClose={onClose} aria-labelledby="feedback-modal-title">
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: "90%",
          maxWidth: "400px",
          bgcolor: "#1a237e", // Dark blue background color
          color: "white",
          borderRadius: "8px",
          py: 3,
          px: 6,
          outline: "none",
          textAlign: "center",
          boxShadow: "0 10px 25px rgba(0, 0, 0, 0.3)",
        }}
      >
        {/* Close button */}
        <IconButton
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: "rgba(255, 255, 255, 0.7)",
            "&:hover": {
              color: "white",
            },
          }}
        >
          <X size={20} />
        </IconButton>

        {/* Title and Content */}
        <Typography
          id="feedback-modal-title"
          variant="h5"
          component="h2"
          sx={{
            fontWeight: "bold",
            mb: 3,
            mt: 1,
            textAlign: "center",
            lineHeight: 1.3,
          }}
        >
          Your feedback makes Wordameda better!
        </Typography>

        <Typography
          variant="body1"
          sx={{
            mb: 4,
            textAlign: "center",
            fontSize: "1.05rem",
          }}
        >
          Help us by answering three quick questions.
        </Typography>

        {/* Action Buttons */}
        <motion.div whileHover={{ scale: 1.03 }} whileTap={{ scale: 0.97 }}>
          <Button
            variant="contained"
            fullWidth
            onClick={handleShareFeedback}
            sx={{
              bgcolor: "#e53935", // Red color for main action button
              color: "white",
              py: 1,
              mb: 2,
              fontWeight: "bold",
              "&:hover": {
                bgcolor: "#d32f2f",
              },
              textTransform: "uppercase",
              borderRadius: "20px",
              border: "1px solid white",
            }}
          >
            GIVE FEEDBACK
          </Button>
        </motion.div>

        <Button
          variant="text"
          onClick={onClose}
          sx={{
            color: "rgba(255, 255, 255, 0.7)",
            "&:hover": {
              bgcolor: "transparent",
              color: "white",
            },
            textDecoration: "underline",
            textTransform: "none",
          }}
        >
          Skip for now
        </Button>
      </Box>
    </Modal>
  );
};

export default SurveyModal;
