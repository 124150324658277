import { PropaneTankRounded } from "@mui/icons-material";
import mixpanel from "mixpanel-browser";

let isInitialized = false;
let isOptedOut = false;

const MIXPANEL_PROJECT_TOKEN = process.env.REACT_APP_MIXPANEL_PROJECT_TOKEN;

export const initializeAnalytics = () => {
  if (!MIXPANEL_PROJECT_TOKEN) {
    console.warn("Mixpanel token not found. Analytics will not be tracked.");
    return;
  }

  try {
    // Always initialize Mixpanel, but with tracking disabled by default
    mixpanel.init(MIXPANEL_PROJECT_TOKEN, {
      debug: process.env.NODE_ENV !== "production",
      track_pageview: true,
      persistence: "localStorage",
      opt_out_tracking_by_default: false, // Changed to false since we'll manage this manually
      opt_out_persistence_by_default: false, // Allow persistence
    });

    isInitialized = true;

    // Check if user has already given consent
    const hasConsent =
      localStorage.getItem("analytics_cookie_consent") === "true";

    if (hasConsent) {
      mixpanel.opt_in_tracking();
      isOptedOut = false;
    } else {
      mixpanel.opt_out_tracking();
      isOptedOut = true;
    }

    if (hasConsent) {
      // Track initialization only if we have consent
      safeTrack("Analytics Initialized", {
        timestamp: new Date().toISOString(),
        consentStatus: "accepted",
      });
    }
  } catch (error) {
    console.error("Failed to initialize Mixpanel:", error);
  }
};

export const enableAnalytics = () => {
  if (!isInitialized) {
    initializeAnalytics();
  }

  mixpanel.opt_in_tracking();
  isOptedOut = false;
};

export const disableAnalytics = () => {
  if (!isInitialized) return;

  try {
    mixpanel.opt_out_tracking();
    isOptedOut = true;

    // Clear any existing super properties
    mixpanel.reset();
  } catch (error) {
    console.error("Failed to disable analytics:", error);
  }
};

// Safe tracking wrapper that respects opt-out status
const safeTrack = (eventName, properties = {}) => {
  if (!isInitialized || isOptedOut) {
    if (process.env.NODE_ENV !== "production") {
      console.warn(
        `Analytics event not tracked (${eventName}): Analytics ${
          !isInitialized ? "not initialized" : "opted out"
        }`
      );
    }
    return;
  }

  try {
    mixpanel.track(eventName, {
      time: Date.now(),
      ...properties,
    });
  } catch (error) {
    console.error(`Failed to track event ${eventName}:`, error);
  }
};

// Customers are required to allow 3rd party cookies in order to add our game,
// in this scenario we can force analytics without using our cookie banner
export const forceEnableForEmbed = (publicationId) => {
  if (!isInitialized) {
    mixpanel.init(MIXPANEL_PROJECT_TOKEN, {
      debug: process.env.NODE_ENV !== "production",
      persistence: "localStorage",
      opt_out_tracking_by_default: false,
      opt_out_persistence_by_default: false,
    });
    isInitialized = true;
  }

  // Check if they're currently opted out
  const wasOptedOut = isOptedOut;

  // Opt in for tracking
  mixpanel.opt_in_tracking();
  isOptedOut = false;

  // Track page view with context about opt-in status
  mixpanel.track("Page View", {
    page: window.location.pathname,
    embedded: true,
    publication_id: publicationId,
    previously_opted_out: wasOptedOut,
  });
};

// Update existing tracking functions to use safeTrack
export const logEvent = (eventName, publicationId, properties = {}) => {
  if (publicationId) {
    properties["publication_id"] = publicationId;
  }

  safeTrack(eventName, properties);
};

export const logFeedbackGiven = (
  gameTitle,
  feedback,
  publicationId,
  extras = {}
) => {
  extras["title"] = gameTitle;
  extras["is_positive_rating"] = feedback;

  logEvent("Game rated", publicationId, extras);
};

export const logGameStarted = (gameTitle, publicationId, extras = {}) => {
  extras["title"] = gameTitle;

  logEvent("Game started", publicationId, extras);
};

export const logGameEnded = (gameTitle, publicationId, extras = {}) => {
  extras["title"] = gameTitle;

  logEvent("Game ended", publicationId, extras);
};

export const logGuessMade = (gameTitle, publicationId, extras = {}) => {
  extras["title"] = gameTitle;

  logEvent("Guess made", publicationId, extras);
};

export const logAbandonedGame = (gameTitle, publicationId, extras = {}) => {
  extras["title"] = gameTitle;

  logEvent("Game Abandoned", publicationId, extras);
};

export const logGameShared = (gameTitle, publicationId, extras = {}) => {
  extras["title"] = gameTitle;

  logEvent("Game shared", publicationId, extras);
};

export const logUserLogin = (userId, properties = {}) => {
  if (!isInitialized || isOptedOut) return;

  try {
    mixpanel.identify(userId);

    mixpanel.people.set({
      $userId: userId,
      $last_login: new Date(),
      ...properties,
    });

    safeTrack("User Login", {
      userId,
      loginTime: new Date().toISOString(),
      ...properties,
    });
  } catch (error) {
    console.error("Failed to log user login:", error);
  }
};

export const identifyUser = (userId, properties = {}) => {
  if (!isInitialized || isOptedOut || !userId) return;

  try {
    mixpanel.identify(userId);

    mixpanel.people.set({
      $userId: userId,
      $last_seen: new Date(),
      ...properties,
    });
  } catch (error) {
    console.error("Failed to identify user:", error);
  }
};

// Add consent status check to all tracking functions
export const isTrackingEnabled = () => {
  return isInitialized && !isOptedOut;
};
