import Home from "./components/Home";
import TermsOfUse from "./components/TermsOfService";
import PrivacyPolicy from "./components/PrivacyPolicy";
import AboutUs from "./components/About";
import AuthenticatePage from "./AuthenticatePage";
import Auth from "./Auth";

// Tools
import DemoCreator from "./tools/DemoCreator";
import DemoPreviewRoute from "./tools/DemoCreator/DemoPreviewRoute";

// Games
import ColorMatcher from "./games/ColorMatcher";
import DigitShift from "./games/DigitShift";
import WordWizard from "./games/WordWizard";
import ShapeSorter from "./games/ShapeSorter";
import ColorFlood from "./games/ColorFlood";
import MemoryMaze from "./games/MemoryMaze";
import AvoidBlocks from "./games/AvoidBlocks";
import EmojiQuest from "./games/EmojiQuest";
import ColorDash from "./games/ColorDash";
import StartupSpeedrunSimulator from "./games/StartupSpeedrunSimulator";
import WhackAMole from "./games/WhackAMole";
import GardenPuzzleGame from "./games/GardenPuzzle";
import TwitterStrands from "./games/TwitterStrands/TwitterStrands";
import Tempus from "./games/Tempus/Tempus";
import PlusMinus from "./games/PlusMinus";
import RainCounter from "./games/RainCounter";
import SeattleStack from "./games/SeattleStack";
import WeatherWiz from "./games/WeatherWiz/WeatherWiz";
import Keyword from "./games/Keyword/Keyword";
import KeywordDemoEmbed from "./games/Keyword/KeywordDemoEmbed";

// WordyVerse Topics
import SeattleWordy from "./games/WordyVerse/Topics/Seattle/Seattle";

// Trivia Games
import TriviaRoundup from "./games/TriviaRoundup/TriviaRoundup";
import SeattleTrivia from "./games/TriviaRoundup/Topics/Seattle/SeattleTrivia";
import SanFranciscoTrivia from "./games/TriviaRoundup/Topics/SanFrancisco/SanFranciscoTrivia";
import NewJerseyTrivia from "./games/TriviaRoundup/Topics/NewJersey/NewJerseyTrivia";
import SFStandardDemo from "./games/TriviaRoundup/Topics/SFStandardDemo/SFStandardDemo";

// Crossword & Stacking Games
import SeattleCrosswordy from "./games/CrossWordy/CrossWordy";
import SeattleHome from "./cities/Seattle/Seattle";
import QuickQuackSeattle from "./cities/Seattle/QuickQuackSeattle";
import SeattleCoffeeStack from "./games/SeattleStack";

// Demos & Embeds
import SeattleTimesDemoEmbed from "./games/WordyVerse/Demos/SeattleTimesDemoEmbed";
import AlamedaPostDemoEmbed from "./games/WordyVerse/Demos/AlamedaPostDemoEmbed";
import WordyGameEmbed from "./fcg/wordy/WordyGameEmbed";
import OaklandsideDemoEmbed from "./games/WordyVerse/Demos/CitysideDemoEmbed";
import ColoradoSunDemoEmbed from "./games/WordyVerse/Demos/ColoradoSunDemoEmbed";
import JocoKeywordDemo from "./games/WordyVerse/Demos/JocoKeywordDemo";
import JocoWordyDemo from "./games/WordyVerse/Demos/JocoWordyDemo";

export const routes = [
  { path: "/", element: <Home /> },
  { path: "/terms", element: <TermsOfUse /> },
  { path: "/privacy", element: <PrivacyPolicy /> },
  { path: "/about", element: <AboutUs /> },
  { path: "/authenticate", element: <AuthenticatePage /> },
  { path: "/wordy-verse/auth", element: <Auth /> },

  // Embedded Games
  { path: "/wordy-verse/seattle", element: <SeattleWordy /> },

  // WordyVerse
  { path: "/wordy-verse/seattle", element: <SeattleWordy /> },
  { path: "/fcg/wordy", element: <WordyGameEmbed /> },

  // Tools
  { path: "/tools/demo-creator", element: <DemoCreator /> },
  { path: "/demo-preview", element: <DemoPreviewRoute /> },

  // Demos
  { path: "/play", element: <SeattleHome /> },
  { path: "/seattle", element: <SeattleHome /> },
  { path: "/seattle-times-demo", element: <SeattleTimesDemoEmbed /> },
  { path: "/alameda-post-demo", element: <AlamedaPostDemoEmbed /> },
  { path: "/oaklandside-demo", element: <KeywordDemoEmbed /> },
  { path: "/colorado-sun", element: <ColoradoSunDemoEmbed /> },
  { path: "/joco-keyword-demo", element: <JocoKeywordDemo /> },
  { path: "/joco-wordy-demo", element: <JocoWordyDemo /> },
  {
    path: "/trivia-roundup/sf-standard-demo",
    element: <SFStandardDemo />,
  },

  // Games
  { path: "/color-matcher", element: <ColorMatcher /> },
  { path: "/digit-shift", element: <DigitShift /> },
  { path: "/word-wizard", element: <WordWizard /> },
  { path: "/shape-sorter", element: <ShapeSorter /> },
  { path: "/color-flood", element: <ColorFlood /> },
  { path: "/memory-maze", element: <MemoryMaze /> },
  { path: "/raining-blocks", element: <AvoidBlocks /> },
  { path: "/cowboy-quest", element: <EmojiQuest /> },
  { path: "/color-dash", element: <ColorDash /> },
  { path: "/startup-speedrun-sim", element: <StartupSpeedrunSimulator /> },
  { path: "/whack-a-mole", element: <WhackAMole /> },
  { path: "/garden-puzzle", element: <GardenPuzzleGame /> },
  { path: "/my-strands", element: <TwitterStrands /> },
  { path: "/tempus", element: <Tempus /> },
  { path: "/plusminus", element: <PlusMinus /> },
  { path: "/seattle/raincounter", element: <RainCounter /> },
  { path: "/seattle/stack", element: <SeattleStack /> },
  { path: "/weatherwiz", element: <WeatherWiz /> },
  { path: "/seattle/quick-quack", element: <QuickQuackSeattle /> },
  { path: "/seattle/stack", element: <SeattleCoffeeStack /> },
  { path: "/crosswordy", element: <SeattleCrosswordy /> },
  { path: "/trivia-roundup", element: <TriviaRoundup /> },
  { path: "/trivia-roundup/seattle", element: <SeattleTrivia /> },
  { path: "/trivia-roundup/san-francisco", element: <SanFranciscoTrivia /> },
  { path: "/trivia-roundup/new-jersey", element: <NewJerseyTrivia /> },
];
