import React, { useState, useEffect } from "react";
import BaseWordyGame from "../../games/WordyVerse/Components/BaseWordyGame";

const DemoWordyPreview = ({ config }) => {
  const [wordData, setWordData] = useState(null);

  // Function to always return today's date
  const findLatestAvailableDate = () => {
    const today = new Date();
    return {
      date: today.toISOString().split("T")[0], // Format as YYYY-MM-DD
      word: config.wordyWord,
    };
  };

  // Function to always return the same word data regardless of date
  const getWordForDate = (date) => {
    return {
      word: config.wordyWord,
      hint: config.wordyHint,
      date: date ? date : new Date().toISOString().split("T")[0],
      has_been_solved: false,
    };
  };

  useEffect(() => {
    // Get data from our findLatestAvailableDate function
    const latestWordData = findLatestAvailableDate();
    setWordData(latestWordData);
  }, [config.wordyWord]);

  if (!wordData) return null;

  // Derive header image or SVG
  const headerImage = config.headerImageLink || config.headerSvg;

  return (
    <BaseWordyGame
      title={config.publicationName}
      customTitle={config.wordyTitle}
      shareText={`${config.publicationName} Wordy`}
      shareUrl={window.location.href}
      getWordForDate={getWordForDate}
      findLatestAvailableDate={findLatestAvailableDate}
      successBackgroundColor={config.highlightColor}
      keyboardBackgroundColor="#d3d6da"
      shareEmojiCorrect="🟦"
      shareEmojiPresent="🟨"
      shareEmojiAbsent="⬜️"
      isEmbedded={true}
    />
  );
};

export default DemoWordyPreview;
