import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { Link } from "@mui/material";
import Grid from "@mui/material/Grid";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

const WhyGames = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <Box
      sx={{
        maxWidth: "100%",
        overflow: "hidden",
      }}
    >
      {/* Section Title */}
      <Typography
        variant="h2"
        component="h2"
        sx={{
          fontWeight: 800,
          fontSize: { xs: "2rem", sm: "2.5rem", md: "3rem" },
          mb: { xs: 3, md: 4 },
          textAlign: "center",
        }}
      >
        Why games?
      </Typography>

      {/* Mobile view */}
      {isMobile ? (
        <Box sx={{ display: "flex", flexDirection: "column", px: 2 }}>
          {/* Mobile Stats Section */}
          <Box
            sx={{
              width: "100%",
              borderTop: "1px solid #000",
              pt: 2,
              pb: 2,
            }}
          >
            <Typography
              variant="h4"
              component="h3"
              sx={{
                fontWeight: 700,
                fontSize: "1.5rem",
                mb: 2,
              }}
            >
              By The Numbers
            </Typography>

            {/* 53% increase stat */}
            <Box sx={{ mb: 3 }}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Box sx={{ width: "55%" }}>
                  <Typography
                    variant="body1"
                    sx={{
                      fontSize: "1rem",
                      fontWeight: 700,
                      lineHeight: 1.4,
                    }}
                  >
                    53% increase in session duration
                  </Typography>
                  <Typography
                    variant="body1"
                    sx={{
                      fontSize: "1rem",
                      lineHeight: 1.4,
                    }}
                  >
                    with interactive content
                  </Typography>
                </Box>

                {/* Bar chart for session duration */}
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "flex-end",
                    mt: 2,
                    mb: 1,
                    width: "40%",
                    justifyContent: "center",
                  }}
                >
                  <Box
                    sx={{
                      mr: 1,
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                    }}
                  >
                    <Box
                      sx={{
                        width: "30px",
                        height: "80px",
                        backgroundColor: "#5FB2D7",
                        mb: 0.5,
                      }}
                    />
                    <Typography variant="caption" sx={{ fontSize: "0.7rem" }}>
                      9 min
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                    }}
                  >
                    <Box
                      sx={{
                        width: "30px",
                        height: "120px",
                        backgroundColor: "#2E6B97",
                        mb: 0.5,
                      }}
                    />
                    <Typography variant="caption" sx={{ fontSize: "0.7rem" }}>
                      13 min
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Box>

            {/* 230% increase stat */}
            <Box sx={{ py: 2, borderTop: "1px solid #E0E0E0" }}>
              <Typography
                variant="body1"
                sx={{
                  fontSize: "1rem",
                  lineHeight: 1.4,
                }}
              >
                Interactive websites receive{" "}
                <Box component="span" sx={{ fontWeight: 700 }}>
                  330% increase in repeat visits
                </Box>
              </Typography>
            </Box>

            {/* 400% higher click through rate */}
            <Box sx={{ py: 2, borderTop: "1px solid #E0E0E0" }}>
              <Typography
                variant="body1"
                sx={{
                  fontSize: "1rem",
                  lineHeight: 1.4,
                }}
              >
                <Box component="span" sx={{ fontWeight: 700 }}>
                  500% higher click through rate
                </Box>{" "}
                compared to static content
              </Typography>
            </Box>

            {/* Double social shares */}
            <Box sx={{ py: 2, borderTop: "1px solid #E0E0E0" }}>
              <Typography
                variant="body1"
                sx={{
                  fontSize: "1rem",
                  lineHeight: 1.4,
                }}
              >
                Games get{" "}
                <Box component="span" sx={{ fontWeight: 700 }}>
                  300% more social shares
                </Box>{" "}
                compared to static content
              </Typography>
            </Box>

            {/* Mobile Horizontal Bar chart */}
            <Box sx={{ mt: 3 }}>
              {/* Repeat visits chart */}
              <Box sx={{ display: "flex", alignItems: "center", mb: 1.5 }}>
                <Typography
                  variant="caption"
                  sx={{
                    width: "90px",
                    fontSize: "0.7rem",
                    whiteSpace: "nowrap",
                  }}
                >
                  Repeat visits
                </Typography>
                <Box sx={{ flex: 1, display: "flex", ml: 1 }}>
                  <Box
                    sx={{
                      width: "20%",
                      height: "14px",
                      backgroundColor: "#5FB2D7",
                    }}
                  />
                  <Box
                    sx={{
                      width: "46%",
                      height: "14px",
                      backgroundColor: "#2E6B97",
                    }}
                  />
                </Box>
              </Box>

              {/* Click-thru-rate chart */}
              <Box sx={{ display: "flex", alignItems: "center", mb: 1.5 }}>
                <Typography
                  variant="caption"
                  sx={{
                    width: "90px",
                    fontSize: "0.7rem",
                    whiteSpace: "nowrap",
                  }}
                >
                  Click-thru-rate
                </Typography>
                <Box sx={{ flex: 1, display: "flex", ml: 1 }}>
                  <Box
                    sx={{
                      width: "20%",
                      height: "14px",
                      backgroundColor: "#5FB2D7",
                    }}
                  />
                  <Box
                    sx={{
                      width: "80%",
                      height: "14px",
                      backgroundColor: "#2E6B97",
                    }}
                  />
                </Box>
              </Box>

              {/* Social shares chart */}
              <Box sx={{ display: "flex", alignItems: "center", mb: 1.5 }}>
                <Typography
                  variant="caption"
                  sx={{
                    width: "90px",
                    fontSize: "0.7rem",
                    whiteSpace: "nowrap",
                  }}
                >
                  Social shares
                </Typography>
                <Box sx={{ flex: 1, display: "flex", ml: 1 }}>
                  <Box
                    sx={{
                      width: "20%",
                      height: "14px",
                      backgroundColor: "#5FB2D7",
                    }}
                  />
                  <Box
                    sx={{
                      width: "40%",
                      height: "14px",
                      backgroundColor: "#2E6B97",
                    }}
                  />
                </Box>
              </Box>

              {/* Percentage markers */}
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  mt: 0.5,
                  mb: 1,
                  ml: "90px",
                  pl: 1,
                }}
              >
                <Typography
                  variant="caption"
                  sx={{ fontSize: "0.6rem", flexShrink: 0 }}
                >
                  0%
                </Typography>
                <Typography
                  variant="caption"
                  sx={{ fontSize: "0.6rem", flexShrink: 0 }}
                >
                  100%
                </Typography>
                <Typography
                  variant="caption"
                  sx={{ fontSize: "0.6rem", flexShrink: 0 }}
                >
                  200%
                </Typography>
                <Typography
                  variant="caption"
                  sx={{ fontSize: "0.6rem", flexShrink: 0 }}
                >
                  300%
                </Typography>
                <Typography
                  variant="caption"
                  sx={{ fontSize: "0.6rem", flexShrink: 0 }}
                >
                  400%
                </Typography>
                <Typography
                  variant="caption"
                  sx={{ fontSize: "0.6rem", flexShrink: 0 }}
                >
                  500%
                </Typography>
              </Box>

              {/* Sources */}
              <Box sx={{ textAlign: "right" }}>
                <Typography
                  variant="caption"
                  sx={{ display: "block", fontSize: "0.6rem" }}
                >
                  <Link
                    href="https://contentmarketinginstitute.com/articles/interactive-content-quizzes/"
                    target="_blank"
                    rel="noopener noreferrer"
                    sx={{ color: "inherit", textDecoration: "underline" }}
                  >
                    Content Marketing Institute, 2024
                  </Link>
                </Typography>
                <Typography variant="caption" sx={{ fontSize: "0.6rem" }}>
                  <Link
                    href="https://www.marketingscoop.com/marketing/the-state-of-interactive-content-marketing-in-2024-25-stats-trends-to-know/"
                    target="_blank"
                    rel="noopener noreferrer"
                    sx={{ color: "inherit", textDecoration: "underline" }}
                  >
                    Marketing Scoop, 2024
                  </Link>
                </Typography>
              </Box>
            </Box>
          </Box>

          {/* Benefits Section */}
          <Box sx={{ width: "100%" }}>
            {/* Boost Retention */}
            <Box sx={{ py: 2, borderTop: "1px solid #000" }}>
              <Typography
                variant="h4"
                component="h3"
                sx={{
                  fontWeight: 700,
                  fontSize: { xs: "1.5rem", md: "1.75rem" },
                  mb: 1,
                }}
              >
                Boost Retention
              </Typography>
              <Typography
                variant="body1"
                sx={{
                  fontSize: "1rem",
                  lineHeight: 1.4,
                }}
              >
                Make your site a daily destination for readers with fun, habit
                building content that delights.
              </Typography>
            </Box>

            {/* Built Loyalty */}
            <Box sx={{ py: 2, borderTop: "1px solid #E0E0E0" }}>
              <Typography
                variant="h4"
                component="h3"
                sx={{
                  fontWeight: 700,
                  fontSize: { xs: "1.5rem", md: "1.75rem" },
                  mb: 1,
                }}
              >
                Built Loyalty
              </Typography>
              <Typography
                variant="body1"
                sx={{
                  fontSize: "1rem",
                  lineHeight: 1.4,
                }}
              >
                Build a lasting connection through games that connect readers to
                your publication.
              </Typography>
            </Box>

            {/* Fresh Content */}
            <Box sx={{ py: 2, borderTop: "1px solid #E0E0E0" }}>
              <Typography
                variant="h4"
                component="h3"
                sx={{
                  fontWeight: 700,
                  fontSize: { xs: "1.5rem", md: "1.75rem" },
                  mb: 1,
                }}
              >
                Fresh Content
              </Typography>
              <Typography
                variant="body1"
                sx={{
                  fontSize: "1rem",
                  lineHeight: 1.4,
                }}
              >
                Give readers something new and engaging every day that is unique
                to your community.
              </Typography>
            </Box>
          </Box>

          {/* Game tiles - Mobile View */}
          <Box
            sx={{
              backgroundColor: "white",
              mt: 3,
              borderTop: "1px solid #E0E0E0",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                gap: 2,
                width: "100%",
                mb: 3,
                mt: 3,
                backgroundColor: "#D9D9D9",
                p: 2,
              }}
            >
              <Box
                sx={{
                  width: "45%",
                  border: "1px solid #000",
                  borderRadius: "5px",
                  overflow: "hidden",
                  pb: 1,
                  bgcolor: "white",
                }}
              >
                <Box
                  sx={{
                    height: "120px",
                    bgcolor: "#ffd583",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    p: 1.5,
                    m: 1,
                    border: "1px solid black",
                    borderRadius: "3px",
                  }}
                >
                  <Box
                    component="img"
                    src={`${process.env.PUBLIC_URL}/assets/game-tiles/wordyverse2.svg`}
                    sx={{ height: "90%", maxWidth: "90%" }}
                    alt="The Wordy"
                  />
                </Box>
                <Box
                  sx={{ display: "flex", justifyContent: "center", mt: 0.5 }}
                >
                  <Box
                    sx={{
                      bgcolor: "#000",
                      color: "#fff",
                      py: 0.5,
                      px: 2,
                      borderRadius: "25px",
                      width: "80%",
                      textAlign: "center",
                    }}
                  >
                    <Typography
                      color="white"
                      variant="caption"
                      fontWeight="medium"
                    >
                      The Wordy
                    </Typography>
                  </Box>
                </Box>
              </Box>

              <Box
                sx={{
                  width: "45%",
                  border: "1px solid #000",
                  borderRadius: "5px",
                  overflow: "hidden",
                  pb: 1,
                  bgcolor: "white",
                }}
              >
                <Box
                  sx={{
                    height: "120px",
                    bgcolor: "#91B2D1",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    p: 1.5,
                    m: 1,
                    border: "1px solid black",
                    borderRadius: "3px",
                  }}
                >
                  <Box
                    component="img"
                    src={`${process.env.PUBLIC_URL}/assets/game-tiles/weather-wiz.svg`}
                    alt="Weather Wiz"
                    sx={{ height: "90%", maxWidth: "90%" }}
                  />
                </Box>
                <Box
                  sx={{ display: "flex", justifyContent: "center", mt: 0.5 }}
                >
                  <Box
                    sx={{
                      bgcolor: "#000",
                      color: "#fff",
                      py: 0.5,
                      px: 2,
                      borderRadius: "25px",
                      width: "80%",
                      textAlign: "center",
                    }}
                  >
                    <Typography
                      color="white"
                      variant="caption"
                      fontWeight="medium"
                    >
                      Weather Wiz
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Box>

            {/* Customization Text */}
            <Box sx={{ px: 2, mb: 3 }}>
              <Typography
                sx={{
                  fontSize: "0.9rem",
                  textAlign: "center",
                  lineHeight: 1.5,
                  color: "#000",
                }}
              >
                Whether it's word trivia based on how well you know your city or
                a puzzle based on the current weather, Flying Comet customizes
                games your readers will love.
              </Typography>
            </Box>

            {/* Testimonial */}
            <Box
              sx={{
                width: "100%",
                textAlign: "center",
                mb: 1,
              }}
            >
              <Typography
                sx={{
                  fontStyle: "italic",
                  fontSize: "1rem",
                  mb: 1,
                  lineHeight: 1.4,
                  fontWeight: 500,
                }}
              >
                "I'm born and raised in Ballard and it's really fun challenging
                my Seattle knowledge, I feel like I learn more from it!"
              </Typography>
              <Typography
                sx={{
                  fontWeight: 600,
                  fontSize: "0.9rem",
                  color: "#555",
                }}
              >
                - Seattle Wordy player
              </Typography>
            </Box>
          </Box>
        </Box>
      ) : (
        /* Desktop View - Two Column Layout */
        <Grid container spacing={4} sx={{ px: 4 }}>
          {/* Left Column - Statistics */}
          <Grid item xs={12} md={6}>
            <Box
              sx={{
                borderTop: "1px solid #000",
                pt: 3,
                pb: 2,
              }}
            >
              <Typography
                variant="h4"
                component="h3"
                sx={{
                  fontWeight: 700,
                  fontSize: "1.75rem",
                  mb: 3,
                }}
              >
                By The Numbers
              </Typography>

              {/* 53% increase stat */}
              <Box sx={{ mb: 4 }}>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Box sx={{ width: "60%" }}>
                    <Typography
                      variant="body1"
                      sx={{
                        fontSize: "1.2rem",
                        fontWeight: 700,
                        display: "block",
                      }}
                    >
                      53% increase in session duration
                    </Typography>
                    <Typography
                      variant="body1"
                      sx={{
                        fontSize: "1.2rem",
                        display: "block",
                      }}
                    >
                      with interactive content
                    </Typography>
                  </Box>

                  {/* Bar chart for session duration */}
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "flex-end",
                      width: "35%",
                      justifyContent: "center",
                    }}
                  >
                    <Box sx={{ mr: 2 }}>
                      <Box
                        sx={{
                          width: "60px",
                          height: "120px",
                          backgroundColor: "#5FB2D7",
                          mb: 1,
                        }}
                      />
                      <Typography variant="body2" sx={{ textAlign: "center" }}>
                        9 min
                      </Typography>
                    </Box>
                    <Box>
                      <Box
                        sx={{
                          width: "60px",
                          height: "180px",
                          backgroundColor: "#2E6B97",
                          mb: 1,
                        }}
                      />
                      <Typography variant="body2" sx={{ textAlign: "center" }}>
                        13 min
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </Box>

              {/* 230% increase stat */}
              <Box sx={{ mb: 4, borderTop: "1px solid #E0E0E0", pt: 3 }}>
                <Typography variant="body1" sx={{ fontSize: "1.2rem" }}>
                  Interactive websites receive{" "}
                  <Box component="span" sx={{ fontWeight: 700 }}>
                    330% increase in repeat visits
                  </Box>
                </Typography>
              </Box>

              {/* 400% higher click through rate */}
              <Box sx={{ mb: 4, borderTop: "1px solid #E0E0E0", pt: 3 }}>
                <Typography variant="body1" sx={{ fontSize: "1.2rem" }}>
                  <Box component="span" sx={{ fontWeight: 700 }}>
                    500% higher click through rate
                  </Box>{" "}
                  compared to static content
                </Typography>
              </Box>

              {/* Double social shares */}
              <Box sx={{ mb: 4, borderTop: "1px solid #E0E0E0", pt: 3 }}>
                <Typography variant="body1" sx={{ fontSize: "1.2rem" }}>
                  Games get{" "}
                  <Box component="span" sx={{ fontWeight: 700 }}>
                    300% more social shares
                  </Box>{" "}
                  compared to static content
                </Typography>
              </Box>

              {/* Desktop Horizontal Bar chart */}
              <Box sx={{ mt: 4 }}>
                <Box sx={{ display: "flex", flexDirection: "column", gap: 3 }}>
                  {/* Repeat visits chart */}
                  <Box sx={{ display: "flex", alignItems: "center", mb: 1 }}>
                    <Typography
                      variant="body2"
                      sx={{ width: "120px", whiteSpace: "nowrap" }}
                    >
                      Repeat visits
                    </Typography>
                    <Box sx={{ flex: 1, display: "flex", ml: 2 }}>
                      <Box
                        sx={{
                          width: "20%",
                          height: "20px",
                          backgroundColor: "#5FB2D7",
                        }}
                      />
                      <Box
                        sx={{
                          width: "46%",
                          height: "20px",
                          backgroundColor: "#2E6B97",
                        }}
                      />
                    </Box>
                  </Box>

                  {/* Click-thru-rate chart */}
                  <Box sx={{ display: "flex", alignItems: "center", mb: 1 }}>
                    <Typography
                      variant="body2"
                      sx={{ width: "120px", whiteSpace: "nowrap" }}
                    >
                      Click-thru-rate
                    </Typography>
                    <Box sx={{ flex: 1, display: "flex", ml: 2 }}>
                      <Box
                        sx={{
                          width: "20%",
                          height: "20px",
                          backgroundColor: "#5FB2D7",
                        }}
                      />
                      <Box
                        sx={{
                          width: "80%",
                          height: "20px",
                          backgroundColor: "#2E6B97",
                        }}
                      />
                    </Box>
                  </Box>

                  {/* Social shares chart */}
                  <Box sx={{ display: "flex", alignItems: "center", mb: 1 }}>
                    <Typography
                      variant="body2"
                      sx={{ width: "120px", whiteSpace: "nowrap" }}
                    >
                      Social shares
                    </Typography>
                    <Box sx={{ flex: 1, display: "flex", ml: 2 }}>
                      <Box
                        sx={{
                          width: "20%",
                          height: "20px",
                          backgroundColor: "#5FB2D7",
                        }}
                      />
                      <Box
                        sx={{
                          width: "40%",
                          height: "20px",
                          backgroundColor: "#2E6B97",
                        }}
                      />
                    </Box>
                  </Box>

                  {/* Percentage markers */}
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      mt: 1,
                      ml: "122px",
                      mr: 0,
                      pr: 0,
                    }}
                  >
                    <Typography variant="caption" sx={{ flexShrink: 0 }}>
                      0%
                    </Typography>
                    <Typography variant="caption" sx={{ flexShrink: 0 }}>
                      100%
                    </Typography>
                    <Typography variant="caption" sx={{ flexShrink: 0 }}>
                      200%
                    </Typography>
                    <Typography variant="caption" sx={{ flexShrink: 0 }}>
                      300%
                    </Typography>
                    <Typography variant="caption" sx={{ flexShrink: 0 }}>
                      400%
                    </Typography>
                    <Typography variant="caption" sx={{ flexShrink: 0 }}>
                      500%
                    </Typography>
                  </Box>
                </Box>

                {/* Sources */}
                <Box sx={{ mt: 1, textAlign: "right" }}>
                  <Typography variant="caption" sx={{ display: "block" }}>
                    <Link
                      href="https://contentmarketinginstitute.com/articles/interactive-content-quizzes/"
                      target="_blank"
                      rel="noopener noreferrer"
                      sx={{ color: "inherit", textDecoration: "underline" }}
                    >
                      Content Marketing Institute, 2024
                    </Link>
                  </Typography>
                  <Typography variant="caption">
                    <Link
                      href="https://www.marketingscoop.com/marketing/the-state-of-interactive-content-marketing-in-2024-25-stats-trends-to-know/"
                      target="_blank"
                      rel="noopener noreferrer"
                      sx={{ color: "inherit", textDecoration: "underline" }}
                    >
                      Marketing Scoop, 2024
                    </Link>
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Grid>

          {/* Right Column - Benefits */}
          <Grid item xs={12} md={6}>
            <Box
              sx={{
                borderTop: "1px solid #000",
                pt: 3,
                pb: 2,
              }}
            >
              {/* Boost Retention */}
              <Box sx={{ mb: 4 }}>
                <Typography
                  variant="h4"
                  component="h3"
                  sx={{
                    fontWeight: 700,
                    fontSize: "1.75rem",
                    mb: 2,
                  }}
                >
                  Boost Retention
                </Typography>
                <Typography
                  variant="body1"
                  sx={{
                    fontSize: "1.2rem",
                    mb: 2,
                    lineHeight: 1.5,
                  }}
                >
                  Make your site a daily destination for readers with fun, habit
                  building content that delights.
                </Typography>
              </Box>

              <Box sx={{ borderTop: "1px solid #E0E0E0", pt: 3, mb: 4 }}>
                <Typography
                  variant="h4"
                  component="h3"
                  sx={{
                    fontWeight: 700,
                    fontSize: "1.75rem",
                    mb: 2,
                  }}
                >
                  Built Loyalty
                </Typography>
                <Typography
                  variant="body1"
                  sx={{
                    fontSize: "1.2rem",
                    mb: 2,
                    lineHeight: 1.5,
                  }}
                >
                  Build a lasting connection through games that connect readers
                  to your publication.
                </Typography>
              </Box>

              <Box sx={{ borderTop: "1px solid #E0E0E0", pt: 3, mb: 4 }}>
                <Typography
                  variant="h4"
                  component="h3"
                  sx={{
                    fontWeight: 700,
                    fontSize: "1.75rem",
                    mb: 2,
                  }}
                >
                  Fresh Content
                </Typography>
                <Typography
                  variant="body1"
                  sx={{
                    fontSize: "1.2rem",
                    mb: 2,
                    lineHeight: 1.5,
                  }}
                >
                  Give readers something new and engaging every day that is
                  unique to your community.
                </Typography>
              </Box>

              {/* Game tiles - Desktop View */}
              <Box
                sx={{
                  backgroundColor: "white",
                  mt: 4,
                  borderTop: "1px solid #E0E0E0",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    gap: 4,
                    width: "100%",
                    mb: 3,
                    mt: 3,
                    backgroundColor: "#D9D9D9",
                    p: 3,
                  }}
                >
                  <Box
                    sx={{
                      width: "180px",
                      border: "1px solid #000",
                      borderRadius: "5px",
                      overflow: "hidden",
                      pb: 1,
                      bgcolor: "white",
                    }}
                  >
                    <Box
                      sx={{
                        height: "140px",
                        bgcolor: "#ffd583",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        p: 2,
                        m: 1,
                        border: "1px solid black",
                        borderRadius: "3px",
                      }}
                    >
                      <Box
                        component="img"
                        src={`${process.env.PUBLIC_URL}/assets/game-tiles/wordyverse2.svg`}
                        alt="The Wordy"
                      />
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        mt: 1,
                      }}
                    >
                      <Box
                        sx={{
                          bgcolor: "#000",
                          color: "#fff",
                          py: 0.5,
                          px: 2,
                          borderRadius: "25px",
                          width: "80%",
                          textAlign: "center",
                        }}
                      >
                        <Typography
                          color="white"
                          variant="body2"
                          fontWeight="medium"
                        >
                          The Wordy
                        </Typography>
                      </Box>
                    </Box>
                  </Box>

                  <Box
                    sx={{
                      width: "180px",
                      border: "1px solid #000",
                      borderRadius: "5px",
                      overflow: "hidden",
                      pb: 1,
                      bgcolor: "white",
                    }}
                  >
                    <Box
                      sx={{
                        height: "140px",
                        bgcolor: "#91B2D1",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        p: 2,
                        m: 1,
                        border: "1px solid black",
                        borderRadius: "3px",
                      }}
                    >
                      <Box
                        component="img"
                        src={`${process.env.PUBLIC_URL}/assets/game-tiles/weather-wiz.svg`}
                        alt="Weather Wiz"
                      />
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        mt: 1,
                      }}
                    >
                      <Box
                        sx={{
                          bgcolor: "#000",
                          color: "#fff",
                          py: 0.5,
                          px: 2,
                          borderRadius: "25px",
                          width: "80%",
                          textAlign: "center",
                        }}
                      >
                        <Typography
                          color="white"
                          variant="body2"
                          fontWeight="medium"
                        >
                          Weather Wiz
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                </Box>

                {/* Customization text */}
                <Box sx={{ px: 4, mb: 4, maxWidth: "500px" }}>
                  <Typography
                    sx={{
                      fontSize: "1.1rem",
                      textAlign: "center",
                      lineHeight: 1.5,
                    }}
                  >
                    Whether it's word trivia based on how well you know your
                    city or a puzzle based on the current weather, Flying Comet
                    customizes games your readers will love.
                  </Typography>
                </Box>

                {/* Testimonial */}
                <Box sx={{ width: "100%", textAlign: "center" }}>
                  <Typography
                    sx={{
                      fontStyle: "italic",
                      fontSize: "1.2rem",
                      mb: 1.5,
                      lineHeight: 1.5,
                      fontWeight: 500,
                      maxWidth: "700px",
                      margin: "0 auto",
                    }}
                  >
                    "I'm born and raised in Ballard and it's really fun
                    challenging my Seattle knowledge, I feel like I learn more
                    from it!"
                  </Typography>
                  <Typography
                    sx={{
                      fontWeight: 600,
                      fontSize: "1rem",
                      color: "#555",
                    }}
                  >
                    - Seattle Wordy player
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Grid>
        </Grid>
      )}

      {/* CTA Section - Visible on both desktop and mobile */}
      <Box
        sx={{
          bgcolor: "#f0f0f0",
          py: { xs: 3, md: 4 },
          px: { xs: 2, md: 4 },
          mt: { xs: 0, md: 6 },
          textAlign: "center",
          border: "1px solid #D9D9D9",
        }}
      >
        <Typography
          variant="h4"
          component="h3"
          sx={{
            fontWeight: 700,
            fontSize: { xs: "1.5rem", md: "1.75rem" },
            mb: { xs: 2, md: 3 },
          }}
        >
          Ready to add games?
        </Typography>
        <Box
          component="a"
          href="https://cal.com/calli-fuchigami/15min"
          target="_blank"
          rel="noopener noreferrer"
          sx={{
            display: "inline-block",
            bgcolor: "#000",
            color: "#fff",
            py: { xs: 1, md: 1.5 },
            px: { xs: 3, md: 4 },
            borderRadius: "25px",
            textDecoration: "none",
            fontWeight: 600,
            fontSize: { xs: "1rem", md: "1.1rem" },
            "&:hover": {
              bgcolor: "#333",
            },
          }}
        >
          Book a demo
        </Box>
      </Box>
    </Box>
  );
};

export default WhyGames;
