import React, { useEffect, useState } from "react";

import CookieConsent from "react-cookie-consent";

// Analytics
import {
  initializeAnalytics,
  disableAnalytics,
  logUserLogin,
  enableAnalytics,
} from "../analytics";

const COOKIE_CONSENT_KEY = "analytics_cookie_consent";
const USER_ID_KEY = "user_id";

const CookieBanner = () => {
  const [cookiesAccepted, setCookiesAccepted] = useState(false);

  // Check for existing consent on mount
  useEffect(() => {
    const checkConsent = () => {
      const consent = localStorage.getItem(COOKIE_CONSENT_KEY);
      const userId = localStorage.getItem(USER_ID_KEY);

      // Always initialize analytics
      initializeAnalytics();

      if (consent === "true") {
        setCookiesAccepted(true);
        enableAnalytics(); // Explicitly enable tracking

        if (userId) {
          logUserLogin(userId, {
            returningUser: true,
            consentStatus: "accepted",
          });
        } else {
          const newUserId = generateUserId();
          localStorage.setItem(USER_ID_KEY, newUserId);
          logUserLogin(newUserId, {
            newUser: true,
            consentStatus: "accepted",
          });
        }
      }
    };

    checkConsent();
  }, []);

  const generateUserId = () => {
    return `u_${Date.now()}_${Math.random().toString(36).substring(2, 15)}`;
  };

  const handleAcceptCookie = () => {
    try {
      setCookiesAccepted(true);
      localStorage.setItem(COOKIE_CONSENT_KEY, "true");

      // First initialize analytics
      initializeAnalytics();
      enableAnalytics(); // Explicitly enable tracking

      // Handle user identification
      const existingUserId = localStorage.getItem(USER_ID_KEY);
      const userId = existingUserId || generateUserId();

      if (!existingUserId) {
        localStorage.setItem(USER_ID_KEY, userId);
      }

      // Log the user with consent status
      logUserLogin(userId, {
        newUser: !existingUserId,
        consentStatus: "accepted",
        consentTimestamp: new Date().toISOString(),
      });
    } catch (error) {
      console.error("Error handling cookie acceptance:", error);
    }
  };

  const handleDeclineCookie = () => {
    try {
      setCookiesAccepted(false);

      // Clear consent and user data
      localStorage.removeItem(COOKIE_CONSENT_KEY);
      localStorage.removeItem(USER_ID_KEY);

      // Disable analytics tracking
      disableAnalytics();

      // Optionally log the decline (if allowed by your privacy policy)
      if (cookiesAccepted) {
        logUserLogin("anonymous", {
          consentStatus: "revoked",
          consentTimestamp: new Date().toISOString(),
        });
      }
    } catch (error) {
      console.error("Error handling cookie decline:", error);
    }
  };

  return (
    <CookieConsent
      location="bottom"
      buttonText="Accept All"
      declineButtonText="Reject All"
      cookieName="ga_cookie_consent"
      style={{
        background: "rgba(53, 53, 53, 0.9)",
        padding: "20px",
        alignItems: "center",
        gap: "20px",
      }}
      buttonStyle={{
        background: "#4CAF50",
        color: "#ffffff",
        fontSize: "14px",
        padding: "10px 20px",
        borderRadius: "5px",
        fontWeight: "bold",
        boxShadow: "0 2px 5px rgba(0, 0, 0, 0.2)",
        transition: "all 0.3s ease",
      }}
      declineButtonStyle={{
        background: "transparent",
        color: "#ffffff",
        fontSize: "14px",
        padding: "10px 20px",
        borderRadius: "5px",
        border: "1px solid #ffffff",
        transition: "all 0.3s ease",
      }}
      enableDeclineButton
      onAccept={handleAcceptCookie}
      onDecline={handleDeclineCookie}
      expires={150}
      ButtonComponent={({ style, ...props }) => (
        <button
          {...props}
          style={{
            ...style,
            ":hover": {
              background: "#45a049",
              transform: "translateY(-2px)",
              boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
            },
          }}
        />
      )}
      DeclineButtonComponent={({ style, ...props }) => (
        <button
          {...props}
          style={{
            ...style,
            ":hover": {
              background: "rgba(255, 255, 255, 0.1)",
            },
          }}
        />
      )}
    >
      <span style={{ fontSize: "16px", marginRight: "20px" }}>
        This website uses cookies to enhance your experience and analyze site
        traffic.
      </span>
    </CookieConsent>
  );
};

export default CookieBanner;
