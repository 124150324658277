export const FCG_WORDS = [
  // April 2025 - Flying Comet Games Wordlist (Game Industry, Space, News Industry)
  {
    date: "2025-04-01",
    hint: "This popular gaming input device is essential for most PC gamers",
    word: "MOUSE",
    characterCount: 5,
  },
  {
    date: "2025-04-02",
    hint: "This celestial body is Earth's only natural satellite",
    word: "LUNAR",
    characterCount: 5,
  },
  {
    date: "2025-04-03",
    hint: "This type of journalistic story reveals previously unknown information",
    word: "SCOOP",
    characterCount: 5,
  },
  {
    date: "2025-04-04",
    hint: "This game engine by Epic Games powers many modern video games",
    word: "UNREAL",
    characterCount: 6,
  },
  {
    date: "2025-04-05",
    hint: "These celestial objects are often called 'shooting stars' when they enter Earth's atmosphere",
    word: "METEOR",
    characterCount: 6,
  },
  {
    date: "2025-04-06",
    hint: "This type of news publication is traditionally delivered to your doorstep",
    word: "PAPER",
    characterCount: 5,
  },
  {
    date: "2025-04-07",
    hint: "This gaming term describes a character's health or stamina in an RPG",
    word: "MANA",
    characterCount: 4,
  },
  {
    date: "2025-04-08",
    hint: "This red planet is the fourth from the sun in our solar system",
    word: "MARS",
    characterCount: 4,
  },
  {
    date: "2025-04-09",
    hint: "This journalism term refers to the main message of a news story",
    word: "LEDE",
    characterCount: 4,
  },
  {
    date: "2025-04-10",
    hint: "This popular game genre involves building and managing simulated worlds",
    word: "CRAFT",
    characterCount: 5,
  },
  {
    date: "2025-04-11",
    hint: "This term describes the visible band of stars in our galaxy",
    word: "MILKY",
    characterCount: 5,
  },
  {
    date: "2025-04-12",
    hint: "This type of journalist covers events as they happen, often from dangerous locations",
    word: "FIELD",
    characterCount: 5,
  },
  {
    date: "2025-04-13",
    hint: "This gaming term describes in-app purchases that provide only cosmetic benefits",
    word: "SKINS",
    characterCount: 5,
  },
  {
    date: "2025-04-14",
    hint: "This is the study of celestial objects and phenomena beyond Earth's atmosphere",
    word: "ASTRO",
    characterCount: 5,
  },
  {
    date: "2025-04-15",
    hint: "This is the section of a newspaper containing opinion pieces",
    word: "OPED",
    characterCount: 4,
  },
  {
    date: "2025-04-16",
    hint: "This indie game development tool is popular for its accessibility to beginners",
    word: "UNITY",
    characterCount: 5,
  },
  {
    date: "2025-04-17",
    hint: "These objects orbit planets and are abundant around Saturn",
    word: "RINGS",
    characterCount: 5,
  },
  {
    date: "2025-04-18",
    hint: "This term describes media reports that fabricate information",
    word: "FAKE",
    characterCount: 4,
  },
  {
    date: "2025-04-19",
    hint: "This gaming term describes moving around a virtual environment",
    word: "ROAM",
    characterCount: 4,
  },
  {
    date: "2025-04-20",
    hint: "This brightest star in Earth's night sky is part of Canis Major",
    word: "SIRIUS",
    characterCount: 6,
  },
  {
    date: "2025-04-21",
    hint: "This traditional position in a newspaper oversees all content",
    word: "EDITOR",
    characterCount: 6,
  },
  {
    date: "2025-04-22",
    hint: "This is a common game mechanic where players compete for rank",
    word: "LADDER",
    characterCount: 6,
  },
  {
    date: "2025-04-23",
    hint: "This term describes the dark center of a black hole",
    word: "EVENT",
    characterCount: 5,
  },
  {
    date: "2025-04-24",
    hint: "This journalism term refers to important stories displayed prominently",
    word: "ABOVE",
    characterCount: 5,
  },
  {
    date: "2025-04-25",
    hint: "This gaming term describes a player's first-person shooter statistics",
    word: "RATIO",
    characterCount: 5,
  },
  {
    date: "2025-04-26",
    hint: "This space agency launched the first successful Mars rover",
    word: "NASA",
    characterCount: 4,
  },
  {
    date: "2025-04-27",
    hint: "This journalism term describes information that cannot yet be published",
    word: "EMBARGO",
    characterCount: 7,
  },
  {
    date: "2025-04-28",
    hint: "This is a common test version of games before official release",
    word: "BETA",
    characterCount: 4,
  },
  {
    date: "2025-04-29",
    hint: "This celestial phenomenon occurs when the Moon blocks the Sun",
    word: "SOLAR",
    characterCount: 5,
  },
  {
    date: "2025-04-30",
    hint: "This journalism practice involves fact verification before publication",
    word: "CHECK",
    characterCount: 5,
  },
];

export const getWordForDate = (date) => {
  if (!date || !FCG_WORDS || !Array.isArray(FCG_WORDS)) {
    return null;
  }

  // Convert to PT/Los Angeles timezone
  const ptDate = new Date(
    date.toLocaleString("en-US", { timeZone: "America/Los_Angeles" })
  );

  // Get current date in PT
  const currentPTDate = new Date(
    new Date().toLocaleString("en-US", { timeZone: "America/Los_Angeles" })
  );

  // Reset time parts for accurate date comparison
  currentPTDate.setHours(0, 0, 0, 0);
  ptDate.setHours(0, 0, 0, 0);

  // If requested date is in the future, return null
  if (ptDate > currentPTDate) {
    return null;
  }

  // Format the PT date as YYYY-MM-DD
  const dateString =
    ptDate.getFullYear() +
    "-" +
    String(ptDate.getMonth() + 1).padStart(2, "0") +
    "-" +
    String(ptDate.getDate()).padStart(2, "0");

  return FCG_WORDS.find((entry) => entry.date === dateString);
};

export const findLatestAvailableDate = () => {
  if (!FCG_WORDS || !Array.isArray(FCG_WORDS)) {
    return null;
  }

  const currentPTDate = new Date(
    new Date().toLocaleString("en-US", { timeZone: "America/Los_Angeles" })
  );

  // Create date string in YYYY-MM-DD format to ignore time
  const currentDateString = [
    currentPTDate.getFullYear(),
    (currentPTDate.getMonth() + 1).toString().padStart(2, "0"),
    currentPTDate.getDate().toString().padStart(2, "0"),
  ].join("-");

  // Filter by comparing normalized date strings
  const latestEntry = FCG_WORDS.filter((entry) => {
    // Compare date strings directly (they're already in YYYY-MM-DD format)
    return entry.date <= currentDateString;
  }).sort((a, b) => b.date.localeCompare(a.date))[0];

  return latestEntry || null;
};
