import React, { useState, useEffect } from "react";
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { createTheme } from "@mui/material/styles";
import { IconButton, Divider, ButtonGroup, Button, Paper } from "@mui/material";
import { Search } from "@mui/icons-material";
import { Menu as MenuIcon } from "lucide-react";
import DemoWordyPreview from "./DemoWordyPreview";
import DemoKeywordPreview from "./DemoKeywordPreview";

// Import Google Fonts
import "@fontsource/playfair-display/400.css";
import "@fontsource/playfair-display/700.css";
import "@fontsource/open-sans/400.css";
import "@fontsource/open-sans/600.css";
import "@fontsource/open-sans/700.css";

const DemoPreviewPage = ({ config }) => {
  const [currentGame, setCurrentGame] = useState("keyword");

  // Load game type from URL if available
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const gameParam = urlParams.get("game");

    if (gameParam && (gameParam === "keyword" || gameParam === "wordy")) {
      setCurrentGame(gameParam);
    }
  }, []);

  const handleGameChange = (game) => {
    setCurrentGame(game);

    // Update URL without refreshing the page
    const url = new URL(window.location);
    url.searchParams.set("game", game);
    window.history.pushState({}, "", url);
  };

  // Get logo (custom or default)
  const getLogo = () => {
    if (config.headerImageLink) {
      return (
        <Box
          component="img"
          src={config.headerImageLink}
          alt={`${config.publicationName} Logo`}
          sx={{ height: 40 }}
        />
      );
    } else if (config.headerSvg) {
      // If SVG is provided, use dangerouslySetInnerHTML
      return (
        <Box
          sx={{ height: 40 }}
          dangerouslySetInnerHTML={{ __html: config.headerSvg }}
        />
      );
    } else {
      // Default: just display the publication name
      return (
        <Typography
          variant="h6"
          component="div"
          sx={{
            fontWeight: "bold",
            letterSpacing: 1,
            color: "white",
          }}
        >
          {config.publicationName.toUpperCase()}
        </Typography>
      );
    }
  };

  return (
    <Box sx={{ bgcolor: "background.default", minHeight: "100vh", pb: 4 }}>
      <Paper
        sx={{
          maxWidth: "sm",
          margin: "0 auto",
          position: "relative",
          overflow: "hidden",
          bgcolor: "background.paper",
          boxShadow: "0px 2px 6px rgba(0,0,0,0.5)",
        }}
      >
        {/* Top navigation bar */}
        <Box
          sx={{
            bgcolor: config.headerBarColor,
            color: "white",
            px: 2,
            py: 1.5,
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <IconButton edge="start" color="inherit" aria-label="menu">
            <MenuIcon size={24} color="white" />
          </IconButton>

          <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
            {getLogo()}
          </Box>

          <IconButton color="inherit" aria-label="search">
            <Search />
          </IconButton>
        </Box>

        {/* Content container with consistent padding */}
        <Box sx={{ p: 1 }}>
          {/* Games section title - using Open Sans */}
          <Typography
            variant="h5"
            component="div"
            sx={{
              fontWeight: 700,
              color: config.highlightColor,
              mb: 1,
              fontSize: "1.8rem",
              letterSpacing: "0.02em",
              fontFamily: '"Open Sans", "Helvetica", "Arial", sans-serif',
              textTransform: "uppercase",
            }}
          >
            GAMES
          </Typography>
          <Divider sx={{ mb: 3 }} />

          {/* Game Toggle Buttons */}
          <Box sx={{ mb: 3, display: "flex", justifyContent: "center" }}>
            <ButtonGroup
              variant="outlined"
              sx={{
                borderRadius: "4px",
                overflow: "hidden",
                "& .MuiButton-root": {
                  border: "1px solid #e0e0e0",
                  borderRadius: 0,
                  fontSize: "0.9rem",
                  minWidth: "130px",
                  fontFamily: '"Open Sans", "Helvetica", "Arial", sans-serif',
                },
              }}
            >
              <Button
                onClick={() => handleGameChange("keyword")}
                sx={{
                  bgcolor:
                    currentGame === "keyword" ? config.highlightColor : "white",
                  color: currentGame === "keyword" ? "white" : "black",
                  fontWeight: 600,
                  borderRight: "1px solid #e0e0e0",
                  "&:hover": {
                    bgcolor:
                      currentGame === "keyword"
                        ? config.highlightColor
                        : "#f5f5f5",
                  },
                }}
              >
                KEYWORD
              </Button>
              <Button
                onClick={() => handleGameChange("wordy")}
                sx={{
                  bgcolor:
                    currentGame === "wordy" ? config.highlightColor : "white",
                  color: currentGame === "wordy" ? "white" : "black",
                  fontWeight: 600,
                  "&:hover": {
                    bgcolor:
                      currentGame === "wordy"
                        ? config.highlightColor
                        : "#f5f5f5",
                  },
                }}
              >
                {config.wordyTitle.toUpperCase()}
              </Button>
            </ButtonGroup>
          </Box>

          {/* Game container */}
          <Box sx={{ mb: 4, overflow: "hidden" }}>
            {currentGame === "keyword" ? (
              <DemoKeywordPreview config={config} />
            ) : (
              <DemoWordyPreview config={config} />
            )}
          </Box>
        </Box>
      </Paper>
      {/* Disclaimer Banner */}
      <Box
        sx={{
          textAlign: "center",
          p: 2,
          mt: 2,
          color: "text.secondary",
          fontSize: "0.875rem",
        }}
      >
        This is a demo of {config.publicationName} created with Flying Comet
        Games
      </Box>
    </Box>
  );
};

export default DemoPreviewPage;
