import { useSearchParams } from "react-router-dom";
import { useEffect, useState } from "react";
import BaseWordyGame from "../../games/WordyVerse/Components/BaseWordyGame";
import { processPublicationData } from "./helper";
import LoadingSkeleton from "./LoadingSkeleton";
import { forceEnableForEmbed, identifyUser } from "../../analytics";

export const ALAMEDA_POST = "ZqUdNcP7DWb2WexYu7GDRd";
export const SEATTLE_TIMES = "BpVgW4KuazkY9qodk9YFob";

// testing
// export const SEATTLE_TIMES = "AdXbHK5DNgwyBWpckJRkRF";
// const ALAMEDA_POST = "AdXbHK5DNgwyBWpckJRkRF";

const fetchPublicationByEncodedId = async (encodedId) => {
  const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
  try {
    const response = await fetch(`${API_BASE_URL}/words/${encodedId}`);
    if (!response.ok) throw new Error("Publication not found");
    return await response.json();
  } catch (error) {
    console.error("Failed to fetch publication:", error);
    return null;
  }
};

const getIconSvgPath = (id) => {
  if (id === SEATTLE_TIMES) {
    return `${process.env.PUBLIC_URL}/assets/customer-logos/st-logo.png`;
  }

  if (id === ALAMEDA_POST) {
    return `${process.env.PUBLIC_URL}/assets/customer-logos/ap-logo.png`;
  }

  return `${process.env.PUBLIC_URL}/assets/icons/wordy-verse-icon.svg`;
};

const getBannerSvgPath = (id) => {
  return id === ALAMEDA_POST
    ? `${process.env.PUBLIC_URL}/assets/customer-logos/banner/wordameda.svg`
    : null;
};

const getGameCustomization = (id) => {
  if (id === ALAMEDA_POST) {
    return {
      correctColor: ["#0050C9", "white"],
      presentColor: ["#990000", "white"],
      absentColor: ["#625D58", "white"],
      shareButtonColor: ["#D43A37", "#1F4FC2"],
      gameTitle: "Alameda Post Wordameda",
      successBackgroundColor: "#070569",
      shareEmojiCorrect: "🟦",
      shareEmojiPresent: "🟥",
      shareEmojiAbsent: "⬛",
      shareUrl: `https://alamedapost.com/wordameda/`,
    };
  }

  if (id === SEATTLE_TIMES) {
    return {
      gameTitle: "Seattle Wordy",
      shareUrl: `https://www.seattletimes.com/games-seattle-wordy/`,
      hideTitle: true,
    };
  }

  return {
    shareUrl: `https://flyingcometgames.com/fcg/wordy?id=${id}`,
  };
};

const WordyGameEmbed = () => {
  const [searchParams] = useSearchParams();
  const [publication, setPublication] = useState(null);

  const encodedId = searchParams.get("id");
  const playerUID = searchParams.get("uid");

  useEffect(() => {
    if (encodedId) {
      fetchPublicationByEncodedId(encodedId).then(setPublication);
    }

    // Enable analytics for embedded content
    if (encodedId) {
      forceEnableForEmbed(encodedId);

      // Identify the user if uid is provided
      if (playerUID) {
        identifyUser(playerUID, {
          publication_id: encodedId,
          source: "embedded_game",
        });
      }
    }
  }, [encodedId, playerUID]);

  if (!publication) {
    return <LoadingSkeleton />;
  }

  const processedData = processPublicationData(publication.words);
  const iconPath = getIconSvgPath(encodedId);
  const customization = getGameCustomization(encodedId);
  const bannerPath = getBannerSvgPath(encodedId);

  return (
    <BaseWordyGame
      title={customization.gameTitle ?? publication.title}
      customTitle={customization.gameTitle ?? `${publication.title} Wordy`}
      iconPath={iconPath}
      shareText={customization.gameTitle ?? `${publication.title} Wordy`}
      shareUrl={customization.shareUrl}
      getWordForDate={processedData.getWordForDate}
      findLatestAvailableDate={processedData.findLatestAvailableDate}
      publicationId={encodedId}
      keyboardBackgroundColor="#d3d6da"
      hideTitle={customization.hideTitle}
      {...(customization.correctColor && {
        correctColor: customization.correctColor,
        presentColor: customization.presentColor,
        absentColor: customization.absentColor,
        successBackgroundColor: customization.successBackgroundColor,
        shareButtonColor: customization.shareButtonColor,
        shareEmojiCorrect: customization.shareEmojiCorrect,
        shareEmojiPresent: customization.shareEmojiPresent,
        shareEmojiAbsent: customization.shareEmojiAbsent,
      })}
      {...(bannerPath && {
        bannerPath: bannerPath,
      })}
      isEmbedded={true}
    />
  );
};

export default WordyGameEmbed;
