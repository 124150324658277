import React, { useState } from "react";
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { createTheme } from "@mui/material/styles";
import {
  IconButton,
  Divider,
  ButtonGroup,
  Button,
  Paper,
  AppBar,
  Toolbar,
  Container,
  Snackbar,
  Alert,
  Tooltip,
} from "@mui/material";
import {
  ContentCopy as CopyIcon,
  Link as LinkIcon,
  Info as InfoIcon,
  Visibility as VisibilityIcon,
} from "@mui/icons-material";
import DemoCreatorForm from "./DemoCreatorForm";
import DemoWordyPreview from "./DemoWordyPreview";
import DemoKeywordPreview from "./DemoKeywordPreview";
import "@fontsource/playfair-display/400.css";
import "@fontsource/playfair-display/700.css";
import "@fontsource/open-sans/400.css";
import "@fontsource/open-sans/600.css";
import "@fontsource/open-sans/700.css";

const DemoCreator = () => {
  const [currentGame, setCurrentGame] = useState("keyword");
  const [config, setConfig] = useState({
    publicationName: "Publication Name",
    highlightColor: "#000000",
    headerBarColor: "#000000",
    articleName: "Your Article Title Goes Here",
    articleLink: "https://example.com/article",
    headerImageLink: "",
    headerSvg: "",
    wordyTitle: "Publication Wordy",
    wordyWord: "CLERK",
    wordyHint: "Enter a hint for your word here",
    keywordWordList: ["PIECES", "DONATE", "LEGOS", "FOSTER", "SORT"],
    keywordAnswer: "COLOR",
  });

  const [showSnackbar, setShowSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");

  // Create a custom theme based on the publication styling
  const theme = createTheme({
    palette: {
      primary: {
        main: config.highlightColor, // Use highlight color as primary
      },
      secondary: {
        main: config.headerBarColor, // Use header bar color as secondary
      },
      background: {
        default: "#f6f6f6", // Light gray background
        paper: "#ffffff",
      },
    },
    typography: {
      fontFamily: '"Playfair Display", serif',
      h5: {
        fontWeight: 700,
        fontFamily: '"Playfair Display", serif',
      },
      h6: {
        fontFamily: '"Playfair Display", serif',
        fontWeight: 700,
      },
      subtitle1: {
        fontFamily: '"Playfair Display", serif',
      },
      button: {
        textTransform: "uppercase",
        fontWeight: 600,
        fontFamily: '"Open Sans", "Helvetica", "Arial", sans-serif',
      },
    },
    shape: {
      borderRadius: 4,
    },
    components: {
      MuiPaper: {
        defaultProps: {
          elevation: 0,
        },
        styleOverrides: {
          root: {
            boxShadow: "0px 2px 6px rgba(0,0,0,0.05)",
          },
        },
      },
      MuiButton: {
        styleOverrides: {
          root: {
            borderRadius: 4,
            padding: "8px 16px",
          },
        },
      },
    },
  });

  const handleGameChange = (game) => {
    setCurrentGame(game);
  };

  const handleUpdateConfig = (newConfig) => {
    setConfig(newConfig);
    setSnackbarMessage("Preview updated successfully!");
    setShowSnackbar(true);
  };

  // Create a shareable link for the current configuration
  const generateShareableLink = () => {
    try {
      // Convert config to JSON and encode for URL
      const configParam = encodeURIComponent(JSON.stringify(config));
      const gameParam = encodeURIComponent(currentGame);

      // Create URL with params
      const baseUrl = window.location.origin + window.location.pathname;
      const shareableUrl = `${baseUrl}?game=${gameParam}&config=${configParam}`;

      // Copy to clipboard
      navigator.clipboard.writeText(shareableUrl).then(() => {
        setSnackbarMessage("Shareable link copied to clipboard!");
        setShowSnackbar(true);
      });
    } catch (error) {
      console.error("Failed to generate shareable link:", error);
      setSnackbarMessage("Failed to generate shareable link");
      setShowSnackbar(true);
    }
  };

  // Open the full demo preview in a new tab
  const openFullDemo = () => {
    try {
      // Convert config to JSON and encode for URL
      const configParam = encodeURIComponent(JSON.stringify(config));
      const gameParam = encodeURIComponent(currentGame);

      // Create URL for the demo preview route
      const baseUrl = `${window.location.origin}/demo-preview`;
      const demoUrl = `${baseUrl}?game=${gameParam}&config=${configParam}`;

      // Open in a new tab
      window.open(demoUrl, "_blank");
    } catch (error) {
      console.error("Failed to generate demo URL:", error);
      setSnackbarMessage("Failed to open demo preview");
      setShowSnackbar(true);
    }
  };

  // Load configuration from URL parameters on component mount
  React.useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const configParam = urlParams.get("config");
    const gameParam = urlParams.get("game");

    if (configParam) {
      try {
        const decodedConfig = JSON.parse(decodeURIComponent(configParam));
        setConfig(decodedConfig);
      } catch (error) {
        console.error("Failed to parse config from URL:", error);
      }
    }

    if (gameParam) {
      setCurrentGame(decodeURIComponent(gameParam));
    }
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline enableColorScheme />

      <Box sx={{ bgcolor: "background.default", minHeight: "100vh", pb: 4 }}>
        <AppBar position="static" color="secondary">
          <Toolbar>
            <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
              Demo Creator Tool
            </Typography>
            <Tooltip title="View the complete demo in a new tab">
              <Button
                color="inherit"
                startIcon={<VisibilityIcon />}
                onClick={openFullDemo}
                sx={{ mr: 1 }}
              >
                View Full Demo
              </Button>
            </Tooltip>
            <Tooltip title="Copy a shareable link with your current configuration">
              <Button
                color="inherit"
                startIcon={<LinkIcon />}
                onClick={generateShareableLink}
                sx={{ mr: 1 }}
              >
                Share Configuration
              </Button>
            </Tooltip>
            <Tooltip title="Access the tool documentation">
              <IconButton
                color="inherit"
                component="a"
                href="https://github.com/your-repo/webapp/blob/main/frontend/src/tools/DemoCreator/README.md"
                target="_blank"
                rel="noopener noreferrer"
              >
                <InfoIcon />
              </IconButton>
            </Tooltip>
          </Toolbar>
        </AppBar>

        <Container maxWidth="lg" sx={{ mt: 4 }}>
          <Typography variant="h4" component="h1" gutterBottom>
            Game Demo Creator
          </Typography>
          <Typography variant="body1" paragraph>
            Use this tool to create customized Keyword and Wordy game demos.
            Enter your configuration on the left and see the preview on the
            right. After configuring, click "View Full Demo" to see the complete
            demo page.
          </Typography>

          <Box
            sx={{
              display: "flex",
              flexDirection: { xs: "column", md: "row" },
              gap: 3,
              mt: 3,
            }}
          >
            {/* Form Section */}
            <Box sx={{ flex: 1 }}>
              <DemoCreatorForm
                onUpdateConfig={handleUpdateConfig}
                initialConfig={config}
              />
            </Box>

            {/* Preview Section */}
            <Box sx={{ flex: 1 }}>
              <Paper sx={{ p: 2, mb: 3 }}>
                <Typography variant="h5" component="h2" gutterBottom>
                  Live Preview
                </Typography>
                <Divider sx={{ mb: 3 }} />

                {/* Game Toggle Buttons */}
                <Box sx={{ mb: 3, display: "flex", justifyContent: "center" }}>
                  <ButtonGroup
                    variant="outlined"
                    sx={{
                      borderRadius: "4px",
                      overflow: "hidden",
                      "& .MuiButton-root": {
                        border: "1px solid #e0e0e0",
                        borderRadius: 0,
                        fontSize: "0.9rem",
                        minWidth: "130px",
                        fontFamily:
                          '"Open Sans", "Helvetica", "Arial", sans-serif',
                      },
                    }}
                  >
                    <Button
                      onClick={() => handleGameChange("keyword")}
                      sx={{
                        bgcolor:
                          currentGame === "keyword"
                            ? config.highlightColor
                            : "white",
                        color: currentGame === "keyword" ? "white" : "black",
                        fontWeight: 600,
                        borderRight: "1px solid #e0e0e0",
                        "&:hover": {
                          bgcolor:
                            currentGame === "keyword"
                              ? config.highlightColor
                              : "#f5f5f5",
                        },
                      }}
                    >
                      KEYWORD
                    </Button>
                    <Button
                      onClick={() => handleGameChange("wordy")}
                      sx={{
                        bgcolor:
                          currentGame === "wordy"
                            ? config.highlightColor
                            : "white",
                        color: currentGame === "wordy" ? "white" : "black",
                        fontWeight: 600,
                        "&:hover": {
                          bgcolor:
                            currentGame === "wordy"
                              ? config.highlightColor
                              : "#f5f5f5",
                        },
                      }}
                    >
                      {config.wordyTitle.toUpperCase()}
                    </Button>
                  </ButtonGroup>
                </Box>

                {/* Game container */}
                <Box
                  sx={{
                    mb: 4,
                    border: "1px solid #e0e0e0",
                    borderRadius: 1,
                    overflow: "hidden",
                    height: "700px",
                  }}
                >
                  {currentGame === "keyword" ? (
                    <DemoKeywordPreview config={config} />
                  ) : (
                    <DemoWordyPreview config={config} />
                  )}
                </Box>

                {/* Full Demo Button */}
                <Box sx={{ display: "flex", justifyContent: "center", mt: 2 }}>
                  <Button
                    variant="contained"
                    color="primary"
                    size="large"
                    startIcon={<VisibilityIcon />}
                    onClick={openFullDemo}
                  >
                    View Full Demo
                  </Button>
                </Box>
              </Paper>
            </Box>
          </Box>
        </Container>

        {/* Snackbar for notifications */}
        <Snackbar
          open={showSnackbar}
          autoHideDuration={3000}
          onClose={() => setShowSnackbar(false)}
        >
          <Alert onClose={() => setShowSnackbar(false)} severity="success">
            {snackbarMessage}
          </Alert>
        </Snackbar>
      </Box>
    </ThemeProvider>
  );
};

export default DemoCreator;
