import React, { useState, useRef, useEffect } from "react";
import { Box } from "@mui/material";
import { keyframes } from "@mui/system";
import { motion } from "framer-motion";

const flipTile = keyframes`
  0% { transform: rotateX(0); }
  50% { transform: rotateX(90deg); box-shadow: none; }
  100% { transform: rotateX(0); }
`;

const waveEffect = keyframes`
  0% { transform: translateY(0); }
  25% { transform: translateY(-5px); }
  50% { transform: translateY(0); }
  75% { transform: translateY(3px); }
  100% { transform: translateY(0); }
`;

const glowEffect = keyframes`
  0% { box-shadow: 0 0 5px rgba(64, 120, 192, 0); }
  50% { box-shadow: 0 0 15px rgba(64, 120, 192, 0.7); }
  100% { box-shadow: 0 0 5px rgba(64, 120, 192, 0); }
`;

const successPulse = keyframes`
  0% { transform: scale(1); box-shadow: 0 0 0 rgba(46, 125, 50, 0); }
  50% { transform: scale(1.05); box-shadow: 0 0 20px rgba(46, 125, 50, 0.5); }
  100% { transform: scale(1); box-shadow: 0 0 0 rgba(46, 125, 50, 0); }
`;

// CSS-only animation style block
const styles = `
  @keyframes flipAnimation {
    0% { transform: rotateX(0); }
    50% { transform: rotateX(90deg); box-shadow: none; }
    100% { transform: rotateX(0); }
  }

  @keyframes glowAnimation {
    0% { box-shadow: 4px 4px 0px rgba(0, 0, 0, 0.3); }
    50% { box-shadow: 4px 4px 10px rgba(64, 120, 192, 0.6); }
    100% { box-shadow: 4px 4px 0px rgba(0, 0, 0, 0.3); }
  }

  @keyframes pulseAnimation {
    0% { transform: scale(1); }
    50% { transform: scale(1.1); }
    100% { transform: scale(1); }
  }

  @keyframes waveAnimation {
    0% { transform: translateY(0); }
    25% { transform: translateY(-5px); }
    50% { transform: translateY(0); }
    75% { transform: translateY(-2px); }
    100% { transform: translateY(0); }
  }

  @keyframes cursorBlink {
    0% { border-color: #000; }
    50% { border-color: #4d90fe; border-width: 3px; }
    100% { border-color: #000; }
  }

  @keyframes cursorPulse {
    0% { box-shadow: 4px 4px 0px rgba(0, 0, 0, 0.25); }
    50% { box-shadow: 4px 4px 0px rgba(77, 144, 254, 0.5), 0 0 0 2px rgba(77, 144, 254, 0.3); }
    100% { box-shadow: 4px 4px 0px rgba(0, 0, 0, 0.25); }
  }

  .animate-flip {
    animation: flipAnimation 0.6s ease-in-out;
    animation-fill-mode: both;
    perspective: 1000px;
    transform-style: preserve-3d;
  }

  .animate-glow {
    animation: glowAnimation 1.5s ease-in-out;
    animation-delay: 0.6s;
    animation-fill-mode: both;
  }

  .animate-pulse {
    animation: pulseAnimation 1s ease-in-out;
    animation-delay: 0.8s;
    animation-fill-mode: both;
  }

  .animate-wave {
    animation: waveAnimation 1.5s ease-in-out;
    animation-delay: 1.2s;
    animation-fill-mode: both;
  }

  .cursor-blink {
    animation: cursorBlink 1.2s ease-in-out infinite;
  }

  .cursor-pulse {
    animation: cursorPulse 1.2s ease-in-out infinite;
  }
`;

// Define a directional shadow effect matching the screenshot (light from north-west)
const get3DBoxShadow = (isCorrect = false, isPresent = false) => {
  // Right and bottom shadow for 3D effect (light from north-west)
  const dropShadow = "4px 4px 0px rgba(0, 0, 0, 0.3)";

  // Specific shadows for different tile types
  if (isCorrect) {
    // Green tiles
    return dropShadow;
  } else if (isPresent) {
    // Yellow tiles
    return dropShadow;
  } else {
    // Gray/black tiles
    return dropShadow;
  }
};

// Shadow for current guess tiles - empty white tiles
const currentTileShadow = "4px 4px 0px rgba(0, 0, 0, 0.2)";

// Shadow for current letter being typed
const activeTileShadow = "4px 4px 0px rgba(0, 0, 0, 0.25)";

// Hover shadow
const hoverTileShadow = "5px 5px 0px rgba(0, 0, 0, 0.35)";

const GameGrid = ({
  wordData,
  guesses,
  currentGuess,
  getLetterBGColor,
  textColor = "black",
  invalidWord = false,
}) => {
  // We'll still track the most recent guess for animation purposes
  const prevGuessCountRef = useRef(guesses?.length || 0);

  // Track guess indices to figure out which is newest
  const [lastGuessAdded, setLastGuessAdded] = useState(null);

  // Use a ref to track the guess ID to detect when a new one is submitted
  useEffect(() => {
    // Detect when a new guess is added
    if (guesses.length > prevGuessCountRef.current) {
      // The last guess is new - mark it for animation
      setLastGuessAdded(guesses.length - 1);
    }

    // Update the previous guess count
    prevGuessCountRef.current = guesses.length;
  }, [guesses.length]);

  // Insert styles into document on first render
  useEffect(() => {
    const styleSheet = document.createElement("style");
    styleSheet.type = "text/css";
    styleSheet.innerText = styles;
    document.head.appendChild(styleSheet);

    return () => {
      document.head.removeChild(styleSheet);
    };
  }, []);

  // Render past guesses (top section)
  const renderPastGuesses = () => {
    if (!wordData || guesses.length === 0) return null;

    const grid = [];

    // Display guesses in their original order (top to bottom)
    for (let i = 0; i < guesses.length; i++) {
      const row = [];
      const guess = guesses[i];

      // Check if this row should be animated (it's the most recently added guess)
      const shouldAnimate = i === lastGuessAdded;

      // Check if this is a correct guess that should get the success animation
      const isCorrectGuess = guess === wordData.word;
      const shouldAnimateSuccess = shouldAnimate && isCorrectGuess;

      for (let j = 0; j < wordData.word.length; j++) {
        const letter = guess[j];
        let [bgcolor, fontColor] = getLetterBGColor(letter, j, true, i);
        const isCorrectLetter =
          bgcolor === "#4caf50" || bgcolor === "success.main";
        const isPresentLetter =
          !isCorrectLetter && wordData.word.includes(letter.toUpperCase());

        // Basic animation for all revealed letters - with sequential timing for each letter
        let animation = shouldAnimate ? `${flipTile} 0.6s ${j * 0.1}s` : "none";

        // Add glow effect for correct letters
        if (shouldAnimate && isCorrectLetter) {
          animation = `${flipTile} 0.6s ${j * 0.1}s, ${glowEffect} 1.5s ${
            0.6 + j * 0.1
          }s`;
        }

        // Add success pulse animation for correct guesses
        if (shouldAnimateSuccess) {
          const baseDelay = 0.6 + wordData.word.length * 0.1;
          animation = `${flipTile} 0.6s ${j * 0.1}s, ${successPulse} 1s ${
            baseDelay + 0.2
          }s`;
        }

        row.push(
          <Box
            key={`past-${i}-${j}`}
            sx={{
              width: 60,
              height: 60,
              border: "2px solid",
              borderColor: "#000",
              borderRadius: "8px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              backgroundColor: bgcolor,
              color: fontColor,
              fontSize: "2rem",
              fontWeight: "bold",
              m: 0.5,
              // Directional shadow matching the screenshot
              boxShadow: get3DBoxShadow(isCorrectLetter, isPresentLetter),
              animation: animation,
              transition: "all 0.3s ease",
              // Text styling
              fontFamily: "'Arial', sans-serif",
              fontWeight: 700,
            }}
          >
            {letter}
          </Box>
        );
      }

      grid.push(
        <Box
          key={`past-row-${i}`}
          sx={{
            display: "flex",
            justifyContent: "center",
            // mb: 1,
            // Wave effect only for correct guesses
            animation: shouldAnimateSuccess
              ? `${waveEffect} 1.5s ease-in-out 1.2s`
              : "none",
          }}
        >
          {row}
        </Box>
      );
    }

    return grid;
  };

  // Render current guess (middle section)
  const renderCurrentGuess = () => {
    if (!wordData) return null;

    const row = [];
    // Calculate position of cursor - it should be at the current letter being typed
    const cursorPosition = currentGuess.length;

    for (let j = 0; j < wordData.word.length; j++) {
      let letter = j < currentGuess.length ? currentGuess[j] : "";

      // Determine if this is the active position (where the cursor should be)
      const isActivePosition = j === cursorPosition;

      row.push(
        <Box
          key={`current-${j}`}
          sx={{
            width: 60,
            height: 60,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            m: 0.5,
            position: "relative",
            color: "black",
            fontSize: "2rem",
            fontWeight: "bold",
            fontFamily: "'Arial', sans-serif",
            fontWeight: 700,
            // Animation for letters as they're typed
            ...(letter && {
              animation: `${waveEffect} 0.3s ease-in-out`,
            }),
            // Make current letter slightly more prominent
            ...(j === currentGuess.length - 1 &&
              letter && {
                transform: "scale(1.05)",
              }),
          }}
        >
          {/* Display the letter */}
          {letter}

          {/* Underline element with different styling for active position */}
          <Box
            sx={{
              position: "absolute",
              bottom: isActivePosition ? 7 : 10,
              left: 0,
              width: "100%",
              height: isActivePosition ? "4px" : "3px",
              backgroundColor: isActivePosition ? "#4d90fe" : "#000",
              animation: isActivePosition
                ? "cursorPulse 1.2s ease-in-out infinite"
                : "none",
              borderRadius: isActivePosition ? "2px" : "0",
            }}
          />

          {/* Vertical cursor indicator for active position */}
          {isActivePosition && (
            <Box
              sx={{
                position: "absolute",
                bottom: 7,
                left: "50%",
                width: "2px",
                height: "20px",
                backgroundColor: "#4d90fe",
                transform: "translateX(-50%)",
                animation: "cursorBlink 1.2s ease-in-out infinite",
                borderRadius: "1px",
              }}
            />
          )}
        </Box>
      );
    }

    return (
      <motion.div
        animate={
          invalidWord
            ? {
                x: [-10, 10, -10, 10, 0],
                transition: { duration: 0.4, ease: "easeInOut" },
              }
            : {}
        }
      >
        <Box sx={{ display: "flex", justifyContent: "center" }}>{row}</Box>
      </motion.div>
    );
  };

  // Render unused guesses (bottom section)
  const renderUnusedGuesses = () => {
    if (!wordData) return null;

    // If all guesses are used up, don't show any unused rows
    if (guesses.length >= 5) return null;

    // After the current guess, calculate how many unused rows we need
    const remainingGuesses = 5 - guesses.length - 1; // -1 for current guess

    // Make sure we don't create negative rows
    if (remainingGuesses <= 0) return null;

    const grid = [];

    for (let i = 0; i < remainingGuesses; i++) {
      const row = [];

      for (let j = 0; j < wordData.word.length; j++) {
        row.push(
          <Box
            key={`unused-${i}-${j}`}
            sx={{
              width: 60,
              height: 60,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              m: 0.5,
              position: "relative",
              overflow: "hidden",
            }}
          >
            {/* Horizontal underline element instead of underscore character */}
            <Box
              sx={{
                position: "absolute",
                top: "50%", // Center vertically
                left: 0,
                width: "100%", // Full width as requested
                height: "3px",
                backgroundColor: "#000",
              }}
            />
          </Box>
        );
      }

      grid.push(
        <Box
          key={`unused-row-${i}`}
          sx={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          {row}
        </Box>
      );
    }

    return grid;
  };

  // Now we compose all the parts in the top-to-bottom order
  return (
    <Box sx={{ display: "flex", flexDirection: "column", mb: 2 }}>
      {/* Past guesses at the top */}
      {renderPastGuesses()}

      {/* Current guess in the middle - only show if we haven't reached the max guesses */}
      {guesses.length < 5 && renderCurrentGuess()}

      {/* Unused guesses at the bottom */}
      {renderUnusedGuesses()}
    </Box>
  );
};

export default GameGrid;
