import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";

const AsSeenInSection = () => {
  return (
    <Box
      sx={{
        width: "100%",
        maxWidth: "450px",
        marginX: "auto",
        textAlign: "center",
      }}
    >
      {/* <Typography
        variant="h6"
        sx={{
          fontWeight: 600,
          color: "#000000",
          whiteSpace: "nowrap",
        }}
      >
        As seen in:
      </Typography> */}

      <Box
        sx={{
          width: "100%",
          marginX: "auto",
          py: 4,
          px: 2,
          backgroundColor: "#FFFFFF", // Clean white background
          alignItems: "center",
          gap: { xs: 2, md: 3 }, // Responsive spacing
          borderRadius: "5px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center", // Add this line to center items
            gap: { xs: 2, md: 4 },
            flexWrap: "wrap",
          }}
        >
          {/* GeekWire */}
          <Link
            href="https://www.geekwire.com/2024/tech-vets-launch-their-own-game-company-and-create-wordle-style-puzzle-with-a-seattle-focus/"
            target="_blank"
            rel="noopener noreferrer"
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              textDecoration: "none",
              cursor: "pointer", // Ensure parent has pointer cursor
            }}
          >
            <Box
              component="img"
              src="https://cdn.geekwire.com/wp-content/uploads/2015/02/GeekWire-logo-transparent.png"
              alt="GeekWire Logo"
              sx={{
                height: { xs: "20px", md: "25px" },
                width: "auto",
                objectFit: "contain",
                cursor: "pointer", // Add pointer cursor to image
                pointerEvents: "none", // Prevent image from capturing pointer events
              }}
            />
          </Link>

          {/* Alameda Post with both logo and text */}
          <Link
            href="https://alamedapost.com/wordameda/"
            target="_blank"
            rel="noopener noreferrer"
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              textDecoration: "none",
              gap: 1,
              cursor: "pointer", // Ensure parent has pointer cursor
            }}
          >
            <Box
              component="img"
              src="/assets/customer-logos/ap-logo.png"
              alt="Alameda Post Logo"
              sx={{
                height: { xs: "20px", md: "25px" },
                width: "auto",
                objectFit: "contain",
                cursor: "pointer", // Add pointer cursor to image
                pointerEvents: "none", // Prevent image from capturing pointer events
              }}
            />
            <Box
              component="img"
              src="/assets/customer-logos/seen-in/alameda-post-text.svg"
              alt="Alameda Post Text"
              sx={{
                height: { xs: "20px", md: "25px" },
                width: "auto",
                objectFit: "contain",
                cursor: "pointer", // Add pointer cursor to SVG
                pointerEvents: "none", // Prevent SVG from capturing pointer events
              }}
            />
          </Link>
        </Box>
      </Box>
    </Box>
  );
};

export default AsSeenInSection;
