import React, { useState, useEffect } from "react";
import { Box, Typography, Button, Modal, IconButton } from "@mui/material";
import { X as CloseIcon, Send as SendIcon } from "lucide-react";
import ThumbUpIcon from "@mui/icons-material/ThumbUp";
import ThumbUpOutlinedIcon from "@mui/icons-material/ThumbUpOutlined";
import ThumbDownIcon from "@mui/icons-material/ThumbDown";
import ThumbDownOutlinedIcon from "@mui/icons-material/ThumbDownOutlined";
import SignupButton from "../games/WordyVerse/SignupButton";
import { motion, AnimatePresence } from "framer-motion";
import Confetti from "react-confetti";
import EmojiEventsIcon from "@mui/icons-material/EmojiEvents"; // Trophy icon

// Fun feedback messages for ratings
const POSITIVE_FEEDBACK = [
  "Awesome choice! You've got great taste!",
  "Woo-hoo! Thanks for the thumbs up!",
  "You rock! Thanks for the positive vibes!",
  "High five! We're glad you enjoyed it!",
  "Fantastic! Your feedback makes our day!",
];

const NEGATIVE_FEEDBACK = [
  "Thanks for the honesty! We'll do better next time!",
  "Gotcha! We'll mix it up tomorrow!",
  "Challenge accepted! We'll find better words!",
  "Your feedback helps us improve! Thanks!",
  "We hear you! Tomorrow's word will be better!",
];

export const ShareModal = ({
  open,
  onClose,
  onShare,
  guesses = [],
  word,
  maxGuesses = 5,
  isCorrect = false,
  correctColor,
  presentColor,
  absentColor,
  successBackgroundColor,
  shareButtonColor,
  onRateWord,
  isFirstSolver,
}) => {
  const isGameOver = guesses.length === maxGuesses || isCorrect;
  const [rating, setRating] = useState(null);
  const [hasVoted, setHasVoted] = useState(false);
  const [feedbackMessage, setFeedbackMessage] = useState("");
  const [showConfetti, setShowConfetti] = useState(isCorrect);
  const [confettiPieces, setConfettiPieces] = useState(200);

  // Check local storage to see if we've already shown the first solver animation
  const [hasShownFirstSolverAnim, setHasShownFirstSolverAnim] = useState(false);

  // Custom confetti colors for first solver (gold-focused)
  const firstSolverConfettiColors = [
    "#FFD700",
    "#FFA500",
    "#FFDF00",
    "#FFCC00",
    "#FFB700",
  ];
  const regularConfettiColors = [
    "#FFD700",
    "#FFA500",
    "#FF4500",
    "#FF6347",
    "#FF69B4",
  ];

  // When component mounts or isCorrect changes, show confetti for successful solving
  useEffect(() => {
    if (isCorrect && open) {
      setShowConfetti(true);
      setConfettiPieces(isFirstSolver ? 300 : 200); // More confetti for first solver

      // Gradually reduce confetti pieces for a fade-out effect
      const duration = isFirstSolver ? 6000 : 4000; // Longer celebration for first solver
      const fadeOutTime = 1000; // 1 second to fade out
      const fadeOutInterval = 50; // Update every 50ms
      const decrementSteps = fadeOutTime / fadeOutInterval;
      const decrementAmount = Math.ceil(
        (isFirstSolver ? 300 : 200) / decrementSteps
      );

      // Start the fade-out after the duration
      const timer = setTimeout(() => {
        const fadeInterval = setInterval(() => {
          setConfettiPieces((prev) => {
            const newValue = prev - decrementAmount;
            if (newValue <= 0) {
              clearInterval(fadeInterval);
              setShowConfetti(false);
              return 0;
            }
            return newValue;
          });
        }, fadeOutInterval);
      }, duration);

      return () => {
        clearTimeout(timer);
      };
    }
  }, [isCorrect, open, isFirstSolver]);

  const handleRating = (isPositive) => {
    setRating(isPositive);
    setHasVoted(true);

    // Set a random feedback message based on rating
    const messages = isPositive ? POSITIVE_FEEDBACK : NEGATIVE_FEEDBACK;
    const randomIndex = Math.floor(Math.random() * messages.length);
    setFeedbackMessage(messages[randomIndex]);

    if (onRateWord) {
      onRateWord(isPositive, isCorrect);
    }
  };

  return (
    <Modal open={open} onClose={onClose}>
      <Box
        sx={{
          position: "absolute",
          // top: "50%",
          marginTop: "450px",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: "90%",
          maxWidth: "400px",
          bgcolor: isCorrect
            ? isFirstSolver
              ? successBackgroundColor
              : successBackgroundColor
            : "white",
          borderRadius: "8px",
          p: 4,
          outline: "none",
          textAlign: "center",
          position: "relative",
          overflow: "hidden",
          boxShadow: isFirstSolver
            ? "0 10px 30px rgba(255, 215, 0, 0.3), 0 0 50px rgba(255, 215, 0, 0.2)"
            : "0 10px 25px rgba(0, 0, 0, 0.2)",
          border: isFirstSolver ? "2px solid #FFD700" : "none",
          animation: isFirstSolver ? "goldPulse 3s infinite" : "none",
          "@keyframes goldPulse": {
            "0%": { boxShadow: "0 0 20px rgba(255, 215, 0, 0.5)" },
            "50%": { boxShadow: "0 0 40px rgba(255, 215, 0, 0.8)" },
            "100%": { boxShadow: "0 0 20px rgba(255, 215, 0, 0.5)" },
          },
        }}
      >
        {showConfetti && (
          <Confetti
            width={400}
            height={800}
            recycle={true}
            numberOfPieces={confettiPieces}
            gravity={0.15}
            colors={
              isFirstSolver ? firstSolverConfettiColors : regularConfettiColors
            }
            opacity={confettiPieces < 100 ? confettiPieces / 100 : 1}
          />
        )}

        <IconButton
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: isCorrect ? presentColor[1] : "black",
            transition: "transform 0.2s ease",
            "&:hover": {
              transform: "rotate(90deg)",
              bgcolor: "rgba(255, 255, 255, 0.1)",
            },
          }}
        >
          <CloseIcon />
        </IconButton>

        {/* Show "Share before solving?" for non-game-over state */}
        {!isGameOver && (
          <>
            <motion.div
              initial={{ y: -20, opacity: 0 }}
              animate={{ y: 0, opacity: 1 }}
              transition={{ type: "spring", stiffness: 300 }}
            >
              <Typography
                variant="h5"
                sx={{ mb: 3, fontWeight: "bold", color: "black" }}
              >
                Share before solving?
              </Typography>
            </motion.div>
            <motion.div
              initial={{ scale: 0, opacity: 0, rotate: -10 }}
              animate={{ scale: 1, opacity: 1, rotate: 0 }}
              transition={{
                type: "spring",
                stiffness: 260,
                damping: 20,
                delay: 0.2,
              }}
            >
              <Box
                component="img"
                src={`${process.env.PUBLIC_URL}/assets/game-logos/wordy-verse-confused.svg`}
                alt="duck mascot"
                sx={{
                  width: "80px",
                  height: "80px",
                  mb: 2,
                  mx: "auto",
                  display: "block",
                }}
              />
            </motion.div>
          </>
        )}

        {/* Game Over states */}
        {isGameOver && (
          <>
            {/* First Solver Banner - only shown for first solver */}
            {isFirstSolver && (
              <motion.div
                initial={{ y: -50, opacity: 0 }}
                animate={{ y: 0, opacity: 1 }}
                transition={{
                  type: "spring",
                  stiffness: 300,
                  damping: 15,
                  delay: 0.1,
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    mb: 1,
                    background:
                      "linear-gradient(45deg, #FFD700 30%, #FFC400 90%)",
                    borderRadius: "20px",
                    py: 1,
                    boxShadow: "0 3px 10px rgba(255, 215, 0, 0.4)",
                    border: "2px solid rgba(255, 255, 255, 0.6)",
                  }}
                >
                  <motion.div
                    animate={{
                      rotate: [0, -10, 10, -5, 5, 0],
                      scale: [1, 1.2, 1.1, 1.15, 1.1, 1],
                    }}
                    transition={{
                      duration: 1.5,
                      repeat: Infinity,
                      repeatDelay: 3,
                    }}
                  >
                    <EmojiEventsIcon
                      sx={{
                        color: "#CC9900",
                        fontSize: 32,
                        mr: 1,
                        filter: "drop-shadow(0 2px 3px rgba(0,0,0,0.2))",
                      }}
                    />
                  </motion.div>
                  <Typography
                    variant="h6"
                    sx={{
                      fontWeight: "bold",
                      color: "#CC9900",
                      textShadow: "0 1px 2px rgba(255,255,255,0.6)",
                      letterSpacing: "0.5px",
                    }}
                  >
                    FIRST SOLVER!
                  </Typography>
                </Box>
              </motion.div>
            )}

            <motion.div
              initial={{ scale: 0.8, opacity: 0 }}
              animate={{ scale: 1, opacity: 1 }}
              transition={{ type: "spring", stiffness: 300, damping: 20 }}
            >
              <Typography
                variant="h5"
                sx={{
                  mb: 3,
                  fontWeight: "bold",
                  color: isCorrect ? "white" : "black",
                }}
              >
                {isCorrect ? "YOU SOLVED IT!" : "Better luck next time!"}
              </Typography>

              {!isCorrect && (
                <motion.div
                  initial={{ opacity: 0, y: -10 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ delay: 0.5, duration: 0.5 }}
                >
                  <Typography
                    variant="h6"
                    sx={{
                      mb: 3,
                      fontWeight: "normal",
                      color: "black",
                      fontSize: "1.1rem",
                    }}
                  >
                    The word was{" "}
                    <span
                      style={{ fontWeight: "bold", textTransform: "uppercase" }}
                    >
                      {word}
                    </span>
                  </Typography>
                </motion.div>
              )}
            </motion.div>

            {/* Score preview grid */}
            <motion.div
              initial={{ y: 20, opacity: 0 }}
              animate={{ y: 0, opacity: 1 }}
              transition={{ delay: 0.2, duration: 0.4 }}
            >
              <Box
                sx={{
                  mb: 3,
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <Box
                  sx={{
                    bgcolor: "white",
                    borderRadius: 1,
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: 0.75,
                    p: 2,
                    boxShadow: isFirstSolver
                      ? "0 0 15px rgba(255, 215, 0, 0.3)"
                      : "0px 4px 12px rgba(0, 0, 0, 0.15)",
                    border: isFirstSolver
                      ? "1px solid rgba(255, 215, 0, 0.5)"
                      : "none",
                  }}
                >
                  {guesses.map((guess, rowIndex) => (
                    <Box
                      key={rowIndex}
                      sx={{
                        display: "flex",
                        gap: 0.75,
                      }}
                    >
                      {Array.from(guess).map((letter, i) => (
                        <motion.div
                          key={i}
                          initial={{ rotateY: 90, opacity: 0 }}
                          animate={{ rotateY: 0, opacity: 1 }}
                          transition={{
                            delay: 0.3 + rowIndex * 0.1 + i * 0.05,
                            duration: 0.3,
                          }}
                        >
                          <Box
                            sx={{
                              width: 16,
                              border: "0.5px solid black",
                              height: 16,
                              bgcolor: (() => {
                                if (
                                  letter.toUpperCase() === word[i].toUpperCase()
                                )
                                  return correctColor[0];
                                if (
                                  word
                                    .toUpperCase()
                                    .includes(letter.toUpperCase())
                                )
                                  return presentColor[0];
                                return absentColor[0];
                              })(),
                              borderRadius: 0.5,
                            }}
                          />
                        </motion.div>
                      ))}
                    </Box>
                  ))}
                </Box>
              </Box>
            </motion.div>
          </>
        )}

        {/* Action Buttons */}
        <motion.div whileHover={{ scale: 1.05 }} whileTap={{ scale: 0.95 }}>
          <Button
            width="95%"
            startIcon={<SendIcon />}
            variant="contained"
            onClick={onShare}
            sx={{
              mb: 2,
              bgcolor: isFirstSolver ? "#FFD700" : shareButtonColor[0],
              borderRadius: "15px",
              border: isFirstSolver ? "1px solid #CC9900" : "1px white solid",
              color: isFirstSolver ? "#CC9900" : "white",
              fontWeight: isFirstSolver ? "bold" : "normal",
              transition: "all 0.2s ease",
              "&:hover": {
                bgcolor: isFirstSolver ? "#FFC400" : shareButtonColor[1],
                transform: "translateY(-2px)",
                boxShadow: isFirstSolver
                  ? "0 4px 12px rgba(255, 215, 0, 0.4)"
                  : "0 4px 8px rgba(0, 0, 0, 0.2)",
              },
            }}
          >
            {!isGameOver
              ? "Share this puzzle"
              : isFirstSolver
              ? "SHARE MY FIRST SOLVE! 🏆"
              : "SHARE MY SCORE"}
          </Button>
        </motion.div>

        {/* Rating Section */}
        {isGameOver && (
          <Box sx={{ mt: 3, mb: 2 }}>
            <AnimatePresence mode="wait">
              {!hasVoted ? (
                <motion.div
                  key="rating-prompt"
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  exit={{ opacity: 0, y: -20 }}
                  transition={{ duration: 0.3 }}
                >
                  <Typography
                    variant="h6"
                    sx={{
                      mb: 2,
                      fontWeight: "bold",
                      color: isCorrect ? "white" : "black",
                    }}
                  >
                    How was today's word?
                  </Typography>
                </motion.div>
              ) : (
                <motion.div
                  key="feedback-message"
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ duration: 0.3, delay: 0.2 }}
                >
                  <Typography
                    variant="h6"
                    sx={{
                      mb: 2,
                      fontWeight: "bold",
                      color: isCorrect ? "white" : "black",
                    }}
                  >
                    {feedbackMessage}
                  </Typography>
                </motion.div>
              )}
            </AnimatePresence>

            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                gap: 4,
                perspective: "1000px",
                height: "80px",
                alignItems: "center",
              }}
            >
              {/* Thumbs Up Button */}
              <motion.div
                whileTap={{ scale: 0.9 }}
                animate={
                  rating === true
                    ? {
                        scale: [1, 1.5, 1],
                        rotate: [0, 15, 0],
                        transition: { duration: 0.5 },
                      }
                    : {}
                }
              >
                <Box
                  component={hasVoted && rating !== true ? "div" : IconButton}
                  onClick={hasVoted ? undefined : () => handleRating(true)}
                  sx={{
                    bgcolor:
                      rating === true
                        ? "rgba(255, 255, 255, 0.3)"
                        : "transparent",
                    border: `2px solid ${isCorrect ? "white" : "#333"}`,
                    borderRadius: "50%",
                    p: 2,
                    width: "64px",
                    height: "64px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    ...(hasVoted && rating !== true
                      ? {
                          opacity: 0.5,
                        }
                      : {
                          cursor: "pointer",
                          "&:hover": {
                            bgcolor: isCorrect
                              ? "rgba(255, 255, 255, 0.2)"
                              : "rgba(0, 0, 0, 0.05)",
                          },
                        }),
                  }}
                >
                  {rating === true ? (
                    <ThumbUpIcon
                      style={{
                        color: isCorrect ? "white" : "#333",
                        fontSize: 32,
                      }}
                    />
                  ) : (
                    <ThumbUpOutlinedIcon
                      style={{
                        color: isCorrect ? "white" : "#333",
                        fontSize: 32,
                      }}
                    />
                  )}
                </Box>
              </motion.div>

              {/* Thumbs Down Button */}
              <motion.div
                whileTap={{ scale: 0.9 }}
                animate={
                  rating === false
                    ? {
                        scale: [1, 1.5, 1],
                        rotate: [0, -15, 0],
                        transition: { duration: 0.5 },
                      }
                    : {}
                }
              >
                <Box
                  component={hasVoted && rating !== false ? "div" : IconButton}
                  onClick={hasVoted ? undefined : () => handleRating(false)}
                  sx={{
                    bgcolor:
                      rating === false
                        ? "rgba(255, 255, 255, 0.3)"
                        : "transparent",
                    border: `2px solid ${isCorrect ? "white" : "#333"}`,
                    borderRadius: "50%",
                    p: 2,
                    width: "64px",
                    height: "64px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    ...(hasVoted && rating !== false
                      ? {
                          opacity: 0.5,
                        }
                      : {
                          cursor: "pointer",
                          "&:hover": {
                            bgcolor: isCorrect
                              ? "rgba(255, 255, 255, 0.2)"
                              : "rgba(0, 0, 0, 0.05)",
                          },
                        }),
                  }}
                >
                  {rating === false ? (
                    <ThumbDownIcon
                      style={{
                        color: isCorrect ? "white" : "#333",
                        fontSize: 32,
                      }}
                    />
                  ) : (
                    <ThumbDownOutlinedIcon
                      style={{
                        color: isCorrect ? "white" : "#333",
                        fontSize: 32,
                      }}
                    />
                  )}
                </Box>
              </motion.div>
            </Box>
          </Box>
        )}

        {/* First solver achievement display */}
        {isFirstSolver && isCorrect && (
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.5, duration: 0.5 }}
          >
            <Box
              sx={{
                mt: 2,
                pt: 2,
                borderTop: "1px solid rgba(255,215,0,0.5)",
              }}
            >
              <motion.div
                animate={{
                  scale: [1, 1.05, 1],
                }}
                transition={{
                  duration: 2,
                  repeat: Infinity,
                  repeatDelay: 1,
                }}
              >
                <Typography
                  variant="subtitle1"
                  sx={{
                    color: "rgba(255,215,0,0.9)",
                    fontWeight: "bold",
                    textShadow: "0 1px 2px rgba(0,0,0,0.3)",
                    letterSpacing: "0.5px",
                  }}
                >
                  🏆 Achievement Unlocked: First to solve today's puzzle! 🏆
                </Typography>
              </motion.div>
            </Box>
          </motion.div>
        )}
      </Box>
    </Modal>
  );
};

export const ShareButton = ({ onClick }) => (
  <motion.div whileHover={{ scale: 1.05 }} whileTap={{ scale: 0.95 }}>
    <Button
      onClick={onClick}
      startIcon={<SendIcon />}
      sx={{
        mt: 2,
        color: "#000",
        textDecoration: "underline",
        transition: "all 0.2s ease",
        "&:hover": {
          bgcolor: "transparent",
          transform: "translateY(-2px)",
        },
      }}
    >
      Send to a friend
    </Button>
  </motion.div>
);
