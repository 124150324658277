const COLORADOSUN_WORDS = [
  {
    date: "2025-03-10",
    hint: "This five-letter word is at the center of Colorado's debate about genetic transparency and reproductive rights.",
    word: "DONOR",
    characterCount: 5,
    url: "https://coloradosun.com/2025/03/10/colorado-sperm-donor-laws/",
  },
  {
    date: "2025-03-09",
    hint: "Colorado schools need billions more of this five-letter resource, according to advocacy groups eyeing a 2026 ballot measure.",
    word: "FUNDS",
    characterCount: 5,
    url: "https://coloradosun.com/2025/03/10/colorado-fully-funding-schools-get-it-done-campaign/",
  },
  {
    date: "2025-03-08",
    hint: "This five-letter word describes Jess Osborne's mountainside sanctuary where unwanted animals find safety and love.",
    word: "HAVEN",
    characterCount: 5,
    url: "https://coloradosun.com/2025/03/09/colorado-animals-tails-of-two-cities-sanctuary/",
  },
  {
    date: "2025-03-07",
    hint: "This five-letter word describes the judicial setting where sexual assault victims will gain more protection under Colorado's new law.",
    word: "COURT",
    characterCount: 5,
    url: "https://coloradosun.com/2025/03/10/colorado-rape-laws-change-2025/",
  },
  {
    date: "2025-03-06",
    hint: "This six-letter word describes the border fees causing a roller coaster ride for Colorado farmers and ranchers.",
    word: "TARIFF",
    characterCount: 6,
    url: "https://coloradosun.com/2025/03/08/trump-tariffs-colorado-farm-agriculture-beef/",
  },
];

export const getWordForDate = (date) => {
  if (!date || !COLORADOSUN_WORDS || !Array.isArray(COLORADOSUN_WORDS)) {
    return null;
  }

  // Convert to MT/Denver timezone
  const mtDate = new Date(
    date.toLocaleString("en-US", { timeZone: "America/Denver" })
  );

  // Get current date in MT
  const currentMTDate = new Date(
    new Date().toLocaleString("en-US", { timeZone: "America/Denver" })
  );

  // Reset time parts for accurate date comparison
  currentMTDate.setHours(0, 0, 0, 0);
  mtDate.setHours(0, 0, 0, 0);

  // If requested date is in the future, return null
  if (mtDate > currentMTDate) {
    return null;
  }

  // Format the MT date as YYYY-MM-DD
  const dateString =
    mtDate.getFullYear() +
    "-" +
    String(mtDate.getMonth() + 1).padStart(2, "0") +
    "-" +
    String(mtDate.getDate()).padStart(2, "0");

  return COLORADOSUN_WORDS.find((entry) => entry.date === dateString);
};

export const findLatestAvailableDate = () => {
  if (!COLORADOSUN_WORDS || !Array.isArray(COLORADOSUN_WORDS)) {
    return null;
  }

  // Get current date in MT
  const currentMTDate = new Date(
    new Date().toLocaleString("en-US", { timeZone: "America/Denver" })
  );
  currentMTDate.setHours(0, 0, 0, 0);

  // Create dates in MT timezone and filter out future dates
  const sortedEntries = COLORADOSUN_WORDS.map((entry) => ({
    date: new Date(entry.date + "T00:00:00-07:00"), // Force MT timezone
    original: entry,
  }))
    .filter((entry) => entry.date <= currentMTDate) // Filter out future dates
    .sort((a, b) => b.date - a.date);

  return sortedEntries[0]?.original;
};
