import {
  getWordForDate,
  findLatestAvailableDate,
} from "./Data/ColoradoSunData";
import BaseWordyGame from "../Components/BaseWordyGame";

const ColoradoSunDemoEmbed = () => {
  return (
    <BaseWordyGame
      title={"The Colorado Sun"}
      customTitle="The Colorado Sun Wordy"
      bannerPath={`${process.env.PUBLIC_URL}/assets/wordy-topics/sun-solver.svg`}
      shareText="The Oaklandside Wordy"
      shareUrl="https://coloradosun.com"
      getWordForDate={getWordForDate}
      findLatestAvailableDate={findLatestAvailableDate}
      correctColor={["#D43E1F", "white"]}
      presentColor={["#fcd232", "white"]}
      absentColor={["#000000", "white"]}
      keyboardBackgroundColor="#d3d6da"
      shareButtonColor={["#003da5", "#111111"]}
      successBackgroundColor="#004162"
      shareEmojiCorrect="🟥"
      shareEmojiPresent="🟨"
      shareEmojiAbsent="⬛"
      isEmbedded={true}
    />
  );
};

export default ColoradoSunDemoEmbed;
