export const SEATTLE_WORDS = [
  {
    date: "2025-02-10",
    hint: "This engineering marvel in Ballard allows boats to travel between different water levels",
    word: "LOCKS",
    characterCount: 5,
  },
  {
    date: "2025-02-09",
    hint: "This snow-capped peak is visible from Seattle on clear days, standing as Washington's third-highest mountain",
    word: "BAKER",
    characterCount: 5,
  },
  {
    date: "2025-02-08",
    hint: "This stadium, previously known as CenturyLink Field, hosts NFL games and major concerts",
    word: "LUMEN",
    characterCount: 5,
  },
  {
    date: "2025-02-07",
    hint: "Home to Seattle's first public swimming pool, this Central District park remains a community hub",
    word: "PRATT",
    characterCount: 5,
  },
  {
    date: "2025-02-06",
    hint: "Look for the iconic red neon sign of this historic bakery near the waterfront",
    word: "FRANZ",
    characterCount: 5,
  },
  {
    date: "2025-02-05",
    hint: "A major downtown avenue named for a pioneering family who arrived in the 1850s",
    word: "BOREN",
    characterCount: 5,
  },
  {
    date: "2025-02-04",
    hint: "A beloved local coffee chain that started in 1993 on Queen Anne Hill",
    word: "LADRO",
    characterCount: 5,
  },
  {
    date: "2025-02-03",
    hint: "This Capitol Hill restaurant puts an Asian twist on American fast food classics",
    word: "UMAMI",
    characterCount: 5,
  },
  {
    date: "2025-02-02",
    hint: "These flowers create stunning multicolored fields in the Skagit Valley each spring",
    word: "TULIP",
    characterCount: 5,
  },
  {
    date: "2025-02-01",
    hint: "This volcanic peak creates its own weather systems visible from Seattle",
    word: "BAKER",
    characterCount: 5,
  },
  {
    date: "2025-01-31",
    hint: "Seattle's WNBA team, four-time league champions",
    word: "STORM",
    characterCount: 5,
  },
  {
    date: "2025-01-30",
    hint: "These majestic birds can often be spotted fishing along the Puget Sound shoreline",
    word: "EAGLE",
    characterCount: 5,
  },
  {
    date: "2025-01-29",
    hint: "This Seattle Center museum celebrates popular culture and music history",
    word: "MOPOP",
    characterCount: 5,
  },
  {
    date: "2025-01-28",
    hint: "The weather condition Seattle is most famous for",
    word: "RAINY",
    characterCount: 5,
  },
  {
    date: "2025-01-27",
    hint: "This martial arts legend is buried in Lake View Cemetery on Capitol Hill",
    word: "BRUCE",
    characterCount: 5,
  },
  {
    date: "2025-01-26",
    hint: "These playful marine mammals can sometimes be spotted in Elliott Bay",
    word: "OTTER",
    characterCount: 5,
  },
  {
    date: "2025-01-25",
    hint: "This hill offers some of the best city views and was once Seattle's primary LGBTQ+ neighborhood",
    word: "QUEEN",
    characterCount: 5,
  },
  {
    date: "2025-01-24",
    hint: "This performing arts venue near Seattle Center was named for a telecommunications pioneer",
    word: "MCCAW",
    characterCount: 5,
  },
  {
    date: "2025-01-23",
    hint: "These large public art pieces can be found throughout Pioneer Square and the International District",
    word: "MURAL",
    characterCount: 5,
  },
  {
    date: "2025-01-22",
    hint: "This street on Capitol Hill is home to many popular restaurants and bars",
    word: "OLIVE",
    characterCount: 5,
  },
  {
    date: "2025-01-21",
    hint: "This Mariners baseball legend has a statue outside T-Mobile Park",
    word: "EDGAR",
    characterCount: 5,
  },
  {
    date: "2025-01-20",
    hint: "This iconic restaurant near Pike Place Market is known for its coconut cream pies",
    word: "THEPI",
    characterCount: 5,
  },
  {
    date: "2025-01-19",
    hint: "This South Lake Union museum preserves and shares local history",
    word: "MOHAI",
    characterCount: 5,
  },
  {
    date: "2025-01-18",
    hint: "This popular medical TV show was set in Seattle but filmed elsewhere",
    word: "GREYS",
    characterCount: 5,
  },
  {
    date: "2025-01-17",
    hint: "This local newspaper has been publishing since 1891",
    word: "TIMES",
    characterCount: 5,
  },
  {
    date: "2025-01-16",
    hint: "This gold rush trail connected Seattle to Alaska",
    word: "YUKON",
    characterCount: 5,
  },
  {
    date: "2025-01-15",
    hint: "UW's athletic teams are known by this mascot",
    word: "HUSKY",
    characterCount: 5,
  },
  {
    date: "2025-01-14",
    hint: "This minimalist coffee shop chain started in Capitol Hill",
    word: "VOAST",
    characterCount: 5,
  },
  {
    date: "2025-01-13",
    hint: "Seattle is known as a hub for this type of beer production",
    word: "CRAFT",
    characterCount: 5,
  },
];

export const getWordForDate = (date) => {
  if (!date || !SEATTLE_WORDS || !Array.isArray(SEATTLE_WORDS)) {
    return null;
  }

  // Convert to PT/Los Angeles timezone
  const ptDate = new Date(
    date.toLocaleString("en-US", { timeZone: "America/Los_Angeles" })
  );

  // Get current date in PT
  const currentPTDate = new Date(
    new Date().toLocaleString("en-US", { timeZone: "America/Los_Angeles" })
  );

  // Reset time parts for accurate date comparison
  currentPTDate.setHours(0, 0, 0, 0);
  ptDate.setHours(0, 0, 0, 0);

  // If requested date is in the future, return null
  if (ptDate > currentPTDate) {
    return null;
  }

  // Format the PT date as YYYY-MM-DD
  const dateString =
    ptDate.getFullYear() +
    "-" +
    String(ptDate.getMonth() + 1).padStart(2, "0") +
    "-" +
    String(ptDate.getDate()).padStart(2, "0");

  return SEATTLE_WORDS.find((entry) => entry.date === dateString);
};

export const findLatestAvailableDate = () => {
  if (!SEATTLE_WORDS || !Array.isArray(SEATTLE_WORDS)) {
    return null;
  }

  // Get current date in PT
  const currentPTDate = new Date(
    new Date().toLocaleString("en-US", { timeZone: "America/Los_Angeles" })
  );
  currentPTDate.setHours(0, 0, 0, 0);

  // Create dates in PT timezone and filter out future dates
  const sortedEntries = SEATTLE_WORDS.map((entry) => ({
    date: new Date(entry.date + "T00:00:00-08:00"), // Force PT timezone
    original: entry,
  }))
    .filter((entry) => entry.date <= currentPTDate) // Filter out future dates
    .sort((a, b) => b.date - a.date);

  return sortedEntries[0]?.original;
};
