import React from "react";
import { Box, Skeleton } from "@mui/material";

const LoadingSkeleton = () => {
  return (
    <Box
      sx={{
        textAlign: "center",
        p: 2,
        minHeight: "100vh",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        backgroundColor: "background.default",
        position: "relative",
      }}
    >
      {/* Header Skeleton (Logo + Title) */}
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "center",
          gap: 1,
          mb: 2,
        }}
      >
        <Skeleton variant="circular" width={54} height={54} />
        <Box sx={{ display: "flex", flexDirection: "column", gap: 0.5 }}>
          <Skeleton variant="text" width={200} height={35} />
          <Skeleton variant="text" width={150} height={20} />
        </Box>
      </Box>

      {/* Date Picker Skeleton */}
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          gap: 1,
          mb: 2,
        }}
      >
        <Skeleton variant="circular" width={32} height={32} />
        <Skeleton variant="text" width={180} height={25} />
        <Skeleton variant="circular" width={32} height={32} />
        <Skeleton variant="circular" width={24} height={24} />
      </Box>

      {/* Game Grid Skeleton (5 rows of 5) */}
      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: "repeat(5, 60px)", // Ensures exactly 5 columns
          gap: 0.5,
          mb: 2,
          justifyContent: "center", // Centers the grid
        }}
      >
        {[...Array(25)].map((_, index) => (
          <Skeleton
            key={index}
            variant="rectangular"
            width={60}
            height={60}
            sx={{ borderRadius: "4px" }}
          />
        ))}
      </Box>

      {/* Keyboard Skeleton (More accurate row spacing and key sizes) */}
      <Box sx={{ mt: 2 }}>
        {[
          ["Q", "W", "E", "R", "T", "Y", "U", "I", "O", "P"],
          ["A", "S", "D", "F", "G", "H", "J", "K", "L"],
          ["ENTER", "Z", "X", "C", "V", "B", "N", "M", "⌫"],
        ].map((row, i) => (
          <Box
            key={i}
            sx={{ display: "flex", justifyContent: "center", gap: 0.5, m: 0.5 }}
          >
            {row.map((key, index) => (
              <Skeleton
                key={index}
                variant="rectangular"
                width={key === "ENTER" || key === "⌫" ? 65 : 40}
                height={50}
                sx={{ borderRadius: "6px" }}
              />
            ))}
          </Box>
        ))}
      </Box>
    </Box>
  );
};

export default LoadingSkeleton;
