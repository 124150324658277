import React, { useEffect } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useLocation,
} from "react-router-dom";
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";

// Analytics
import { forceEnableForEmbed } from "./analytics";

// Themes
import theme from "./theme";
import seattleTimesTheme from "./games/WordyVerse/Demos/seattleTimesTheme";

// Routing Config
import { routes } from "./routesConfig";

// UI Components
import GameToolbar from "./games/GameToolbar";
import CookieBanner from "./utilities/CookieBanner";

const getTheme = (pathname) => {
  if (
    pathname.startsWith("/demo-preview") ||
    pathname.startsWith("/seattle-times-demo") ||
    pathname.startsWith("/alameda-post-demo") ||
    pathname.startsWith("/joco-keyword-demo") ||
    pathname.startsWith("/joco-wordy-demo") ||
    pathname.startsWith("/oaklandside-demo") ||
    pathname.startsWith("/colorado-sun") ||
    pathname.startsWith("/fcg/wordy")
  ) {
    return seattleTimesTheme;
  }
  return theme;
};

// Create a separate component for the router content
function AppRouter() {
  const location = useLocation();
  const isDemo =
    location.pathname === "/demo-preview" ||
    location.pathname === "/tools/demo-creator" ||
    location.pathname === "/seattle-times-demo" ||
    location.pathname === "/oaklandside-demo" ||
    location.pathname === "/colorado-sun" ||
    location.pathname === "/alameda-post-demo" ||
    location.pathname === "/joco-keyword-demo" ||
    location.pathname === "/joco-wordy-demo";
  const isCustomerGame = location.pathname.startsWith("/fcg/wordy") || isDemo;

  const searchParams = new URLSearchParams(location.search);
  const publicationId = searchParams.get("id") ?? null;

  useEffect(() => {
    if (isCustomerGame) {
      forceEnableForEmbed(publicationId);
    }
  }, [isCustomerGame]);

  return (
    <>
      <ThemeProvider theme={getTheme(location.pathname)}>
        {!isCustomerGame && (
          <>
            <GameToolbar />
          </>
        )}

        <Box sx={{ width: "100%" }}>
          <Routes>
            {routes.map((route, index) => (
              <Route key={index} path={route.path} element={route.element} />
            ))}
          </Routes>
        </Box>

        {!isCustomerGame && <CookieBanner />}
      </ThemeProvider>
    </>
  );
}

function App() {
  return (
    <Router basename={process.env.PUBLIC_URL}>
      <CssBaseline />
      <AppRouter />
    </Router>
  );
}

export default App;
