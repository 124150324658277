import React, { useState } from "react";
import { Box, Typography, IconButton, Tooltip } from "@mui/material";
import { ChevronLeft, ChevronRight, Lightbulb, HelpCircle } from "lucide-react";
import { keyframes } from "@mui/system";
import GameHint from "../../../../components/GameHint";
import { HintModal, HowToModal } from "../../../../components/GameHint";
import StreakCounter from "../../../../components/StreakCounter";
import { SEATTLE_TIMES } from "../../../../fcg/wordy/WordyGameEmbed";

const popIn = keyframes`
  0% { transform: scale(0.8); opacity: 0; }
  40% { transform: scale(1.1); opacity: 0.7; }
  100% { transform: scale(1); opacity: 1; }
`;

const GameControls = ({
  currentDate,
  onDateChange,
  showHint,
  onHintToggle,
  wordData,
  correctColor,
  presentColor,
  absentColor,
  streak,
  publicationId,
  isStreakActive,
  isFirstSolver,
}) => {
  const today = new Date();
  today.setHours(0, 0, 0, 0);

  // Create separate states for hint and how-to modals
  const [showHintModal, setShowHintModal] = useState(false);
  const [showHowToModal, setShowHowToModal] = useState(
    publicationId === SEATTLE_TIMES
  );

  // Handlers for each modal
  const toggleHintModal = () => setShowHintModal((prev) => !prev);
  const toggleHowToModal = () => setShowHowToModal((prev) => !prev);

  return (
    <>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          gap: 1,
          mb: 2,
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <IconButton
            onClick={() => onDateChange(-1)}
            onKeyDown={(e) => e.key === "Enter" && e.preventDefault()}
            tabIndex={-1}
          >
            <ChevronLeft />
          </IconButton>
          <Typography sx={{ mx: 2, color: "black" }}>
            {currentDate.toLocaleDateString("en-US", {
              month: "short",
              day: "numeric",
              year: "numeric",
            })}
          </Typography>
          {currentDate < today && ( // Hide ChevronRight if it's the latest date
            <IconButton
              onClick={() => onDateChange(1)}
              onKeyDown={(e) => e.key === "Enter" && e.preventDefault()}
              tabIndex={-1}
            >
              <ChevronRight />
            </IconButton>
          )}
        </Box>

        {/* Show Hint button only if there's no theme */}
        {!wordData.theme && (
          <Tooltip title="Need a hint?" arrow placement="top">
            <IconButton
              onClick={toggleHintModal}
              onKeyDown={(e) => e.key === "Enter" && e.preventDefault()}
              tabIndex={-1}
              sx={{
                color: "action.disabled",
                "&:hover": { color: "black" },
                transition: "color 0.3s ease",
              }}
            >
              <Lightbulb size={20} />
            </IconButton>
          </Tooltip>
        )}

        {/* Always show How To Play button */}
        <Tooltip title="How to play" arrow placement="top">
          <IconButton
            onClick={toggleHowToModal}
            onKeyDown={(e) => e.key === "Enter" && e.preventDefault()}
            tabIndex={-1}
            sx={{
              color: "action.disabled",
              "&:hover": { color: "black" },
              transition: "color 0.3s ease",
            }}
          >
            <HelpCircle size={24} />
          </IconButton>
        </Tooltip>

        {/* <StreakCounter
          streak={streak}
          isActive={isStreakActive}
          isFirstSolver={isFirstSolver}
          publicationId={publicationId}
        /> */}
      </Box>

      {/* Hint Modal - only shown when there's no theme and hint button is clicked */}
      {!wordData.theme && (
        <HintModal
          url={wordData.url}
          hint={wordData.hint}
          open={showHintModal}
          onClose={toggleHintModal}
        ></HintModal>
      )}

      {/* How To Modal - always available */}
      <HowToModal
        open={showHowToModal}
        onClose={toggleHowToModal}
        correctColor={correctColor}
        presentColor={presentColor}
        absentColor={absentColor}
      />
    </>
  );
};

export default GameControls;
