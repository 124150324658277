import React, { useState, useEffect, useCallback } from "react";
import {
  Box,
  Typography,
  Grid,
  Paper,
  Button,
  Container,
  useMediaQuery,
  IconButton,
  Snackbar,
  Alert,
  Stack,
  Tooltip,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import RefreshIcon from "@mui/icons-material/Refresh";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import { generateSolvablePuzzle } from "./puzzleGenerator";
import { WEATHER_SYMBOLS, EMPTY_CELL } from "./solver";
import { keyframes } from "@mui/system";

// Animation keyframes
const popIn = keyframes`
  0% { transform: scale(1); }
  50% { transform: scale(1.1); }
  100% { transform: scale(1); }
`;

const shake = keyframes`
  0%, 100% { transform: translateX(0); }
  25% { transform: translateX(-4px); }
  75% { transform: translateX(4px); }
`;

const celebrate = keyframes`
  0% { transform: scale(1) rotate(0); opacity: 1; }
  25% { transform: scale(1.2) rotate(-5deg); opacity: 0.8; }
  75% { transform: scale(1.2) rotate(5deg); opacity: 0.8; }
  100% { transform: scale(1) rotate(0); opacity: 1; }
`;

const settleIn = keyframes`
  0% { transform: scale(1.1); }
  20% { transform: scale(0.95); }
  60% { transform: scale(1.03); }
  100% { transform: scale(1); }
`;

const victoryGlow = keyframes`
  0% { box-shadow: 0 0 5px rgba(0, 255, 0, 0.5); }
  50% { box-shadow: 0 0 20px rgba(0, 255, 0, 0.8); }
  100% { box-shadow: 0 0 5px rgba(0, 255, 0, 0.5); }
`;

// Weather API configuration
const WEATHER_API_KEY = "af4ee06fe2e144199f5230125251403";
const WEATHER_API_URL = "https://api.weatherapi.com/v1/current.json";

const WeatherWiz = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  // Game state
  const [grid, setGrid] = useState([]);
  const [solution, setSolution] = useState(null);
  const [initialCells, setInitialCells] = useState(new Set());
  const [selectedSymbol, setSelectedSymbol] = useState(WEATHER_SYMBOLS[0]);
  const [message, setMessage] = useState("");
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [gameWon, setGameWon] = useState(false);
  const [errors, setErrors] = useState({ rows: new Set(), cols: new Set() });
  const [lastPlaced, setLastPlaced] = useState(null);
  const [celebrating, setCelebrating] = useState(false);
  const [startTime, setStartTime] = useState(null);
  const [moveCount, setMoveCount] = useState(0);
  const [elapsedTime, setElapsedTime] = useState(0);
  const [gameStarted, setGameStarted] = useState(false);
  const [mistakeCount, setMistakeCount] = useState(0);
  const [symbolCounts, setSymbolCounts] = useState({});
  const [currentWeather, setCurrentWeather] = useState(null);

  // Define startNewGame before using it in useEffect
  const startNewGame = () => {
    const { puzzle, solution } = generateSolvablePuzzle();
    setGrid(puzzle);
    setSolution(solution);

    // Track initial cell positions
    const initialPositions = new Set();
    puzzle.forEach((row, rowIndex) => {
      row.forEach((cell, colIndex) => {
        if (cell !== EMPTY_CELL) {
          initialPositions.add(`${rowIndex},${colIndex}`);
        }
      });
    });
    setInitialCells(initialPositions);

    setMistakeCount(0);
    setGameStarted(false);
    setGameWon(false);
    setElapsedTime(0);
    setMessage("");
    setErrors({ rows: new Set(), cols: new Set() });
    setLastPlaced(null);
    setCelebrating(false);
    setStartTime(null);
    setMoveCount(0);
  };

  // Initialize the game
  useEffect(() => {
    startNewGame();
  }, []);

  // Timer effect - only start when first move is made
  useEffect(() => {
    if (!gameWon && gameStarted) {
      const timer = setInterval(() => {
        const now = Date.now();
        setElapsedTime(Math.floor((now - startTime) / 1000));
      }, 1000);
      return () => clearInterval(timer);
    }
  }, [startTime, gameWon, gameStarted]);

  // Update symbol counts whenever the grid changes
  useEffect(() => {
    const counts = {};
    WEATHER_SYMBOLS.forEach((symbol) => (counts[symbol] = 0));

    grid.forEach((row) => {
      row.forEach((cell) => {
        if (WEATHER_SYMBOLS.includes(cell)) {
          counts[cell]++;
        }
      });
    });

    setSymbolCounts(counts);
  }, [grid]);

  // Fetch current weather
  useEffect(() => {
    const fetchWeather = async () => {
      try {
        const response = await fetch(
          `${WEATHER_API_URL}?key=${WEATHER_API_KEY}&q=Seattle&aqi=no`
        );
        const data = await response.json();
        setCurrentWeather(data);
      } catch (error) {
        console.error("Error fetching weather:", error);
      }
    };
    fetchWeather();
  }, []);

  // Map weather condition to emoji
  const getWeatherEmoji = (condition) => {
    if (!condition) return "☁️";
    const code = condition.code;
    // Clear
    if (code === 1000) return "☀️";
    // Cloudy conditions
    if (code >= 1003 && code <= 1030) return "☁️";
    // Rainy conditions
    if (code >= 1063 && code <= 1201) return "🌧️";
    return "☁️";
  };

  // Check for duplicates in a row or column
  const findDuplicates = (cells) => {
    const symbols = cells.filter((cell) => WEATHER_SYMBOLS.includes(cell));
    const counts = {};
    symbols.forEach((symbol) => {
      counts[symbol] = (counts[symbol] || 0) + 1;
    });
    return Object.values(counts).some((count) => count > 1);
  };

  // Check if a row or column is complete (has exactly 5 valid symbols with no duplicates)
  const checkLineCompletion = (line) => {
    const symbols = line.filter((cell) => WEATHER_SYMBOLS.includes(cell));
    if (symbols.length !== 5) return false;

    const uniqueSymbols = new Set(symbols);
    return uniqueSymbols.size === symbols.length;
  };

  // Update error states and check for completed lines
  const updateErrors = (newGrid) => {
    const prevErrors = errors.rows.size + errors.cols.size;
    const newErrors = { rows: new Set(), cols: new Set() };

    // Check rows
    for (let i = 0; i < 5; i++) {
      if (findDuplicates(newGrid[i])) {
        newErrors.rows.add(i);
      }
    }

    // Check columns
    for (let j = 0; j < 5; j++) {
      const column = newGrid.map((row) => row[j]);
      if (findDuplicates(column)) {
        newErrors.cols.add(j);
      }
    }

    // Count new mistakes
    const currentErrors = newErrors.rows.size + newErrors.cols.size;
    if (currentErrors > prevErrors) {
      setMistakeCount((prev) => prev + (currentErrors - prevErrors));
    }

    setErrors(newErrors);

    return newErrors;
  };

  // Handle cell click with enhanced feedback
  const handleCellClick = (rowIndex, colIndex) => {
    if (gameWon) return;

    // Check if this is an initial cell
    if (initialCells.has(`${rowIndex},${colIndex}`)) {
      setMessage("Can't modify initial puzzle cells!");
      setOpenSnackbar(true);
      return;
    }

    // Start the game timer on first move
    if (!gameStarted) {
      setGameStarted(true);
      setStartTime(Date.now());
    }

    const newGrid = [...grid.map((row) => [...row])];
    const currentValue = newGrid[rowIndex][colIndex];
    const newValue =
      currentValue === selectedSymbol ? EMPTY_CELL : selectedSymbol;

    // Update the cell with the new value
    newGrid[rowIndex][colIndex] = newValue;
    setGrid(newGrid);
    setLastPlaced({ row: rowIndex, col: colIndex });
    setMoveCount((prev) => prev + 1);

    // Check for errors and win condition
    const newErrors = updateErrors(newGrid);
    if (newErrors.rows.size === 0 && newErrors.cols.size === 0) {
      checkWinCondition(newGrid);
    }
  };

  // Check if the game is won
  const checkWinCondition = (currentGrid) => {
    // Count each symbol
    const symbolCounts = {};
    WEATHER_SYMBOLS.forEach((symbol) => {
      symbolCounts[symbol] = 0;
    });

    currentGrid.forEach((row) => {
      row.forEach((cell) => {
        if (WEATHER_SYMBOLS.includes(cell)) {
          symbolCounts[cell]++;
        }
      });
    });

    const isComplete = WEATHER_SYMBOLS.every(
      (symbol) => symbolCounts[symbol] === 5
    );

    if (isComplete && errors.rows.size === 0 && errors.cols.size === 0) {
      const endTime = Date.now();
      const timeElapsed = Math.floor((endTime - startTime) / 1000);

      setGameWon(true);
      setCelebrating(true);
      setMessage(`Solved in ${timeElapsed}s with ${moveCount} moves!`);
      setOpenSnackbar(true);

      // Here we could save the score to localStorage or a backend
      const score = {
        timeElapsed,
        moveCount,
        date: new Date().toISOString(),
      };
      const scores = JSON.parse(
        localStorage.getItem("weatherWizScores") || "[]"
      );
      scores.push(score);
      scores.sort((a, b) => a.timeElapsed - b.timeElapsed);
      localStorage.setItem(
        "weatherWizScores",
        JSON.stringify(scores.slice(0, 10))
      ); // Keep top 10
    }
  };

  // Cell component with enhanced visuals
  const Cell = ({ value, onClick, rowIndex, colIndex }) => {
    const hasError = errors.rows.has(rowIndex) || errors.cols.has(colIndex);
    const isLastPlaced =
      lastPlaced?.row === rowIndex && lastPlaced?.col === colIndex;
    const isInitialCell = initialCells.has(`${rowIndex},${colIndex}`);

    const cellStyle = {
      width: isMobile ? "52px" : "65px",
      height: isMobile ? "52px" : "65px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      cursor: gameWon || isInitialCell ? "default" : "pointer",
      fontSize: isMobile ? "24px" : "28px",
      fontWeight: "bold",
      backgroundColor: isInitialCell ? "#f0f0f0" : "#fff",
      border: "1px solid rgba(0, 0, 0, 0.2)",
      borderRadius: "4px",
      transition: "all 0.2s ease",
      position: "relative",
      animation: isLastPlaced
        ? `${settleIn} 0.4s ease`
        : hasError
        ? `${shake} 0.3s ease`
        : gameWon
        ? `${celebrate} 1s ease forwards`
        : "none",
      ...(hasError && {
        backgroundColor: isInitialCell ? "#f0f0f0" : "rgba(255, 0, 0, 0.1)",
        borderColor: "red",
      }),
      ...(gameWon && {
        animation: `${celebrate} 1s ease, ${victoryGlow} 2s ease-in-out infinite`,
        backgroundColor: isInitialCell ? "#f0f0f0" : "rgba(255, 255, 200, 0.9)",
        borderColor: "#4CAF50",
        borderWidth: "2px",
      }),
    };

    return (
      <Paper
        elevation={3}
        style={cellStyle}
        onClick={() => !gameWon && onClick(rowIndex, colIndex)}
      >
        {value}
      </Paper>
    );
  };

  return (
    <Container maxWidth="sm" sx={{ pt: 1, pb: 1 }}>
      <Box sx={{ textAlign: "center", mb: 1 }}>
        <Box
          sx={{
            mb: 2,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            gap: 0.5,
          }}
        >
          <Typography
            variant="h4"
            component="h1"
            sx={{
              fontFamily: '"Times New Roman", Times, serif',
              fontSize: isMobile ? "2.2rem" : "2.8rem",
              letterSpacing: 1,
              mb: 0.5,
              ...(gameWon && {
                color: "#4CAF50",
                textShadow: "0 0 10px rgba(76, 175, 80, 0.3)",
              }),
            }}
          >
            WeatherWiz
          </Typography>
          <Typography
            variant="caption"
            sx={{
              fontFamily: "monospace",
              fontSize: isMobile ? "0.8rem" : "1rem",
              color: "text.secondary",
              borderBottom: "1px solid #ccc",
              px: 2,
              pb: 0.5,
            }}
          >
            {new Date().toLocaleDateString("en-US", {
              weekday: "long",
              year: "numeric",
              month: "long",
              day: "numeric",
            })}
          </Typography>
          {currentWeather && (
            <Typography
              variant="body1"
              sx={{
                fontStyle: "italic",
                color:
                  currentWeather.current.condition.code === 1000
                    ? "orange"
                    : currentWeather.current.condition.code >= 1063
                    ? "blue"
                    : "gray",
                mt: 0.5,
                fontSize: isMobile ? "0.9rem" : "1.1rem",
                fontWeight: "bold",
              }}
            >
              Seattle • {currentWeather.current.condition.text}{" "}
              {getWeatherEmoji(currentWeather.current.condition)}{" "}
              {currentWeather.current.temp_f}°F
            </Typography>
          )}
        </Box>

        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            gap: 1,
            mb: 2,
          }}
        >
          <Typography
            variant="body1"
            sx={{
              fontFamily: '"Times New Roman", Times, serif',
              fontSize: isMobile ? "1rem" : "1.2rem",
            }}
          >
            Fill the grid with weather symbols!
          </Typography>
          <Tooltip
            title={
              <Typography>
                Rules:
                <br />
                1. Each row must have one of each symbol
                <br />
                2. Each column must have one of each symbol
              </Typography>
            }
          >
            <IconButton size="small">
              <HelpOutlineIcon />
            </IconButton>
          </Tooltip>
        </Box>
      </Box>

      <Box sx={{ display: "flex", gap: 2, mb: 3 }}>
        <Box
          sx={{
            p: 1.5,
            border: "1px solid #ccc",
            borderRadius: 2,
            flex: 1,
            backgroundColor: "#f5f5f5",
          }}
        >
          <Typography
            variant="h6"
            sx={{ fontFamily: "monospace", fontSize: "1.4rem", mb: 0.5 }}
          >
            {Math.floor(elapsedTime / 60)}:
            {(elapsedTime % 60).toString().padStart(2, "0")}
          </Typography>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              fontSize: "1rem",
            }}
          >
            <Typography variant="body1">Moves: {moveCount}</Typography>
            <Typography
              variant="body1"
              color={mistakeCount > 0 ? "error.main" : "text.secondary"}
            >
              Mistakes: {mistakeCount}
            </Typography>
          </Box>
        </Box>
      </Box>

      <Stack
        direction="row"
        spacing={2}
        justifyContent="center"
        alignItems="center"
        sx={{ mb: 3 }}
      >
        {WEATHER_SYMBOLS.map((symbol, index) => (
          <Box key={index} sx={{ textAlign: "center" }}>
            <Button
              variant={selectedSymbol === symbol ? "contained" : "outlined"}
              onClick={() => !gameWon && setSelectedSymbol(symbol)}
              disabled={gameWon || symbolCounts[symbol] >= 5}
              sx={{
                minWidth: "60px",
                height: "60px",
                borderRadius: "12px",
                fontSize: "24px",
                p: 0,
                transform:
                  selectedSymbol === symbol && !gameWon
                    ? "scale(1.1)"
                    : "scale(1)",
                opacity: gameWon || symbolCounts[symbol] >= 5 ? 0.7 : 1,
              }}
            >
              {symbol}
              <Typography
                variant="caption"
                sx={{
                  position: "absolute",
                  bottom: -24,
                  left: "50%",
                  transform: "translateX(-50%)",
                  fontSize: "1rem",
                  color:
                    symbolCounts[symbol] >= 5
                      ? "success.main"
                      : "text.secondary",
                  fontWeight: symbolCounts[symbol] >= 5 ? "bold" : "normal",
                }}
              >
                {symbolCounts[symbol] || 0}/5
              </Typography>
            </Button>
          </Box>
        ))}
      </Stack>

      <Grid
        container
        spacing={0.75}
        justifyContent="center"
        sx={{
          maxWidth: isMobile ? "320px" : "400px",
          margin: "0 auto",
          mb: 3,
        }}
      >
        {grid.map((row, rowIndex) => (
          <Grid item xs={12} key={rowIndex}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                gap: 1.5,
              }}
            >
              {row.map((cell, colIndex) => (
                <Cell
                  key={colIndex}
                  value={cell}
                  onClick={handleCellClick}
                  rowIndex={rowIndex}
                  colIndex={colIndex}
                />
              ))}
            </Box>
          </Grid>
        ))}
      </Grid>

      <Box sx={{ display: "flex", justifyContent: "center", mb: 2 }}>
        <IconButton
          onClick={startNewGame}
          size="large"
          sx={{
            width: "55px",
            height: "55px",
            backgroundColor: gameWon ? "#4CAF50" : theme.palette.primary.main,
            color: "white",
            transform: gameWon ? "scale(1.1)" : "scale(1)",
            "&:hover": {
              backgroundColor: gameWon ? "#45a049" : theme.palette.primary.dark,
            },
          }}
        >
          <RefreshIcon sx={{ fontSize: "2rem" }} />
        </IconButton>
      </Box>

      <Snackbar
        open={openSnackbar}
        autoHideDuration={gameWon ? 5000 : 3000}
        onClose={() => setOpenSnackbar(false)}
      >
        <Alert
          onClose={() => setOpenSnackbar(false)}
          severity={gameWon ? "success" : "error"}
          sx={{
            width: "100%",
            ...(gameWon && {
              backgroundColor: "#4CAF50",
              color: "white",
              "& .MuiAlert-icon": {
                color: "white",
              },
            }),
          }}
        >
          {gameWon
            ? `Puzzle solved in ${elapsedTime}s with ${moveCount} moves and ${mistakeCount} mistakes!`
            : message}
        </Alert>
      </Snackbar>
    </Container>
  );
};

export default WeatherWiz;
