// Utility functions for managing game streaks using UTC for storage
const STREAK_KEY = "wordyverse_streak";
const LAST_PLAY_KEY = "wordyverse_last_play";
const TIMEZONE = "America/Los_Angeles"; // Pacific Time

export const getStreakKey = (publicationId) => {
  if (!publicationId) {
    return STREAK_KEY;
  }
  return `${STREAK_KEY}-${publicationId}`;
};

export const getLastPlayKey = (publicationId) => {
  if (!publicationId) {
    return LAST_PLAY_KEY;
  }
  return `${LAST_PLAY_KEY}-${publicationId}`;
};

/**
 * Get the current date in Pacific Time as a day-only date object
 * @returns {Date} Date object representing today in PT, at start of day
 */
const getTodayInPT = () => {
  // Get current date in PT timezone
  const now = new Date();
  const ptString = now.toLocaleDateString("en-US", { timeZone: TIMEZONE });
  // Parse as midnight on that day
  const [month, day, year] = ptString.split("/").map((n) => parseInt(n, 10));
  return new Date(year, month - 1, day);
};

/**
 * Convert a UTC timestamp to a Pacific Time day
 * @param {number} timestamp - UTC timestamp in milliseconds
 * @returns {Date} Date object representing that day in PT, at start of day
 */
const utcToPTDay = (timestamp) => {
  const date = new Date(timestamp);
  const ptString = date.toLocaleDateString("en-US", { timeZone: TIMEZONE });
  const [month, day, year] = ptString.split("/").map((n) => parseInt(n, 10));
  return new Date(year, month - 1, day);
};

/**
 * Get streak information from local storage
 */
export const getStreakFromStorage = (publicationId) => {
  try {
    const streakKey = getStreakKey(publicationId);
    const lastPlayKey = getLastPlayKey(publicationId);

    const currentStreak = parseInt(localStorage.getItem(streakKey) || "0");
    const lastPlayTimestamp = localStorage.getItem(lastPlayKey);

    // If there's no last play timestamp, return 0 streak
    if (!lastPlayTimestamp) {
      return { count: 0, lastPlayDate: null };
    }

    // Get today in PT
    const todayPT = getTodayInPT();

    // Convert the stored UTC timestamp to a PT day
    const lastPlayPT = utcToPTDay(parseInt(lastPlayTimestamp, 10));

    // Calculate days since last play
    const diffTime = todayPT.getTime() - lastPlayPT.getTime();
    const diffDays = Math.floor(diffTime / (1000 * 60 * 60 * 24));

    // If it's been more than 1 day since last play, reset the streak
    if (diffDays > 1) {
      localStorage.removeItem(streakKey);
      return { count: 0, lastPlayDate: lastPlayTimestamp };
    }

    // Otherwise return the current streak
    return { count: currentStreak, lastPlayDate: lastPlayTimestamp };
  } catch (e) {
    console.error("Error reading streak from storage:", e);
    return { count: 0, lastPlayDate: null };
  }
};

/**
 * Update the streak based on current play
 */
export const updateStreak = (currentDate, publicationId) => {
  try {
    const { count, lastPlayDate } = getStreakFromStorage(publicationId);

    // Get today in PT - use this for lastPlayKey instead of currentDate
    const todayPT = getTodayInPT();
    const todayTimestamp = todayPT.getTime();

    // Store current timestamp in UTC (for other logic)
    const streakKey = getStreakKey(publicationId);
    const lastPlayKey = getLastPlayKey(publicationId);

    // If this is their first play
    if (!lastPlayDate) {
      localStorage.setItem(streakKey, "1");
      localStorage.setItem(lastPlayKey, todayTimestamp.toString());
      return 1;
    }

    // Convert the stored UTC timestamp to a PT day
    const lastPlayPT = utcToPTDay(parseInt(lastPlayDate, 10));

    // Calculate days since last play
    const diffTime = todayPT.getTime() - lastPlayPT.getTime();
    const diffDays = Math.round(diffTime / (1000 * 60 * 60 * 24));

    // If played same day, maintain streak without increment
    if (diffDays === 0) {
      // Update the timestamp to today in PT
      localStorage.setItem(lastPlayKey, todayTimestamp.toString());
      return count;
    }

    // If played consecutive day, increment streak
    if (diffDays === 1) {
      const newStreak = count + 1;
      localStorage.setItem(streakKey, newStreak.toString());
      localStorage.setItem(lastPlayKey, todayTimestamp.toString());
      return newStreak;
    }

    // If missed a day or played on a previous day, reset streak
    localStorage.setItem(streakKey, "1");
    localStorage.setItem(lastPlayKey, todayTimestamp.toString());
    return 1;
  } catch (e) {
    console.error("Error updating streak:", e);
    return 0;
  }
};

/**
 * Check if the streak is currently active
 */
export const getIsStreakActive = (publicationId) => {
  try {
    const { count, lastPlayDate } = getStreakFromStorage(publicationId);

    // If there's no streak or no last play date, streak is not active
    if (count === 0 || !lastPlayDate) {
      return false;
    }

    // Get today in PT
    const todayPT = getTodayInPT();

    // Get yesterday in PT
    const yesterdayPT = new Date(todayPT);
    yesterdayPT.setUTCDate(yesterdayPT.getUTCDate() - 1);

    // Convert the stored UTC timestamp to a PT day
    const lastPlayPT = utcToPTDay(parseInt(lastPlayDate, 10));

    // Check if the last play was today or yesterday
    const sameDay = (date1, date2) => {
      return (
        date1.getUTCFullYear() === date2.getUTCFullYear() &&
        date1.getUTCMonth() === date2.getUTCMonth() &&
        date1.getUTCDate() === date2.getUTCDate()
      );
    };
    const playedToday = sameDay(lastPlayPT, todayPT);
    const playedYesterday = sameDay(lastPlayPT, yesterdayPT);

    // Streak is active if player played today
    return playedToday;
  } catch (e) {
    console.error("Error checking if streak is active:", e);
    return false;
  }
};
