export const processPublicationData = (publicationData) => {
  if (!publicationData || !Array.isArray(publicationData)) {
    return {
      getWordForDate: () => null,
      findLatestAvailableDate: () => null,
    };
  }

  // Convert response dates to a normalized format (YYYY-MM-DD)
  const formattedData = publicationData.map((entry) => ({
    ...entry,
    date: new Date(entry.date + "T00:00:00-08:00"), // Assume PT timezone
  }));

  const getWordForDate = (date) => {
    if (!date) return null;

    // Convert to PT timezone
    const ptDate = new Date(
      date.toLocaleString("en-US", { timeZone: "America/Los_Angeles" })
    );

    // Create date string in YYYY-MM-DD format to ignore time
    const datePTString = [
      ptDate.getFullYear(),
      (ptDate.getMonth() + 1).toString().padStart(2, "0"),
      ptDate.getDate().toString().padStart(2, "0"),
    ].join("-");

    // Get current PT date string
    const now = new Date();
    const currentPTDate = new Date(
      now.toLocaleString("en-US", { timeZone: "America/Los_Angeles" })
    );
    const currentPTString = [
      currentPTDate.getFullYear(),
      (currentPTDate.getMonth() + 1).toString().padStart(2, "0"),
      currentPTDate.getDate().toString().padStart(2, "0"),
    ].join("-");

    // If the date is in the future, return null
    if (datePTString > currentPTString) return null;

    // Find the entry for the requested date
    return formattedData.find((entry) => {
      const entryDate = new Date(entry.date);
      const entryDateString = [
        entryDate.getFullYear(),
        (entryDate.getMonth() + 1).toString().padStart(2, "0"),
        entryDate.getDate().toString().padStart(2, "0"),
      ].join("-");

      return entryDateString === datePTString;
    });
  };

  const findLatestAvailableDate = () => {
    // Get current PT date (just year, month, day)
    const now = new Date();
    const currentPTDate = new Date(
      now.toLocaleString("en-US", { timeZone: "America/Los_Angeles" })
    );

    // Create date string in YYYY-MM-DD format to ignore time
    const currentDateString = [
      currentPTDate.getFullYear(),
      (currentPTDate.getMonth() + 1).toString().padStart(2, "0"),
      currentPTDate.getDate().toString().padStart(2, "0"),
    ].join("-");

    // Filter by comparing normalized date strings
    const latestEntry = formattedData
      .filter((entry) => {
        const entryDate = new Date(entry.date);
        const entryDateString = [
          entryDate.getFullYear(),
          (entryDate.getMonth() + 1).toString().padStart(2, "0"),
          entryDate.getDate().toString().padStart(2, "0"),
        ].join("-");

        // Compare date strings (YYYY-MM-DD format)
        return entryDateString <= currentDateString;
      })
      .sort((a, b) => new Date(b.date) - new Date(a.date))[0];

    return latestEntry || null;
  };

  return { getWordForDate, findLatestAvailableDate };
};
