import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";

const FeatureCard = ({ icon, title, description }) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        p: 3,
        backgroundColor: "white",
        borderRadius: "8px",
        height: "100%",
      }}
    >
      <Box
        component="img"
        src={icon}
        alt={`${title} icon`}
        sx={{ width: "24px", height: "24px", mb: 2 }}
      />
      <Typography
        variant="h5"
        sx={{ fontWeight: 700, mb: 1.5, textAlign: "left" }}
      >
        {title}
      </Typography>
      <Typography variant="body1" sx={{ textAlign: "left" }}>
        {description}
      </Typography>
    </Box>
  );
};

const WorkWithUs = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const features = [
    {
      icon: `${process.env.PUBLIC_URL}/assets/icons/stars/red-star.svg`,
      title: "Seamless setup",
      description:
        "We make adding games to your site hassle free - no new system to manage or technical knowledge required!",
    },
    {
      icon: `${process.env.PUBLIC_URL}/assets/icons/stars/blue-star.svg`,
      title: "Local focus",
      description:
        "We focus exclusively on games tailored to engaging and growing your community.",
    },
    {
      icon: `${process.env.PUBLIC_URL}/assets/icons/stars/gold-star.svg`,
      title: "Full support",
      description:
        "We are human-driven so you can rely on fast response times and thoughtful collaboration.",
    },
  ];

  return (
    <Box
      sx={{
        py: 6,
        px: { xs: 2, md: 4 },
        backgroundColor: "#f4f0df",
        width: "100%",
      }}
    >
      <Box
        sx={{
          maxWidth: "1200px",
          mx: "auto",
        }}
      >
        {/* Section Title */}
        <Typography
          variant="h2"
          component="h2"
          sx={{
            fontWeight: 800,
            fontSize: { xs: "2rem", sm: "2.5rem", md: "3rem" },
            mb: { xs: 3, md: 4 },
            textAlign: "center",
          }}
        >
          Work with us
        </Typography>
        {/* Feature Cards */}
        <Grid container spacing={3} sx={{ mb: 5 }}>
          {features.map((feature, index) => (
            <Grid item xs={12} md={4} key={index}>
              <FeatureCard
                icon={feature.icon}
                title={feature.title}
                description={feature.description}
              />
            </Grid>
          ))}
        </Grid>

        {/* CTA Button */}
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <Button
            variant="contained"
            href="https://cal.com/calli-fuchigami/15min"
            target="_blank"
            rel="noopener noreferrer"
            sx={{
              textTransform: "none",
              fontWeight: 600,
              borderRadius: 28,
              px: 5,
              py: 1.5,
              backgroundColor: "black",
              color: "white",
              "&:hover": {
                backgroundColor: "#333",
              },
            }}
          >
            Contact sales
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default WorkWithUs;
