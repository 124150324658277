import {
  getWordForDate,
  findLatestAvailableDate,
} from "./Data/SeattleTimesData";
import BaseWordyGame from "../Components/BaseWordyGame";

const SeattleTimesDemoEmbed = () => {
  return (
    <BaseWordyGame
      title={"The Seattle Times"}
      customTitle="The Seattle Times Wordy"
      iconPath={`${process.env.PUBLIC_URL}/assets/wordy-topics/seattle-times-logo-small.svg`}
      shareText="Burien Local Lingo"
      shareUrl="https://flyingcometgames.com/wordy-verse/burien"
      getWordForDate={getWordForDate}
      findLatestAvailableDate={findLatestAvailableDate}
      correctColor={["#75B4B7", "black"]}
      presentColor={["#ECB061", "white"]}
      absentColor={["#000000", "white"]}
      keyboardBackgroundColor="#d3d6da"
      isEmbedded={true}
    />
  );
};

export default SeattleTimesDemoEmbed;
