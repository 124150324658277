import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import BaseKeyword from "./BaseKeyword";

const KeywordDemoEmbed = () => {
  const [wordData, setWordData] = useState(null);

  useEffect(() => {
    // Static data for demo
    setWordData({
      wordList: ["SENIOR", "APEX", "WALKER", "FLURRY", "UNITY"],
      answer: "RALLY",
    });
  }, []);

  if (!wordData) return null;

  return (
    <>
      <Helmet>
        <title>The Oaklandside Keyword Game</title>
        <meta
          name="description"
          content="Slide words horizontally to reveal the hidden keyword in this fun word puzzle from The Oaklandside."
        />
      </Helmet>

      <BaseKeyword
        title="Oaklandside"
        subtitle="Slide each word horizontally until the center letters line up to reveal the keyword."
        bannerPath="/assets/customer-logos/banner/oaklandside.png"
        wordList={wordData.wordList}
        isEmbedded={true}
        answer={wordData.answer}
        shareText="I played Keyword"
        shareUrl="https://oaklandside.org/play-keyword"
        publicationName="Oaklandside article"
        modalBackgroundColor="#00324F"
        articleTitle="'We've lived through everything': Rockridge seniors protest Social Security cuts"
        articleUrl="https://oaklandside.org/2025/03/17/weve-lived-through-everything-rockridge-seniors-protest-social-security-cuts/"
      />
    </>
  );
};

export default KeywordDemoEmbed;
