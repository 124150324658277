import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import BaseWordyGame from "../Components/BaseWordyGame";

// Function to always return today's date
const findLatestAvailableDate = () => {
  const today = new Date();
  return {
    date: today.toISOString().split("T")[0], // Format as YYYY-MM-DD
    word: "CLERK",
  };
};

// Function to always return the same word data regardless of date
const getWordForDate = (date) => {
  return {
    word: "CLERK",
    hint: "A library assistant position, one that former Friends of the Johnson County Library librarian Ellen Miller held in her early career.",
    date: date ? date : new Date().toISOString().split("T")[0],
    has_been_solved: false,
  };
};

const JocoWordyDemo = () => {
  const [wordData, setWordData] = useState(null);

  useEffect(() => {
    // Get data from our findLatestAvailableDate function
    const latestWordData = findLatestAvailableDate();
    setWordData(latestWordData);
  }, []);

  if (!wordData) return null;

  return (
    <BaseWordyGame
      title={"Johnson County Post"}
      customTitle="JoCo Post Wordy"
      iconPath={`${process.env.PUBLIC_URL}/assets/wordy-topics/joco-small.png`}
      shareText="Johnson County Post Wordy"
      shareUrl="https://demo.flyingcometgames.com/joco"
      getWordForDate={getWordForDate}
      findLatestAvailableDate={findLatestAvailableDate}
      correctColor={["#91B2D1", "black"]}
      successBackgroundColor="#2273BE"
      keyboardBackgroundColor="#d3d6da"
      shareEmojiCorrect="🟦"
      shareEmojiPresent="🟨"
      shareEmojiAbsent="⬛"
      isEmbedded={true}
    />
  );
};

export default JocoWordyDemo;
