import React, { useEffect } from "react";
import { Box, useMediaQuery, useTheme } from "@mui/material";
import BackspaceOutlinedIcon from "@mui/icons-material/BackspaceOutlined";

const KEYBOARD_KEYS = [
  ["Q", "W", "E", "R", "T", "Y", "U", "I", "O", "P"],
  ["A", "S", "D", "F", "G", "H", "J", "K", "L"],
  ["ENTER", "Z", "X", "C", "V", "B", "N", "M", "⌫"],
];

const Keyboard = ({
  onGuessUpdate,
  currentGuess,
  wordData,
  gameOver,
  guesses,
  correctColor,
  presentColor,
  absentColor,
  keyboardBackgroundColor,
}) => {
  const theme = useTheme();
  const isXs = useMediaQuery(theme.breakpoints.down("sm"));
  const isSm = useMediaQuery(theme.breakpoints.between("sm", "md"));

  // Keyboard event handler
  const handleKeyPress = (e) => {
    if (!wordData || gameOver) return;

    if (e.key === "Enter" && currentGuess.length === wordData.word.length) {
      e.preventDefault(); // Prevent form submission
      onGuessUpdate("ENTER");
    } else if (e.key === "Backspace") {
      e.preventDefault();
      onGuessUpdate("BACKSPACE");
    } else if (
      /^[A-Za-z]$/.test(e.key) &&
      currentGuess.length < wordData.word.length
    ) {
      e.preventDefault();
      onGuessUpdate(e.key.toUpperCase());
    }
  };

  // Add keyboard event listener
  useEffect(() => {
    document.addEventListener("keydown", handleKeyPress);
    return () => document.removeEventListener("keydown", handleKeyPress);
  }, [currentGuess, gameOver, wordData, onGuessUpdate]);

  // On-screen keyboard handler
  const handleKeyClick = (key) => {
    if (gameOver) return;

    if (key === "ENTER" && currentGuess.length === wordData.word.length) {
      onGuessUpdate("ENTER");
    } else if (key === "⌫") {
      onGuessUpdate("BACKSPACE");
    } else if (currentGuess.length < wordData.word.length) {
      onGuessUpdate(key);
    }
  };

  const getKeyboardKeyColor = (key) => {
    if (!wordData || !guesses) return [keyboardBackgroundColor, "black"];

    const keyUpperCase = key.toUpperCase();
    const wordUpperCase = wordData.word.toUpperCase();
    let keyFound = false;
    let correctPosition = false;

    const letterInWord = wordUpperCase.includes(keyUpperCase);

    if (letterInWord) {
      for (let guess of guesses) {
        const guessArray = guess.toUpperCase().split("");
        for (let i = 0; i < guessArray.length; i++) {
          if (guessArray[i] === keyUpperCase) {
            keyFound = true;
            if (keyUpperCase === wordUpperCase[i]) {
              correctPosition = true;
              break;
            }
          }
        }
        if (correctPosition) break;
      }
    }

    if (correctPosition) return correctColor;
    if (letterInWord && keyFound) return presentColor;
    return guesses.some((guess) => guess.toUpperCase().includes(keyUpperCase))
      ? absentColor
      : [keyboardBackgroundColor, "black"];
  };

  // Calculate responsive key sizes based on available width
  const getKeySize = (key, rowLength) => {
    // Calculate percentage of row width that each key should occupy
    // Special keys like ENTER and backspace get more space
    const baseKeyWidth = key === "ENTER" || key === "⌫" ? 1.5 : 1;

    // We use percentages to ensure keys take up full width
    return {
      width: `${
        (baseKeyWidth /
          (rowLength + (key === "ENTER" || key === "⌫" ? 0.5 : 0))) *
        100
      }%`,
      height: { xs: "48px", sm: "52px", md: "58px" },
      fontSize: {
        xs: key === "ENTER" ? "12px" : "14px",
        sm: key === "ENTER" ? "13px" : "15px",
        md: key === "ENTER" ? "14px" : "16px",
      },
      // Maintain minimum width for touch targets
      minWidth: {
        xs: key === "ENTER" || key === "⌫" ? "40px" : "24px",
        sm: key === "ENTER" || key === "⌫" ? "45px" : "30px",
      },
    };
  };

  return (
    <Box
      sx={{
        mt: { xs: 1, sm: 2 },
        width: "100%",
        maxWidth: "sm",
        mx: "auto",
        overflow: "hidden",
        px: { xs: 0.5, sm: 1 },
      }}
    >
      {KEYBOARD_KEYS.map((row, i) => (
        <Box
          key={i}
          sx={{
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
            gap: { xs: "2px", sm: "4px" },
            mb: { xs: "4px", sm: "6px" },
          }}
        >
          {row.map((key) => {
            const keySize = getKeySize(key, row.length);
            return (
              <Box
                key={key}
                onClick={() => handleKeyClick(key)}
                sx={{
                  backgroundColor: getKeyboardKeyColor(key)[0],
                  color: getKeyboardKeyColor(key)[1],
                  ...keySize,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  borderRadius: "4px",
                  fontWeight: "bold",
                  border: "0.5px solid black",
                  cursor: "pointer",
                  userSelect: "none",
                  textTransform: "uppercase",
                  transition: "transform 0.1s",
                  "&:hover": {
                    transform: "scale(1.05)",
                  },
                  px: { xs: 0.25, sm: 0.5 },
                }}
              >
                {key === "⌫" ? <BackspaceOutlinedIcon fontSize="small" /> : key}
              </Box>
            );
          })}
        </Box>
      ))}
    </Box>
  );
};

export default Keyboard;
