import React, { useState, useEffect, useRef, useMemo } from "react";
import {
  Box,
  Typography,
  Button,
  Snackbar,
  Alert,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Link,
  Modal,
  Tooltip,
} from "@mui/material";
import {
  motion,
  useMotionValue,
  useTransform,
  animate,
  AnimatePresence,
} from "framer-motion";
import { logGameEnded, logGameShared, logGuessMade } from "../../analytics";
import { GameHeader } from "../WordyVerse/Components/GameBoard/GameHeader";
import Footer from "../../components/Footer";
import { checkWordValidity } from "../../utils/dictionary"; // Import dictionary check utility
import KeyIcon from "@mui/icons-material/Key";
import LockIcon from "@mui/icons-material/Lock";
import CloseIcon from "@mui/icons-material/Close";
import { Send as SendIcon, Newspaper, HelpCircle } from "lucide-react";
import { ChevronLeft, ChevronRight } from "lucide-react";
import Confetti from "react-confetti";

const BaseKeyword = ({
  title = "Keyword",
  customTitle,
  iconPath,
  subtitle = "Slide each word horizontally until the center letters line up to reveal the keyword.",
  shareText = "I played Keyword",
  shareUrl = window.location.href,
  bannerPath,
  wordList,
  answer = "CARVE",
  publicationId = null,
  articleTitle = "'We've lived through everything': Rockridge seniors protest Social Security cuts",
  articleUrl = "#",
  publicationName = "Oaklandside article",
  buttonColor = "black",
  modalBackgroundColor = "white",
}) => {
  // State for storing guesses
  const [guesses, setGuesses] = useState([]);
  const [currentGuess, setCurrentGuess] = useState("");
  const [gameOver, setGameOver] = useState(false);
  const [showShareToast, setShowShareToast] = useState(false);
  const [invalidWordError, setInvalidWordError] = useState(false); // State to handle invalid word errors
  const [errorMessage, setErrorMessage] = useState("");
  const [shakeAnimation, setShakeAnimation] = useState(false); // State for shake animation
  const isAnimating = useRef(false); // Reference to track if animation is in progress

  // Add state for current date
  const [currentDate, setCurrentDate] = useState(new Date());

  // Add state for source info popup
  const [showSourceInfo, setShowSourceInfo] = useState(true);

  // Add state for victory modal
  const [showVictoryModal, setShowVictoryModal] = useState(false);
  const [showConfetti, setShowConfetti] = useState(false);
  const [confettiPieces, setConfettiPieces] = useState(200);

  // Add new state for how-to-play modal (to be implemented later)
  const [showHowToPlay, setShowHowToPlay] = useState(false);

  // Create individual motion values at the top level
  const motion1 = useMotionValue(0);
  const motion2 = useMotionValue(0);
  const motion3 = useMotionValue(0);
  const motion4 = useMotionValue(0);
  const motion5 = useMotionValue(0);

  // Collect all motion values in an array we can reference
  const motionValues = useMemo(() => {
    return [motion1, motion2, motion3, motion4, motion5];
  }, [motion1, motion2, motion3, motion4, motion5]);

  // Create refs for each word's dimensions
  const wordRefs = useRef(
    Array(wordList.length)
      .fill()
      .map((_, i) => ({
        x: motionValues[i],
        width: 0,
        letterWidth: 0,
      }))
  );

  // State for storing the width of each letter cell
  const [letterWidth, setLetterWidth] = useState(60);
  const [containerWidth, setContainerWidth] = useState(0);
  const containerRef = useRef(null);
  const isInitialSetup = useRef(true);

  // Calculate the appropriate dimensions on mount and window resize
  useEffect(() => {
    const calculateDimensions = () => {
      if (containerRef.current) {
        const width = containerRef.current.offsetWidth;
        setContainerWidth(width);

        // Calculate letter width based on the longest word
        const maxWordLength = Math.max(...wordList.map((word) => word.length));
        const calculatedWidth = Math.min(60, (width - 40) / maxWordLength);
        setLetterWidth(calculatedWidth);

        // Update refs with new dimensions
        wordRefs.current = wordRefs.current.map((ref, index) => {
          const wordLength = wordList[index].length;
          return {
            ...ref,
            width: wordLength * calculatedWidth,
            letterWidth: calculatedWidth,
          };
        });

        // If this is the initial setup, position words to have specific letters in center
        if (isInitialSetup.current) {
          isInitialSetup.current = false;

          // Set initial positions with a delay to ensure container is ready
          setTimeout(() => {
            // Position words to show the 3rd character in the center for each word
            const centerOffset = width / 2 - calculatedWidth / 2;

            // Set each word to show its 3rd character (index 2) in the center
            motionValues[0].set(centerOffset - calculatedWidth * 2); // Place: show 3rd letter (L)
            motionValues[1].set(centerOffset - calculatedWidth * 2); // Glasses: show 3rd letter (A)
            motionValues[2].set(centerOffset - calculatedWidth * 2); // Turkey: show 3rd letter (R)
            motionValues[3].set(centerOffset - calculatedWidth * 2); // Vine: show 3rd letter (N)
            motionValues[4].set(centerOffset - calculatedWidth * 2); // Mouse: show 3rd letter (U)
          }, 200);
        }
      }
    };

    calculateDimensions();

    window.addEventListener("resize", calculateDimensions);
    return () => window.removeEventListener("resize", calculateDimensions);
  }, [wordList, motionValues]);

  // Snap position to closest letter grid after drag ends
  const snapToGrid = (index, position) => {
    const letterW = wordRefs.current[index].letterWidth;
    const centerX = containerWidth / 2;

    // Calculate offset from the exact center of the grid
    const offsetFromCenter = centerX - letterW / 2 - position;

    // Calculate how many letter steps away we are
    const letterSteps = Math.round(offsetFromCenter / letterW);

    // Calculate target position to snap to grid
    const snapPosition = centerX - letterW / 2 - letterSteps * letterW;

    // Word constraints to ensure a letter is always in center column
    const wordLength = wordList[index].length;
    const maxRight = centerX - letterW / 2; // First letter at center
    const maxLeft = maxRight - (wordLength - 1) * letterW; // Last letter at center

    // Ensure position is within bounds
    const clampedPosition = Math.min(Math.max(snapPosition, maxLeft), maxRight);

    // Animate to the snapped position
    animate(motionValues[index], clampedPosition, {
      type: "spring",
      damping: 20,
      stiffness: 300,
    });

    return clampedPosition;
  };

  // Extract the current center letter from each word based on its position
  const extractGuess = () => {
    const centerLetters = wordList.map((word, index) => {
      const xPos = motionValues[index].get();
      const letterW = wordRefs.current[index].letterWidth;
      const centerX = containerWidth / 2;

      // Calculate which letter is in the center
      // For the first letter to be at center, xPos = centerX - letterW/2
      // So letter index = (centerX - letterW/2 - xPos) / letterW
      const letterIndex = Math.round((centerX - letterW / 2 - xPos) / letterW);

      // Ensure index is within bounds
      if (letterIndex >= 0 && letterIndex < word.length) {
        return word[letterIndex];
      }
      return "";
    });

    return centerLetters.join("");
  };

  // Effect to handle victory celebration with confetti
  useEffect(() => {
    if (
      gameOver &&
      guesses.length > 0 &&
      guesses[guesses.length - 1] === answer
    ) {
      setShowVictoryModal(true);
      setShowConfetti(true);

      // Gradually reduce confetti pieces for a fade-out effect
      const duration = 4000;
      const fadeOutTime = 1000;
      const fadeOutInterval = 50;
      const decrementSteps = fadeOutTime / fadeOutInterval;
      const decrementAmount = Math.ceil(200 / decrementSteps);

      // Start the fade-out after the duration
      const timer = setTimeout(() => {
        const fadeInterval = setInterval(() => {
          setConfettiPieces((prev) => {
            const newValue = prev - decrementAmount;
            if (newValue <= 0) {
              clearInterval(fadeInterval);
              setShowConfetti(false);
              return 0;
            }
            return newValue;
          });
        }, fadeOutInterval);
      }, duration);

      return () => {
        clearTimeout(timer);
      };
    }
  }, [gameOver, guesses, answer]);

  // Check the guess and update the game state
  const checkGuess = async () => {
    const extractedGuess = extractGuess();

    if (extractedGuess.length === wordList.length) {
      // Check if the guess is a valid word
      try {
        const isValid = await checkWordValidity(extractedGuess.toLowerCase());

        if (!isValid) {
          // Only start shake animation if not already animating
          if (!isAnimating.current) {
            isAnimating.current = true;
            setInvalidWordError(true);
            setErrorMessage("Not a valid word!");
            setShakeAnimation(true);

            // Perform shake animation on each word
            wordList.forEach((_, index) => {
              const currentX = motionValues[index].get();

              // Create a shake sequence using animate
              animate(
                motionValues[index],
                [
                  currentX,
                  currentX - 10,
                  currentX + 10,
                  currentX - 10,
                  currentX + 10,
                  currentX,
                ],
                { duration: 0.4, ease: "easeInOut" }
              );
            });

            // Reset shake animation after it completes
            setTimeout(() => {
              setShakeAnimation(false);

              // Make sure all words are properly snapped back to grid
              wordList.forEach((_, index) => {
                snapToGrid(index, motionValues[index].get());
              });

              // Reset animation state
              isAnimating.current = false;
            }, 500);

            // Reset error message after a delay
            setTimeout(() => {
              setInvalidWordError(false);
            }, 3000);
          }
          return;
        }

        setCurrentGuess(extractedGuess);
        const newGuesses = [...guesses, extractedGuess];
        setGuesses(newGuesses);

        const isCorrect = extractedGuess === answer;

        if (isCorrect) {
          setGameOver(true);

          // Log game ended analytics
          logGameEnded("Keyword", publicationId, {
            won: isCorrect,
            attempts: newGuesses.length,
            word: answer,
            date: new Date().toLocaleDateString("en-US"),
          });
        }

        // Log guess made
        logGuessMade("Keyword", publicationId, {
          attempt: newGuesses.length,
          word: answer,
          isCorrect,
          date: new Date().toLocaleDateString("en-US"),
        });
      } catch (error) {
        console.error("Error validating word:", error);
        // If validation fails, still accept the word
        setCurrentGuess(extractedGuess);
        const newGuesses = [...guesses, extractedGuess];
        setGuesses(newGuesses);

        const isCorrect = extractedGuess === answer;
        if (isCorrect) {
          setGameOver(true);
        }
      }
    }
  };

  // Highlight letters based on their position in the answer
  const getLetterColor = (letter, position) => {
    if (!letter) return ["white", "black"];

    if (position < answer.length && letter === answer[position]) {
      return ["#91B2D1", "black"]; // Correct position color (light blue)
    }

    return ["white", "black"]; // Default color
  };

  // Get color for displayed guesses
  const getGuessLetterColor = (letter, position, guess) => {
    // If the entire guess is correct, highlight all letters
    if (guess === answer) {
      return ["#91B2D1", "black"]; // All letters in light blue
    }

    // For incorrect guesses, only highlight matching letters
    if (position < answer.length && letter === answer[position]) {
      return ["#91B2D1", "black"]; // Matching letter in light blue
    }

    // Default for non-matching letters in incorrect guesses
    return ["white", "black"]; // White background with black text
  };

  // Share functionality
  const copyToClipboard = (text) => {
    navigator.clipboard
      .writeText(text)
      .then(() => {
        setShowShareToast(true);
        setShowVictoryModal(false); // Close the modal after sharing

        // Log share analytics
        logGameShared("Keyword", publicationId, {
          won: guesses.length > 0 && guesses[guesses.length - 1] === answer,
          attempts: guesses.length,
          word: answer,
          date: new Date().toLocaleDateString("en-US"),
        });
      })
      .catch((err) => {
        console.error("Failed to copy text: ", err);
      });
  };

  // Date navigation handler (not currently used as navigation is disabled)
  const changeDate = (increment) => {
    if (!currentDate) return;

    const newDate = new Date(currentDate);
    newDate.setDate(newDate.getDate() + increment);

    // For future implementation: check if word data exists for this date
    // For now, we're just updating the date state
    setCurrentDate(newDate);
  };

  const handleShare = () => {
    const dateStr = currentDate.toLocaleDateString("en-US", {
      month: "numeric",
      day: "numeric",
      year: "numeric",
    });
    const won = guesses.length > 0 && guesses[guesses.length - 1] === answer;

    // Format: "Oaklandside Keyword 3/24/2025"
    let shareString = `${customTitle || title} Keyword ${dateStr}\n\n`;

    // Add emoji representation of guesses - lock for wrong, key for correct
    guesses.forEach((guess) => {
      shareString += guess === answer ? "🗝️" : "🔒";
    });

    shareString += "\n\nPlay at: " + shareUrl;

    copyToClipboard(shareString);
  };

  // Get the current selected letter index for highlighting
  const getSelectedLetterIndex = (wordIndex) => {
    if (!containerRef.current) return 0;

    const xPos = motionValues[wordIndex].get();
    const letterW = wordRefs.current[wordIndex].letterWidth;
    const centerX = containerWidth / 2;

    // Calculate which letter is at center
    const letterIndex = Math.round((centerX - letterW / 2 - xPos) / letterW);

    // Ensure we return a valid index
    if (letterIndex < 0) return 0;
    if (letterIndex >= wordList[wordIndex].length)
      return wordList[wordIndex].length - 1;

    return letterIndex;
  };

  return (
    <Box
      sx={{
        textAlign: "center",
        p: 2,
        display: "flex",
        flexDirection: "column",
        backgroundColor: "#EEEEEE",
        position: "relative",
      }}
    >
      <Box sx={{ flexGrow: 1 }}>
        {bannerPath && (
          <GameHeader
            title={customTitle || title}
            subtitle={subtitle}
            iconPath={iconPath}
            bannerPath={bannerPath}
          />
        )}

        {/* Source Info Dialog */}
        <Dialog
          open={showSourceInfo}
          onClose={() => setShowSourceInfo(false)}
          maxWidth="sm"
          PaperProps={{
            sx: {
              borderRadius: 2,
              p: 0,
              m: 2,
              maxWidth: { xs: "90%", sm: "400px" },
              position: "relative",
              overflow: "visible",
              border: "1px solid black", // Add black border as in image 2
            },
          }}
        >
          <Box
            sx={{
              bgcolor: "white",
              borderRadius: "8px",
              p: 3,
              pt: 4,
              pb: 4,
              outline: "none",
              textAlign: "left",
              overflow: "hidden",
              color: "black",
            }}
          >
            <IconButton
              onClick={() => setShowSourceInfo(false)}
              aria-label="close"
              sx={{
                position: "absolute",
                right: 16,
                top: 16,
                color: "black",
                zIndex: 1,
              }}
            >
              <CloseIcon />
            </IconButton>

            <Box sx={{ display: "flex", alignItems: "center", mb: 3 }}>
              <Newspaper size={24} style={{ marginRight: "12px" }} />
              <Typography
                variant="h5"
                sx={{
                  fontWeight: 500,
                  fontSize: { xs: "1.3rem", sm: "1.5rem" },
                  lineHeight: 1.3,
                  pt: 3,
                  color: "black",
                }}
              >
                This game is based on the article:
              </Typography>
            </Box>

            <Typography
              variant="h4"
              component="h2"
              sx={{
                fontWeight: "bold",
                mb: 4,
                fontSize: { xs: "1.6rem", sm: "1.9rem" },
                lineHeight: 1.3,
                color: "black",
              }}
            >
              {articleTitle}
            </Typography>

            <Link
              href={articleUrl}
              underline="always"
              color="inherit"
              target="_blank"
              rel="noopener noreferrer"
              sx={{
                fontSize: "1.1rem",
                display: "block",
                color: "black",
                fontWeight: "medium",
                mb: 4,
                textDecoration: "underline", // Ensure the link is underlined as in image 2
              }}
            >
              Read the article here
            </Link>

            <Box sx={{ textAlign: "center" }}>
              <Button
                variant="contained"
                sx={{
                  bgcolor: buttonColor, // Use the custom button color
                  color: "white",
                  borderRadius: "30px",
                  px: 6,
                  py: 1.5,
                  fontSize: "1.25rem",
                  textTransform: "none",
                  fontWeight: "bold",
                  width: "100%",
                  "&:hover": {
                    bgcolor: buttonColor,
                    opacity: 0.9,
                    transform: "translateY(-2px)",
                    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
                  },
                  boxShadow: "none",
                }}
                onClick={() => setShowSourceInfo(false)}
              >
                Play!
              </Button>
            </Box>
          </Box>
        </Dialog>

        {/* How to Play Dialog */}
        <Dialog
          open={showHowToPlay}
          onClose={() => setShowHowToPlay(false)}
          maxWidth="sm"
          PaperProps={{
            sx: {
              borderRadius: 2,
              p: 0,
              m: 2,
              maxWidth: { xs: "90%", sm: "400px" },
              position: "relative",
              overflow: "visible",
              border: "1px solid black",
            },
          }}
        >
          <Box
            sx={{
              bgcolor: "white",
              borderRadius: "8px",
              pt: 6,
              pb: 4,
              px: 3,
              outline: "none",
              textAlign: "center",
              overflow: "hidden",
              color: "black",
              position: "relative",
            }}
          >
            <IconButton
              onClick={() => setShowHowToPlay(false)}
              aria-label="close"
              sx={{
                position: "absolute",
                right: 16,
                top: 16,
                color: "black",
                zIndex: 1,
              }}
            >
              <CloseIcon />
            </IconButton>

            {/* Question mark icon and title */}
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                gap: 2,
                mb: 4,
              }}
            >
              <HelpCircle size={32} strokeWidth={2} />
              <Typography
                variant="h4"
                component="h2"
                sx={{
                  fontWeight: "bold",
                  fontSize: { xs: "2rem", sm: "2.2rem" },
                  lineHeight: 1.3,
                  color: "black",
                }}
              >
                How to play
              </Typography>
            </Box>

            <Box sx={{ textAlign: "left", mb: 5 }}>
              <Typography
                variant="body1"
                sx={{
                  fontSize: "1.35rem",
                  mb: 4,
                  color: "black",
                  fontWeight: "400",
                  lineHeight: 1.4,
                }}
              >
                Slide each word horizontally until the center letters line up to
                reveal a word, vertically.
              </Typography>

              <Typography
                variant="body1"
                sx={{
                  fontSize: "1.35rem",
                  mb: 4,
                  color: "black",
                  fontWeight: "400",
                  lineHeight: 1.4,
                }}
              >
                Tap "Enter" to submit your guess.
              </Typography>

              <Typography
                variant="body1"
                sx={{
                  fontSize: "1.35rem",
                  mb: 4,
                  color: "black",
                  fontWeight: "400",
                  lineHeight: 1.4,
                }}
              >
                Your guesses will appear below the grid.
              </Typography>

              <Typography
                variant="body1"
                sx={{
                  fontSize: "1.35rem",
                  color: "black",
                  fontWeight: "400",
                  lineHeight: 1.4,
                }}
              >
                Blue letters mean the letter is correct!
              </Typography>
            </Box>

            <Box sx={{ textAlign: "center" }}>
              <Button
                variant="contained"
                sx={{
                  bgcolor: buttonColor,
                  color: "white",
                  borderRadius: "30px",
                  px: 6,
                  py: 1.5,
                  fontSize: "1.5rem",
                  textTransform: "none",
                  fontWeight: "bold",
                  width: "100%",
                  maxWidth: "400px",
                  "&:hover": {
                    bgcolor: buttonColor,
                    opacity: 0.9,
                    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
                  },
                  boxShadow: "none",
                }}
                onClick={() => setShowHowToPlay(false)}
              >
                Play!
              </Button>
            </Box>
          </Box>
        </Dialog>

        {/* Victory Modal */}
        <Dialog
          open={showVictoryModal}
          onClose={() => setShowVictoryModal(false)}
          maxWidth="sm"
          fullWidth
          PaperProps={{
            sx: {
              overflow: "hidden",
              bgcolor: modalBackgroundColor,
              color: "white",
              borderRadius: "12px",
              textAlign: "left",
              p: { xs: 3, sm: 4 },
              m: 2,
              maxWidth: { xs: "90%", sm: "400px" },
              width: "100%",
              border: "1px solid black", // Add black border for consistency
            },
          }}
        >
          <DialogContent sx={{ p: 0 }}>
            {showConfetti && (
              <Confetti
                width={window.innerWidth}
                height={window.innerHeight}
                recycle={true}
                numberOfPieces={confettiPieces}
                gravity={0.15}
                opacity={confettiPieces < 100 ? confettiPieces / 100 : 1}
                colors={[
                  "#FFA500",
                  "#FF4500",
                  "#FFD700",
                  "#FFFF00",
                  "#00BFFF",
                  "#1E90FF",
                  "#ADD8E6",
                ]}
              />
            )}

            <IconButton
              onClick={() => setShowVictoryModal(false)}
              aria-label="close"
              sx={{
                position: "absolute",
                right: 16,
                top: 16,
                color: "white",
                transition: "transform 0.2s ease",
                "&:hover": {
                  transform: "rotate(90deg)",
                  bgcolor: "rgba(255, 255, 255, 0.1)",
                },
              }}
            >
              <CloseIcon />
            </IconButton>

            {/* Victory Message */}
            <motion.div
              initial={{ scale: 0.8, opacity: 0 }}
              animate={{ scale: 1, opacity: 1 }}
              transition={{ type: "spring", stiffness: 300, damping: 20 }}
            >
              <Typography
                variant="h3"
                sx={{
                  mb: 4,
                  pt: 3,
                  fontWeight: "bold",
                  color: "white",
                  fontSize: { xs: "2.2rem", sm: "2.2rem" },
                  textAlign: "center",
                }}
              >
                YOU SOLVED IT!
              </Typography>
            </motion.div>

            {/* Display the answer */}
            <motion.div
              initial={{ y: 20, opacity: 0 }}
              animate={{ y: 0, opacity: 1 }}
              transition={{ delay: 0.2, duration: 0.4 }}
            >
              <Box sx={{ mb: 5 }}>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    gap: 1,
                  }}
                >
                  {Array.from(answer).map((letter, index) => (
                    <motion.div
                      key={index}
                      initial={{ rotateY: 90, opacity: 0 }}
                      animate={{ rotateY: 0, opacity: 1 }}
                      transition={{
                        delay: 0.3 + index * 0.1,
                        duration: 0.3,
                      }}
                    >
                      <Box
                        sx={{
                          width: 50,
                          height: 50,
                          border: "2px solid",
                          borderColor: "#000",
                          borderRadius: "8px",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          backgroundColor: "white",
                          color: "black",
                          fontSize: "2rem",
                          fontWeight: "bold",
                          // Directional shadow matching the screenshot
                          boxShadow: "4px 4px 0px rgba(0, 0, 0, 0.3)",
                          transition: "all 0.3s ease",
                          // Text styling
                          fontFamily: "'Arial', sans-serif",
                          fontWeight: 700,
                        }}
                      >
                        {letter}
                      </Box>
                    </motion.div>
                  ))}
                </Box>
              </Box>
            </motion.div>

            {/* Article title and link */}
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.4, duration: 0.5 }}
            >
              <Box
                sx={{
                  bgcolor: "white",
                  borderRadius: "8px",
                  p: 3,
                  mb: 4,
                  mx: "auto",
                  boxShadow: "0 2px 6px rgba(0, 0, 0, 0.08)",
                  textAlign: "left",
                  color: "black",
                  border: "1px solid black", // Add black border for consistency
                }}
              >
                <Box
                  sx={{ display: "flex", alignItems: "flex-start", mb: 2.5 }}
                >
                  <Typography
                    variant="h4"
                    component="h3"
                    sx={{
                      fontWeight: "bold",
                      color: "black",
                      fontSize: { xs: "1.1rem", sm: "1.3rem" },
                      lineHeight: 1.3,
                    }}
                  >
                    {articleTitle}
                  </Typography>
                </Box>

                <Button
                  component={Link}
                  href={articleUrl}
                  target="_blank"
                  rel="noopener noreferrer"
                  variant="contained"
                  sx={{
                    bgcolor: buttonColor, // Use custom button color for consistency
                    color: "white",
                    fontWeight: "bold",
                    borderRadius: "20px",
                    px: 3,
                    py: 1,
                    textTransform: "none",
                    boxShadow: "none",
                    "&:hover": {
                      bgcolor: buttonColor,
                      opacity: 0.9,
                      boxShadow: "none",
                    },
                  }}
                >
                  Read the article
                </Button>
              </Box>
            </motion.div>

            {/* Share Button */}
            <motion.div whileHover={{ scale: 1.05 }} whileTap={{ scale: 0.95 }}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  width: "100%",
                }}
              >
                <Button
                  startIcon={<SendIcon />}
                  variant="contained"
                  onClick={handleShare}
                  sx={{
                    bgcolor: "black",
                    color: "white",
                    fontWeight: "bold",
                    borderRadius: "30px",
                    px: 4,
                    py: 1.5,
                    width: "80%",
                    textTransform: "none",
                    transition: "all 0.2s ease",
                    boxShadow: "none",
                    "&:hover": {
                      bgcolor: "#333",
                      transform: "translateY(-2px)",
                      boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
                    },
                  }}
                >
                  Share my score
                </Button>
              </Box>
            </motion.div>
          </DialogContent>
        </Dialog>

        {/* Game instructions */}
        <Typography
          variant="h2"
          component="h1"
          sx={{
            fontSize: { xs: "2.5rem", md: "3.5rem" },
            fontWeight: "bold",
            my: 2,
          }}
        >
          Keyword
        </Typography>

        <Typography
          variant="body1"
          sx={{
            fontSize: { xs: "1rem", md: "1.2rem" },
            maxWidth: "sm",
            mx: "auto",
            mb: 3,
          }}
        >
          Slide each word horizontally until the center letters line up to
          reveal the keyword.
        </Typography>

        {/* Date Controls */}
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            gap: 1,
            mb: 2,
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <IconButton
              disabled={true} // Disabled for now as we don't support past dates yet
              onClick={() => changeDate(-1)}
              sx={{
                color: "rgba(0, 0, 0, 0.26)", // Grayed out when disabled
              }}
            >
              <ChevronLeft />
            </IconButton>
            <Typography sx={{ mx: 0, color: "black" }}>
              {currentDate.toLocaleDateString("en-US", {
                month: "long",
                day: "numeric",
                year: "numeric",
              })}
            </Typography>
            {/* <IconButton
              disabled={true} // Disabled for now as we don't support future dates yet
              onClick={() => changeDate(1)}
              sx={{
                color: "rgba(0, 0, 0, 0.26)", // Grayed out when disabled
              }}
            >
              <ChevronRight />
            </IconButton> */}
          </Box>

          {/* Game info icons */}
          <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
            {/* Newspaper icon to show article info */}
            <IconButton
              onClick={() => setShowSourceInfo(true)}
              aria-label="Show article information"
              sx={{
                color: "black",
                padding: 1,
                "&:hover": {
                  backgroundColor: "rgba(0,0,0,0.08)",
                },
              }}
            >
              <Newspaper size={24} />
            </IconButton>

            {/* Question mark icon for how to play */}
            <IconButton
              onClick={() => setShowHowToPlay(true)}
              aria-label="How to play"
              sx={{
                color: "black",
                padding: 1,
                "&:hover": {
                  backgroundColor: "rgba(0,0,0,0.08)",
                },
              }}
            >
              <HelpCircle size={24} />
            </IconButton>
          </Box>
        </Box>

        {/* Word sliding area */}
        <Box
          ref={containerRef}
          sx={{
            position: "relative",
            maxWidth: "sm",
            mx: "auto",
            border: "1px solid black",
            height: `${wordList.length * 70 + 20}px`,
            mb: 3,
            overflow: "hidden",
          }}
        >
          {/* Center column - static letter boxes */}
          <Box
            sx={{
              position: "absolute",
              left: "50%",
              top: 0,
              height: "100%",
              width: letterWidth,
              transform: "translateX(-50%)",
              zIndex: 1,
              pointerEvents: "none", // Ensure it doesn't interfere with dragging
            }}
          >
            {/* Individual letter boxes in center column - now just borders */}
            {wordList.map((_, wordIndex) => (
              <Box
                key={`center-box-${wordIndex}`}
                sx={{
                  position: "absolute",
                  width: letterWidth,
                  height: "60px",
                  top: `${wordIndex * 70 + 10}px`,
                  border: "4px solid black",
                  borderRight: "3px solid black",
                  zIndex: 0,
                }}
              />
            ))}
          </Box>

          {/* Words that can be dragged */}
          {wordList.map((word, wordIndex) => {
            const xMotionValue = motionValues[wordIndex];
            const wordWidth = word.length * letterWidth;
            const selectedLetterIndex = getSelectedLetterIndex(wordIndex);

            // Calculate drag constraints based on container and word width
            const dragConstraints = {
              left: -(wordWidth - containerWidth / 2), // Ensure at least one letter is in center
              right: containerWidth / 2 - letterWidth, // Prevent going too far right
            };

            return (
              <motion.div
                key={`word-${wordIndex}`}
                style={{
                  x: xMotionValue,
                  position: "absolute",
                  top: `${wordIndex * 70 + 10}px`,
                  height: "60px",
                  display: "flex",
                  touchAction: "none",
                  cursor: "grab",
                }}
                whileDrag={{ cursor: "grabbing" }}
                drag="x"
                dragConstraints={dragConstraints}
                dragElastic={0.1}
                dragMomentum={false}
                onDragEnd={() => snapToGrid(wordIndex, xMotionValue.get())}
              >
                {Array.from(word).map((letter, letterIndex) => {
                  return (
                    <Box
                      key={`letter-${wordIndex}-${letterIndex}`}
                      sx={{
                        width: `${letterWidth}px`,
                        height: "60px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        border:
                          invalidWordError && shakeAnimation
                            ? "1px solid #f44336"
                            : "1px solid black",
                        fontSize: { xs: "1.5rem", md: "2rem" },
                        fontWeight: "bold",
                        backgroundColor: "white",
                        color: "black",
                        cursor: "inherit",
                      }}
                    >
                      {letter}
                    </Box>
                  );
                })}
              </motion.div>
            );
          })}
        </Box>

        {/* Error message alert */}
        <AnimatePresence>
          {invalidWordError && (
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0 }}
              transition={{ duration: 0.3 }}
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                marginBottom: "20px",
                position: "relative",
              }}
            >
              <Alert
                severity="error"
                sx={{
                  width: "200px",
                  fontSize: "1rem",
                  backgroundColor: "white",
                  border: "1px solid #f44336",
                  borderRadius: "5px",
                  color: "black",
                  "& .MuiAlert-message": {
                    fontSize: "1rem",
                  },
                }}
              >
                {errorMessage}
              </Alert>
            </motion.div>
          )}
        </AnimatePresence>

        {/* Check button */}
        <Button
          variant="contained"
          color="primary"
          disableRipple
          sx={{
            px: 4,
            py: 1,
            mb: 3,
            fontSize: "1.2rem",
            borderRadius: "20px",
            width: "75%",
            maxWidth: "300px",
            bgcolor: buttonColor,
            "&.MuiButton-contained": {
              bgcolor: buttonColor,
            },
            "&.Mui-disabled": {
              bgcolor: buttonColor,
              opacity: 0.6,
              color: "white",
            },
          }}
          onClick={checkGuess}
          disabled={gameOver}
        >
          Check
        </Button>

        {/* Display guesses */}
        <Box sx={{ mb: 4 }}>
          <Typography variant="h6" component="h3" sx={{ mb: 2 }}>
            GUESSES
          </Typography>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              gap: 2,
            }}
          >
            {[...guesses].reverse().map((guess, guessIndex) => (
              <Box
                key={`guess-${guessIndex}`}
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: 1,
                }}
              >
                {/* Key or Lock icon based on correctness */}
                <Box sx={{ mr: 2, display: "flex", alignItems: "center" }}>
                  {guess === answer ? (
                    <KeyIcon sx={{ fontSize: 30, color: "black" }} />
                  ) : (
                    <LockIcon sx={{ fontSize: 30, color: "black" }} />
                  )}
                </Box>

                {Array.from(guess).map((letter, letterIndex) => {
                  const [bgColor, textColor] = getGuessLetterColor(
                    letter,
                    letterIndex,
                    guess
                  );
                  return (
                    <Box
                      key={`guess-letter-${guessIndex}-${letterIndex}`}
                      sx={{
                        width: 45,
                        height: 45,
                        border: "2px solid",
                        borderColor: "#000",
                        borderRadius: "8px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        backgroundColor: bgColor,
                        color: textColor,
                        fontSize: "2rem",
                        fontWeight: "bold",
                        // Directional shadow matching the screenshot
                        boxShadow: "4px 4px 0px rgba(0, 0, 0, 0.3)",
                        transition: "all 0.3s ease",
                        // Text styling
                        fontFamily: "'Arial', sans-serif",
                        fontWeight: 700,
                      }}
                    >
                      {letter}
                    </Box>
                  );
                })}
              </Box>
            ))}
          </Box>
        </Box>
      </Box>

      <Snackbar
        open={showShareToast}
        autoHideDuration={3000}
        onClose={() => setShowShareToast(false)}
        message="Your score was copied to clipboard!"
      />
    </Box>
  );
};

export default BaseKeyword;
